import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PublicPage from './pages/PublicPage';
import BillingPage from './pages/BillingPage';
import {
  AccountPage,
  LogoutPage,
  ErrorPage,
  HomePage,
  SplashPage,
  SignaturePage,
  UsersPage,
  OrganisationPage,
  OnBoardPage,
} from './pages';
import { refreshAccessToken } from '../api';
import {
  AccountSlice,
  SocialSlice,
  SubscriptionSlice,
  BillingSlice,
  CompanySlice,
  TemplateSlice,
  SignatureSlice,
  TeamSlice,
  UsersSlice,
  CursorSlice,
} from '../store';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

const Routes = (props: any) => {
  const dispatch = useDispatch();
  const [render, setRender] = useState(false); //loading is false
  const [isCardExpired, setIsCardExpired] = useState(false);
  const { isAuthorized, billing, account, lastAction, subscription } = useSelector(
    (state: any) => ({
      isAuthorized: state.account.accessToken !== null && state.account.account !== null,
      account: state.account.account,
      subscription: state.subscription.subscription,
      lastAction: state.account.lastAction,
      billing: state.billing.billing,
    }),
    shallowEqual
  );
  const { loadAccount } = AccountSlice.actions;
  const { loadSubscription } = SubscriptionSlice.actions;
  const { loadBilling } = BillingSlice.actions;
  const { loadCompany } = CompanySlice.actions;
  const { loadSocialItem } = SocialSlice.actions;
  const { loadTemplate } = TemplateSlice.actions;
  const { loadSignatures } = SignatureSlice.actions;
  const { loadTeam } = TeamSlice.actions;
  const { loadUsers } = UsersSlice.actions;
  const { updateUserCursor } = CursorSlice.actions;

  useEffect(() => {
    if (billing) {
      if (billing.card && billing.card.exp_year && billing.card.exp_month) {
        setIsCardExpired(new Date(billing.card.exp_year, billing.card.exp_month, 1).getTime() < new Date().getTime());
      } else {
        const date = new Date();
        setIsCardExpired(new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime() < new Date().getTime());
      }
    }
  }, [billing]);

  useEffect(() => {
    (async () => {
      //when user reload the browser
      if (isAuthorized && lastAction !== 'Auth Objects/loginReducer') {
        //check if it is authorised and just login or not
        await refreshAccessToken(); // validate and refresh access token every 15 minutes
        setRender(true);
        if (!props!.data.errors) {
          dispatch(loadAccount({ account: props!.data.account })); // update user every time mate user reload
          dispatch(loadSocialItem({ socials: props!.data.socials })); //update the social item of store
          dispatch(loadSubscription({ subscription: props!.data.subscription }));
          dispatch(loadBilling({ billing: props!.data.billing }));
          dispatch(loadCompany({ company: props!.data.company }));
          dispatch(loadTemplate({ template: props!.data.template }));
          dispatch(loadSignatures({ signatures: props!.data.signatures }));
          dispatch(loadTeam({ team: props!.data.teams }));
          dispatch(loadUsers({ users: props!.data.users }));
          dispatch(updateUserCursor({ users: props!.data.cursors.users }));
        } else {
          if (props!.data.errors[0].field === 'accessToken') {
            dispatch(loadAccount({ account: null }));
          }
        }
        setRender(false);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {render ? (
        'Loading...'
      ) : (
        <Suspense fallback={<SplashPage />}>
          <Elements stripe={stripePromise}>
            <Switch>
              <Route path="/logout" component={LogoutPage} />
              <Route path="/public" component={PublicPage} />
              {!isAuthorized && (
                <Route>
                  <AccountPage />
                </Route>
              )}
              {isAuthorized && account.status === 'completed' && (
                <Switch>
                  <Redirect from="/account" to="/" />
                  <Route path="/billing" component={BillingPage} />
                  {subscription && subscription.status === 'incomplete_expired' && <Redirect to="/billing/expire" />}
                  <Redirect exact from="/onboard" to="/dashboard" />
                  {billing && billing.card && isCardExpired && <Route path="/onboard" component={OnBoardPage} />}
                  {billing && billing.card && isCardExpired && <Redirect to="/onboard/subscription/card" />}
                  <Route path="/dashboard" component={HomePage} />
                  <Redirect exact from="/" to="/dashboard" />
                  <Route path="/signature" component={SignaturePage} />
                  <Route path="/users" component={UsersPage} />
                  <Route path="/organization" component={OrganisationPage} />
                  <Route path="/error" component={ErrorPage} />
                  <Redirect to="/error" />
                </Switch>
              )}
              {isAuthorized && account.status !== 'completed' && account.status !== 'form' && (
                <Switch>
                  <Route path="/onboard" component={OnBoardPage} />
                  <Route path="/error" component={ErrorPage} />
                  {account.status !== 'completed' && <Redirect to={`/onboard/${account.status}`} />}
                  <Redirect to="/error" />
                </Switch>
              )}

              <Route path="/error" component={ErrorPage} />
              <Redirect to="/error" />
            </Switch>
          </Elements>
        </Suspense>
      )}
    </>
  );
};

export { Routes };
