import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { SideBox, HeaderLayout, SubHeader } from '.';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: '100%',
    },
    container: {
      height: '100%',
      padding: 0,
    },
    pageLayout: {
      marginBottom: 25,
    },
  })
);

export const Layout = ({ isPublic = false, children }: LayoutProps) => {
  const classes = useStyles();

  const { subscription } = useSelector(
    (state: any) => ({
      subscription: state.subscription.subscription,
    }),
    shallowEqual
  );

  return (
    <>
      <Grid container className={classes.root} direction="column" justify="flex-start" alignItems="flex-start">
        <Grid container item justify="flex-start" alignItems="center">
          <Container className={classes.container}>
            <SideBox />
            <HeaderLayout isPublic={isPublic} />
          </Container>
        </Grid>
        {/*<Grid container item  justify={"flex-start"} alignItems={"center"} >*/}
        {/*<SubHeader />*/}
        {/*</Grid>*/}
        {!isPublic && subscription && subscription.status === 'incomplete' && (
          <Grid container item justify="flex-start" alignItems="center">
            <SubHeader />
          </Grid>
        )}
        <Grid className={classes.pageLayout} container item justify="flex-start" alignItems="center">
          <Container className={classes.container}>{children}</Container>
        </Grid>
      </Grid>
    </>
  );
};

type LayoutProps = {
  isPublic?: boolean;
  children: ReactElement;
};
