import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createStyles, Grid, makeStyles, Theme, Typography, AppBar, Box, Tabs, Tab, lighten } from '@material-ui/core';
import { ButtonCustom, TextFieldInline, themeBase } from '../../../../style';
import { SignatureDisplay } from '../SignatureDisplay';
import { EditorsControl } from './controls/EditorsControl';
import { Template } from './template/Template';
import { SignatureUser } from './users/SignatureUser';
import { SignaturePublishConfirmation } from './controls/SignaturePublishConfirmation';
import { useSignaturePublishMutation, useSignatureRevertMutation, useSignatureRemoveMutation } from '../../../../api';
import { SignatureSlice, UsersSlice } from '../../../../store';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(4),
    },
    container: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
    },
    signaturePreview: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(16),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      backgroundColor: '#EEEEF3',
    },
    header: {
      marginBottom: theme.spacing(6),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    signature: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
      width: '100%',
      border: '1px solid #aaa',
    },
    tabs: {
      borderBottom: '2px solid ' + lighten(theme.palette.primary.main, 0.85),
      minHeight: theme.spacing(16),
      // minWidth:100
      '& .MuiTab-root': {
        minWidth: 80,
        height: theme.spacing(16),
        flexGrow: 1,
      },
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
    },
    signatureControl: {
      paddingLeft: theme.spacing(4),
    },
    signatureContainer: {
      width: '100%',
    },
    back: {
      display: 'flex',
      alignItem: 'center',
      fontSize: theme.typography.h6.fontSize,
    },
    previewText: {
      paddingBottom: theme.spacing(12),
      fontWeight: 600,
    },
    title: {
      width: theme.spacing(70),
    },
    titleDraft: {
      width: theme.spacing(125),
    },
    btnSave: {
      paddingTop: themeBase.spacing(2) + 1,
      paddingBottom: themeBase.spacing(2) + 1,
    },
  })
);

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`signature-editor-tabs-${index}`}
      aria-labelledby={`signature-editor-tabs-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `signature-editor-tabs-${index}`,
    'aria-controls': `signature-editor-tabs-${index}`,
  };
};

export const SignatureEdit = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setActive, updateSignature, removeSignature, setHasChanges } = SignatureSlice.actions;
  const { template, signatures, signature, hasChanges } = useSelector(
    (state: any) => ({
      template: state.template.templateList,
      signatures: state.signature.signatureItems,
      signature: state.signature.active,
      hasChanges: state.signature.hasChanges,
    }),
    shallowEqual
  );
  const { removeSignatureUser } = UsersSlice.actions;
  const [signaturePublish] = useSignaturePublishMutation();
  const [signatureRevert] = useSignatureRevertMutation();
  const [signatureRemove] = useSignatureRemoveMutation();
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [value, setValue] = useState(0);
  const pathnames = location.pathname.split('/').filter((x) => x);

  useEffect(() => {
    getSignature();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSignature = () => {
    if (pathnames.length === 3) {
      const path = signatures.filter((sign: any) => sign.id === pathnames[2]);
      if (path) {
        if (signature) {
          if (path[0].id !== signature.id) {
            dispatch(setActive({ signature: path[0] }));
          }
        } else {
          dispatch(setActive({ signature: path[0] }));
        }
      }
    }
    return;
  };

  const handlePublish = async (hasDraft: boolean) => {
    enableLoading(hasDraft);
    const signatureId = signature.id;
    const templateId = signature.draft.template.id;
    const properties = signature.draft.properties;
    const meetingLinkImg = signature.draft.meetingLinkImg;
    const embedCode = signature.draft.embedCode;
    const embedCodeTwo = signature.draft.embedCodeTwo;
    const customCode = signature.draft.customCode;
    const name = signature.draft.name;
    const response = await signaturePublish({
      variables: {
        options: {
          signature: signatureId,
          template: templateId,
          meetingLinkImg,
          embedCode,
          embedCodeTwo,
          customCode,
          properties,
          hasDraft,
          name,
        },
      } as any,
    });

    if (!response.errors && !response.data!.signaturePublish.errors) {
      const message = !hasDraft
        ? 'Successfully published the signature'
        : 'Updated signature has been saved in draft. Click Publish to implement the changes in your live signature.';

      dispatch(updateSignature({ signature: response.data!.signaturePublish.signature }));
      dispatch(setActive({ signature: response.data!.signaturePublish.signature }));
      dispatch(setHasChanges({ change: false }));
      toastr.success('Success', message);
      disableLoading(hasDraft);
    } else {
      response.data!.signaturePublish.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
      disableLoading(hasDraft);
    }
  };

  const handleRevert = async () => {
    const signatureId = signature.id;
    setLoadingRevert(true);
    const response = await signatureRevert({
      variables: {
        options: { signature: signatureId },
      } as any,
    });
    if (!response.errors && !response.data!.signatureRevert.errors) {
      dispatch(updateSignature({ signature: response.data!.signatureRevert.signature }));
      dispatch(setActive({ signature: response.data!.signatureRevert.signature }));
      dispatch(setHasChanges({ change: false }));
      toastr.success('Success', 'Successfully reverted to published signature');
      setLoadingRevert(false);
    } else {
      response.data!.signatureRevert.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
      setLoadingRevert(false);
    }
  };

  const handleDelete = async () => {
    setLoadingRemove(true);
    const signatureId = signature.id;

    const response = await signatureRemove({
      variables: {
        options: { signature: signatureId },
      } as any,
    });
    if (!response.errors && !response.data!.signatureRemove.errors) {
      dispatch(removeSignature({ signature: signature }));
      dispatch(removeSignatureUser({ signature: signature }));
      dispatch(setActive({ signature: null }));
      toastr.success('Success', 'Signature successfully removed');
      setLoadingRemove(false);
      history.push('/signature');
    } else {
      response.data!.signatureRemove.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
      setLoadingRemove(false);
    }
  };

  const updateSignatureName = (name: string) => {
    const newSignature = { ...signature, draft: { ...signature.draft, name: name } };
    dispatch(setActive({ signature: newSignature }));
    dispatch(setHasChanges({ change: true }));
  };
  const enableLoading = (hasDraft: boolean) => {
    if (hasDraft) {
      setLoadingDraft(true);
    } else {
      setLoadingPublish(true);
    }
  };
  const disableLoading = (hasDraft: boolean) => {
    if (hasDraft) {
      setLoadingDraft(false);
    } else {
      setLoadingPublish(false);
    }
  };
  const provideTemplateHtml = (id: string) => {
    return template.filter((templ: any) => templ.id === id)[0].html;
  };
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const handleAssign = (signature: any) => {
    history.push('/users/' + signature.id);
  };

  return (
    <Grid container justify="center">
      {signature && (
        <Grid container className={classes.root} item justify="space-between" alignItems="center" lg={12}>
          <Grid container className={classes.header} item justify="space-between" alignItems="center" lg={12}>
            <Prompt when={hasChanges} message="You have unsaved changes, are you sure you want to leave?" />
          </Grid>
          <Grid container className={classes.header} item justify="space-between" alignItems="flex-start" lg={12}>
            <Grid container item justify="flex-start" lg={5} alignItems="center">
              <Grid item lg={12}>
                <Box display="flex" width="100%" alignItems="center">
                  <Typography className={signature.hasDraft ? classes.titleDraft : classes.title} component="div" variant="h4">
                    Editing Signature: {signature.hasDraft && '[Draft]'}
                  </Typography>
                  <TextFieldInline onSubmit={updateSignatureName} variant="h4" content={signature.draft.name} />
                </Box>
              </Grid>
              {signature.hasDraft && (
                <Grid item lg={12}>
                  <Box display="flex" width="100%" alignItems="center">
                    <Typography component="p" variant="caption">
                      Updated signature has been saved in draft. Click Publish to implement the changes in your live signature.
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid item container lg={7} justify="flex-end" spacing={3}>
              <Grid item>
                <ButtonCustom
                  disabled={loadingRemove}
                  spinner={loadingRemove}
                  onClick={handleDelete}
                  variant="outlined"
                  color="primary"
                >
                  Delete
                </ButtonCustom>
              </Grid>
              <Grid item>
                <ButtonCustom variant="outlined" color="primary" onClick={() => handleAssign(signature)}>
                  Assign users
                </ButtonCustom>
              </Grid>
              {signature.hasDraft && (
                <Grid item>
                  <ButtonCustom
                    disabled={loadingRevert}
                    variant="outlined"
                    spinner={loadingRevert}
                    color="primary"
                    onClick={() => handleRevert()}
                  >
                    Revert to published
                  </ButtonCustom>
                </Grid>
              )}
              {/*  <Grid item> <ButtonCustom disabled={loadingPublish} spinner={loadingPublish} variant"contained" color"primary" onClick={()=>handlePublish(false)}>Publish</ButtonCustom> </Grid> */}
              <Grid item>
                <SignaturePublishConfirmation
                  loadingPublish={loadingPublish}
                  handlePublish={handlePublish}
                  numUsers={signature.users.length}
                />
              </Grid>
              <Grid item>
                <Box>
                  <ButtonCustom
                    disabled={loadingDraft}
                    spinner={loadingDraft}
                    variant="contained"
                    color="primary"
                    className={classes.btnSave}
                    onClick={() => handlePublish(true)}
                  >
                    Save
                  </ButtonCustom>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item lg={7} container justify="center" className={classes.signaturePreview}>
              <Box className={classes.signatureContainer}>
                <Typography className={classes.previewText}>
                  This is a live render of how your signature will look in your email client.
                  <br />
                  We recommend your HubSpot Call To Action picture be no wider than 600 pixels.
                </Typography>
                <Box className={classes.signature}>
                  <SignatureDisplay
                    meetingLinkImg={signature.draft.meetingLinkImg}
                    embedCode={signature.draft.embedCode}
                    embedCodeTwo={signature.draft.embedCodeTwo}
                    customCode={signature.draft.customCode}
                    template={provideTemplateHtml(signature.draft.template.id)}
                    property={signature.draft.properties}
                    setOutputCopy
                    isPreviewMeetingLink
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} className={classes.signatureControl}>
              {/*<EditorControls  values={signature.properties} handleValue={updateStore} ></EditorControls>*/}
              <AppBar className={classes.appBar} color="default" position="static">
                <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="signature-editor-tabs">
                  <Tab label="Style" {...a11yProps(0)} />
                  <Tab label={`Template ( ${template ? template.length : 0} ) `} {...a11yProps(1)} />
                  {/*<Tab  label="Embed Code" {...a11yProps(2)} />*/}
                  <Tab label={`Users ( ${signature.users ? signature.users.length : 0} ) `} {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <EditorsControl />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Template />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <SignatureUser />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
