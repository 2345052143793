import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, createStyles, Dialog, Grid, makeStyles, Theme } from '@material-ui/core';
import { ButtonCustom, TextFieldCustom } from '../../../style';
import { AlertBox } from '../../../util';
import { useSubscriptionUpdateMutation } from '../../../api';
import { BillingSlice, SubscriptionSlice } from '../../../store';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(7),
    },
    modalBody: {
      padding: theme.spacing(7),
    },
    wrapper: {
      display: 'inline-flex',
      marginRight: theme.spacing(3),
    },
    change: {
      position: 'absolute',
      right: theme.spacing(8),
      color: theme.palette.background.paper,
    },
    card: {
      width: '100%',
      height: 40,
      '& .StripeElement': {
        width: '100%',
        padding: '10px',
        border: '1px solid #666666',
        borderRadius: 4,
      },
    },
    errorCard: {
      color: '#F64E60',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    cardContainer: {
      padding: theme.spacing(6),
      border: '4px dashed #F7F7FC',
      borderRadius: 8,
    },
    action: {
      marginTop: theme.spacing(4),
    },
  })
);

const iframeStyles = {
  base: {
    color: '#666666',
    fontSize: '16px',
    iconColor: '#666666',
    '::placeholder': {
      color: '#666666',
    },
    marginTop: '15px',
  },
  invalid: {
    iconColor: '#F64E60',
    color: '#F64E60',
  },
  complete: {
    iconColor: '#4A7DFF',
  },
};

const cardElementOpts = {
  iconStyle: 'solid',
  style: iframeStyles,
  hidePostalCode: true,
};

export const ChangeCard = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements: any = useElements();
  const { loadSubscription } = SubscriptionSlice.actions;
  const { loadBilling } = BillingSlice.actions;
  const { account } = useSelector(
    (state: any) => ({
      account: state.account.account,
      company: state.company.company,
      subscription: state.subscription.subscription,
      billing: state.billing.billing,
    }),
    shallowEqual
  );
  const [updateSubscription] = useSubscriptionUpdateMutation();
  const [billingDetails, setBillingDetails] = useState({ name: account.displayName, email: account.email });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // const minSubscriptions = subscription.inUse > 5 ? subscription.inUse : 5;

  const handleBillingChange = async (evt: any) => {
    const name = evt.target.value;
    setBillingDetails({ name, email: account.email });
  };

  const handleCardDetailsChange = (ev: any) => {
    if (ev.error) {
      toastr.error('Error', ev.error.message);
    }
  };

  const handleCardChange = async () => {
    if (!billingDetails.name) {
      return toastr.error('Error', "Enter cardholder's name");
    }
    enableLoading();
    const cardElement = elements.getElement('card');
    const paymentMethodReq: any = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails,
    });
    if (paymentMethodReq.error) {
      toastr.error('Error', paymentMethodReq.error.message);
      disableLoading();
      return;
    } else {
      const response = await updateSubscription({
        variables: {
          options: {
            type: 'card',
            paymentMethodId: paymentMethodReq.paymentMethod.id,
          },
        } as any,
      });
      if (!response.errors && !response.data!.subscriptionUpdate.errors) {
        dispatch(loadSubscription({ subscription: response.data!.subscriptionUpdate.subscription }));
        dispatch(loadBilling({ billing: response.data!.subscriptionUpdate.billing }));
        handleClose();
      } else {
        response.data!.subscriptionUpdate!.errors!.forEach((error: any) => {
          toastr.error('Error', error.message);
        });
      }
      disableLoading();
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box className={classes.change}>
        <ButtonCustom color="inherit" variant="outlined" onClick={handleClickOpen}>
          Change
        </ButtonCustom>
      </Box>

      <Dialog
        className={classes.modalBody}
        maxWidth="sm"
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid item lg={12} container>
          <Box className={classes.cardContainer}>
            <Grid item container justify="center" alignItems="center">
              <Grid item lg={12}>
                <AlertBox container="card-billing" />
              </Grid>
              <Grid item lg={12} container justify="center" alignItems="center" spacing={4}>
                <Grid item lg={12}>
                  <TextFieldCustom
                    fullWidth
                    id="name"
                    name="name"
                    variant="outlined"
                    color="primary"
                    label="Cardholder name"
                    value={billingDetails.name}
                    onChange={(evt) => handleBillingChange(evt)}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Box className={classes.card}>
                    <CardElement options={cardElementOpts as any} onChange={handleCardDetailsChange} />
                  </Box>
                </Grid>
                <Grid
                  className={classes.action}
                  container
                  item
                  xs={12}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <ButtonCustom disabled={loading} onClick={handleClose} color="primary">
                      Cancel
                    </ButtonCustom>
                  </Grid>
                  <Grid item>
                    <ButtonCustom
                      disabled={loading}
                      size="large"
                      onClick={handleCardChange}
                      spinner={loading ? loading : undefined}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Change Card
                    </ButtonCustom>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Dialog>
    </>
  );
};
