import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import shadowRoot from 'react-shadow';
import Handlebars from 'handlebars';
import * as DOMPurify from 'dompurify';
import { Box, createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { SignatureSlice } from '../../../../store';

Handlebars.registerHelper('sum', (a, b) => a + b);
Handlebars.registerHelper('multiply', (a, b) => a * b);
Handlebars.registerHelper('ifNotEq', (arg1, arg2, options) => {
  // @ts-ignore
  return arg1 !== arg2 ? options.fn() : options.inverse();
});
Handlebars.registerHelper('eq', function (a, b) {
  const next = arguments[arguments.length - 1];
  // @ts-ignore
  return a === b ? next.fn(this) : next.inverse(this);
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signaturePreview: {
      padding: theme.spacing(4),
      margin: theme.spacing(3),
      width: '650px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    previewArea: {
      maxWidth: 650,
      overflow: 'hidden',
    },
  })
);

export const SignatureDisplay = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setOutputCopy } = SignatureSlice.actions;
  const { property, company, socialItem } = useSelector(
    (state: any) => ({
      company: state.company.company,
      property: state.properties.property,
      socialItem: state.socials.socialItem,
    }),
    shallowEqual
  );
  const getProperty = () => {
    return props.property ? props.property : property;
  };
  const getAddress = (address: any) => {
    const newAddress: any = [];
    address.forEach((add: any) => {
      newAddress.push({
        icon: {
          src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Location.png',
          alt: 'Address',
        },
        line1: add.street ? add.street : '<Street>',
        line2: add.city ? add.city : '<City>',
        line3: add.post_code ? add.post_code : '<Postal Code>',
        line4: add.state ? add.state : '<State>',
        line5: add.country ? add.country : '<Country>',
        isDefault: add.isDefault,
      });
    });
    return newAddress;
  };
  const getSocials = (socials: any, type: string) => {
    const socialsDummy: any = [];
    const SocialsData: any = [];
    const property = getProperty();
    const userSocials = socialItem.filter((i: any) => i.linkBuffer && i.labelBuffer);

    if (type === 'dummy') {
      const signatureSocials = socialItem.filter((i: any) => socials.some((s:any) => i.id === s.details));

      signatureSocials.forEach((social: any) => {
        socialsDummy.push({
          link: '',
          label: social.name,
          icon: {
            src: social.icon_round,
            alt: social.name,
            backgroundColor: '',
          },
        });
      });
    }

    userSocials.forEach((social: any) => {
      SocialsData.push({
        link: social.linkBuffer,
        label: social.labelBuffer ? social.labelBuffer : social.name,
        icon: {
          src: property.socials.iconStyle === 'default' ? social.icon_round : social.icon_color,
          alt: social.name,
          backgroundColor: '',
        },
      });
    });

    if (type === 'dummy') {
      return socialsDummy.map((social: any) => {
        const map = SocialsData.filter((map: any) => map.icon.alt === social.icon.alt);
        if (map.length > 0) {
          return map[0];
        } else {
          return social;
        }
      });
    } else {
      return SocialsData;
    }
  };
  const [dataT, setDataT] = useState('');

  function stripScript(embedCode: string) {
    if (embedCode && embedCode.length) {
      return DOMPurify.sanitize(embedCode, {
        ALLOW_DATA_ATTR: false,
        ALLOW_ARIA_ATTR: false,
        ALLOW_UNKNOWN_PROTOCOLS: false,
      });
      // return embedCode.substring(embedCode.indexOf('<a href'), embedCode.indexOf('</a>') + 4);
    }
  }

  useEffect(() => {
    //when user reload the browser
    const user = props.user;
    const companyMain = props.company ? props.company : company;
    let companyUrl = companyMain.url;
    if (companyUrl && !companyUrl.includes('http://') && !companyUrl.includes('https://')) {
      companyUrl = 'https://' + companyUrl;
    }

    const embedCode: any = stripScript(props.embedCode);
    const embedCodeTwo: any = stripScript(props.embedCodeTwo);
    const customCode: any = stripScript(props.customCode);
    const meetingLinkImg: any = stripScript(props.meetingLinkImg);

    let data = {
      bio: {
        name: '<First Name> <Last Name>',
        jobTitle: '<Role>',
        department: '<Department>',
        companyName: '<Company Name>',
        customField: '<Custom Field>',
        profileLink: '<Profile Link>',
        avatar: {
          src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/placeholders/User-Profile.png',
          alt: 'profile',
        },
        companyLogo: {
          src: companyMain.image,
          alt: companyMain.name,
        },
      },
      contact: {
        email: {
          icon: {
            src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Mail.png',
            alt: 'Email',
          },
          link: '<Email Address>',
          display: '<Email Address>',
        },
        website: {
          icon: {
            src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Link.png',
            alt: 'Website',
          },
          link: companyUrl ? companyUrl : '',
          display: companyMain.url ? companyMain.url : '<Company Url>',
        },
        address: getAddress(companyMain.address),
        phone: {
          icon: {
            src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Phone.png',
            alt: 'Phone',
          },
          link: '<User Phone 1>',
          display: '<User Phone 1>',
          /*   phoneOne:{
                               link: user.phone,
                               display: user.phone,
                           },
                           phoneTwo: {
                               link: user.mobile,
                               display: user.mobile
                           },*/
        },
        mobile: {
          icon: {
            src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Mobile.png',
            alt: 'Phone',
          },
          link: '<User Phone 2>',
          display: '<User Phone 2>',
        },
        companyPhone: {
          icon: {
            src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Phone.png',
            alt: 'Phone',
          },
          link: companyMain.phone ? companyMain.phone : '<Company Phone>',
          display: companyMain.phone ? companyMain.phone : '<Company Phone>',
        },
        fax: {
          icon: {
            src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Fax.png',
            alt: 'Fax',
          },
          link: companyMain.fax ? companyMain.fax : '<Company Fax>',
          display: companyMain.fax ? companyMain.fax : '<Company Fax>',
        },
      },
      banner: {
        embedCode: embedCode ? embedCode : null,
      },
      bannerTwo: {
        embedCode: embedCodeTwo ? embedCodeTwo : null,
      },
      customCode: customCode ? customCode : null,
      isPreviewMeetingLink: props.isPreviewMeetingLink ? props.isPreviewMeetingLink : false,
      meetingLinkImg: meetingLinkImg ? meetingLinkImg : null,
      meetingLink: {
        link: null,
        label: '<Meeting Link>',
      },
      footnote: companyMain.footnote ? companyMain.footnote : null,
      socials: getSocials(companyMain.socials, 'dummy'), //take first user and then if not company
      display: props.property ? props.property : property,
    };
    if (user) {
      data = {
        bio: {
          name: user.name,
          jobTitle: user.role,
          department: user.department,
          companyName: companyMain.name,
          customField: user.custom,
          profileLink: user.profileLink,
          avatar: {
            src: user.image,
            alt: user.name,
          },
          companyLogo: {
            src: companyMain.image,
            alt: companyMain.name,
          },
        },
        contact: {
          email: {
            icon: {
              src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Mail.png',
              alt: 'Email',
            },
            link: user.email,
            display: user.email,
          },
          website: {
            icon: {
              src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Link.png',
              alt: 'Website',
            },
            link: companyUrl,
            display: companyMain.url,
          },
          address: getAddress(companyMain.address),
          phone: {
            icon: {
              src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Phone.png',
              alt: 'Phone',
            },
            link: user.phone,
            display: user.phone,
            /*   phoneOne:{
                                        link: user.phone,
                                        display: user.phone,
                                    },
                                    phoneTwo: {
                                        link: user.mobile,
                                        display: user.mobile
                                    },*/
          },
          mobile: {
            icon: {
              src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Mobile.png',
              alt: 'Phone',
            },
            link: user.mobile,
            display: user.mobile,
          },
          companyPhone: {
            icon: {
              src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Phone.png',
              alt: 'Phone',
            },
            link: companyMain.phone,
            display: companyMain.phone,
          },
          fax: {
            icon: {
              src: 'https://sigbuilder-dist.s3-ap-southeast-2.amazonaws.com/contact-icons/Fax.png',
              alt: 'Fax',
            },
            link: companyMain.fax,
            display: companyMain.fax,
          },
        },
        banner: {
          embedCode,
        },
        bannerTwo: {
          embedCode: embedCodeTwo,
        },
        customCode: customCode ? customCode : null,
        isPreviewMeetingLink: props.isPreviewMeetingLink ? props.isPreviewMeetingLink : false,
        meetingLinkImg: meetingLinkImg ? meetingLinkImg : null,
        meetingLink: {
          link: user.meetingLink,
          label: user.meetingLinkLabel,
        },
        footnote: companyMain.footnote,
        socials: user.socials ? getSocials(user.socials, 'live') : getSocials(companyMain.socials, 'live'), //take first user and then if not company
        display: props.property ? props.property : property,
      };
    }

    for (let social of data.socials) {
      if (social.link && !social.link.includes('https://') && !social.link.includes('https://')) {
        social.link = 'https://' + social.link;
      }
    }

    if (
      data.contact.website.link &&
      !data.contact.website.link.includes('http://') &&
      !data.contact.website.link.includes('https://')
    ) {
      data.contact.website.link = 'https://' + data.contact.website.link;
    }
    const template = Handlebars.compile(props.template);
    const dataHtml = template(data);
    if (props.setOutputCopy) {
      dispatch(setOutputCopy({ outputCopy: dataHtml }));
    }
    setDataT(dataHtml);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Grid container>
      <Grid item lg={12}>
        <shadowRoot.div className={classes.previewArea}>
          <Box className="preview" dangerouslySetInnerHTML={{ __html: dataT }} />
        </shadowRoot.div>
      </Grid>
    </Grid>
  );
};
