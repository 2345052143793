import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ButtonCustom } from '../../../../style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },
  })
);

export const GoogleSuccess = () => {
  const { userId } = useParams();
  const classes = useStyles();

  return (
    <Grid className={classes.root} container justify="center" alignItems="center">
      <Grid item lg={6} container justify="center" alignItems="center" spacing={4}>
        <Grid item lg={12}>
          <Typography color="textPrimary" align="center" variant="h3" component="h3">
            Your signature has been successfully connected to Google
          </Typography>
        </Grid>
        <Grid item lg={12} container justify="center" alignItems="center">
          <ButtonCustom variant="outlined" color="primary">
            <Link to={`/public/signature-copy/${userId}`}>Preview Signature</Link>
          </ButtonCustom>
        </Grid>
      </Grid>
    </Grid>
  );
};
