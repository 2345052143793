import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useOnBoardHubspotMutation } from '../../../api';
import { AccountSlice, SocialSlice } from '../../../store';
import { Splash } from '../../../style';
import { toastr } from 'react-redux-toastr';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ConfirmHubspot = (props: any) => {
  const history = useHistory();
  const query: any = useQuery();
  const dispatch = useDispatch();
  const { loadAccount } = AccountSlice.actions;
  const { loadSocialItem } = SocialSlice.actions;
  const [onBoardHubspot] = useOnBoardHubspotMutation();

  useEffect(() => {
    (async () => {
      if (query.get('code')) {
        const response = await onBoardHubspot({
          variables: {
            hubspot_code: query.get('code') as string,
          } as any,
        });
        if (!response.data!.onBoardHubspot.errors) {
          toastr.success('Success', 'You have successfully connected to HubSpot');
          dispatch(loadSocialItem({ socials: response.data!.onBoardHubspot!.socials })); //update the social item of store
          dispatch(loadAccount({ account: response.data!.onBoardHubspot!.account })); // update user every time mate user reload

          history.push('/onboard/company');
        } else {
          toastr.error('Error', response.data!.onBoardHubspot.errors[0].message);
          history.push('/onboard/hubspot');
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Splash />;
};
