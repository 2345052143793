import React, { useContext, useReducer, useState, createRef, createElement, createContext } from 'react';
import { AlertComponentType, AlertNode, AlertOptions, ContextAlert } from './types';
import { AlertCustom } from './AlertCustom';
import { AlertBox } from '.';

const initialComponent = { components: null };

function reducerComponent(state: any, action: any) {
  switch (action.type) {
    case 'add':
      return { components: action.payload };
    case 'remove':
      return { components: state.components.filter((item: any) => item.key !== action.payload) };
    default:
      throw new Error();
  }
}

const AlertContext = createContext({});
const useAlert = () => useContext(AlertContext) as any;
const AlertProvider = ({ children, ...props }: any) => {
  const [alerts, dispatchAlert] = useReducer(reducerComponent, initialComponent);
  const [counter, setCounter] = useState(1);
  const context: ContextAlert = {
    components: alerts.components,
    trigger(options?: AlertOptions) {
      const ref = createRef();
      let optionsNew = {
        ...options,
        container: options!.container ? options!.container : 'global',
        data: options!.data,
      };

      const component: AlertNode = createElement(AlertCustom, {
        ref,
        id: counter + '_alert',
        options: optionsNew,
      });

      if (alerts.components === null) {
        dispatchAlert({
          type: 'add',
          payload: [{ component, ref, key: counter + '_alert', options: optionsNew }],
        });
      } else {
        const newAlerts: [AlertComponentType] = alerts.components;
        newAlerts.push({ component, ref, key: counter + '_alert', options: optionsNew });
        dispatchAlert({ type: 'add', payload: newAlerts });
      }

      setCounter((state) => state + 1);
      return `${counter}_alert`;
    },
    remove(alert) {
      dispatchAlert({ type: 'remove', payload: alert });
    },
  };
  const direction = props.direction ? props.direction : 'top-right';

  return (
    <AlertContext.Provider value={context}>
      <AlertBox container="global" dir={direction}></AlertBox>
      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider, useAlert };
