import { createSlice } from '@reduxjs/toolkit';
import { PropertiesState } from '../properties';
import { UsersEntities } from '../users';

interface templateType {
  name: string;
  id: string;
}
export interface SignatureDraftEntities {
  id: string;
  name: string;
  meetingLink: string;
  meetingLinkImg: string;
  embedCode: string;
  embedCodeTwo: string;
  customCode: string;
  template: templateType;
  properties: PropertiesState;
}
export interface SignatureEntities {
  id: string;
  name: string;
  meetingLink: string;
  meetingLinkImg: string;
  embedCode: string;
  embedCodeTwo: string;
  customCode: string;
  users: UsersEntities[];
  template: templateType;
  properties: PropertiesState;
  hasDraft: boolean; //
  draft: SignatureDraftEntities;
  hasChanges: boolean;
  outputCopy: (dataHtml: string) => void;
}
interface initialType {
  lastAction: string | null;
  signatureItems: SignatureEntities[] | null;
  active: SignatureEntities | null;
  hasChanges: boolean | null;
}

const SignatureState: initialType = {
  lastAction: null,
  signatureItems: null,
  active: null,
  hasChanges: false,
};
export const callTypesSignature = {
  list: 'list',
  action: 'action',
};

export const SignatureSlice = createSlice({
  name: 'Signature Objects',
  initialState: SignatureState,
  reducers: {
    clear: (state: any, action) => {
      state.active = null;
      state.lastAction = action.type;
    },
    loadSignatures: (state: any, action) => {
      const { signatures } = action.payload;
      state.signatureItems = signatures;
      state.lastAction = action.type;
    },
    setActive: (state: any, action) => {
      const { signature } = action.payload;
      state.active = signature;
      state.lastAction = action.type;
    },
    setHasChanges: (state: any, action) => {
      const { change } = action.payload;
      state.hasChanges = change;
      state.lastAction = action.type;
    },
    setOutputCopy: (state: any, action) => {
      const { outputCopy } = action.payload;
      state.outputCopy = outputCopy;
      state.lastAction = action.type;
    },
    updateSignature: (state: any, action) => {
      const { signature } = action.payload;
      state.signatureItems = state.signatureItems.map((sig: SignatureEntities) => {
        if (signature.id === sig.id) {
          return signature;
        } else {
          return sig;
        }
      });
      state.lastAction = action.type;
    },
    addSignature: (state: any, action) => {
      try {
        const { signature } = action.payload; //array of signature
        if (!state.signatureItems) {
          state.signatureItems = [];
        }
        state.signatureItems.unshift(signature);
        state.lastAction = action.type;
      } catch (e) {
        console.error('i am error', e.message);
      }
    },
    removeSignature: (state: any, action) => {
      const { signature } = action.payload; //array of signature
      state.signatureItems = state.signatureItems
        .map((signatureState: SignatureEntities) => {
          if (signatureState.id !== signature.id) {
            return signatureState;
          }
          return null;
        })
        .filter((val: SignatureEntities) => !!val);
      state.lastAction = action.type;
    },
    assignSignature: (state: any, action) => {
      const { signature } = action.payload; //array of signature
      state.signatureItems = state.signatureItems.map((signatureMap: SignatureEntities) => {
        if (signatureMap.id === signature.id) {
          signatureMap.users = signature.users;
        }
        return signatureMap;
      });
      state.lastAction = action.type;
    },
    detachSignatureUser: (state: any, action) => {
      try {
        const { user, signature } = action.payload; //array of signature
        if (signature !== '') {
          const newSignatures: SignatureEntities[] = [];
          state.signatureItems.forEach((signatureMap: SignatureEntities) => {
            let signatureNew = signatureMap;
            if (signatureNew.id === signature) {
              const users: UsersEntities[] = [];
              if (signatureNew.users) {
                signatureNew.users.forEach((usermap: UsersEntities) => {
                  if (usermap.id !== user) {
                    users.push(usermap);
                  }
                });
                signatureNew.users = users;
                if (state.active) {
                  if (state.active.id === signature) {
                    state.active = signatureNew;
                  }
                }
              }
            }

            newSignatures.push(signatureNew);
          });

          state.signatureItems = newSignatures;
          state.lastAction = action.type;
        }
      } catch (e) {
        console.error(e, e.message);
      }
    },
  },
});
