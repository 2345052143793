const escapedTags = [
  'applet',
  'base',
  'basefont',
  'command',
  'embed',
  'frame',
  'frameset',
  'iframe',
  'keygen',
  'link',
  'meta',
  'noframes',
  'noscript',
  'object',
  'param',
  'script',
  'title',
];

const escapedAttributes = [
  'accept-charset',
  'accesskey',
  'allow',
  'async',
  'autocapitalize',
  'autofocus',
  'autoplay',
  'buffered',
  'challenge',
  'charset',
  'code',
  'codebase',
  'content',
  'contenteditable',
  'contextmenu',
  'controls',
  'data',
  'decoding',
  'defer',
  'dirname',
  'draggable',
  'dropzone',
  'form',
  'formaction',
  'http-equiv',
  'icon',
  'importance',
  'itemprop',
  'keytype',
  'kind',
  'language',
  'lazyload',
  'manifest',
  'minlength',
  'muted',
  'ping',
  'sandbox',
  'scoped',
  'slot',
  'spellcheck',
  'srcdoc',
  'srclang',
  'start',
  'target',
  'translate',
  'wrap',
];

export { escapedTags, escapedAttributes };
