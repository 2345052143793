import React from 'react';
import { Link } from 'react-router-dom';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(6),
    },
    billing: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.h5.fontWeight,
      lineHeight: theme.typography.h5.lineHeight,
      color: 'inherit',
      textDecoration: 'underline',
    },
  })
);

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SubHeader() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Alert severity="error">
        <Box display="inline-flex">
          <Typography variant="h5" component="div">
            We were unable to make payment from your supplied card, we will retry every day until 3 weeks from renew date. If you
            want to change the card go to.{' '}
            <Link className={classes.billing} to="/billing">
              billings
            </Link>
          </Typography>
        </Box>
      </Alert>
    </Container>
  );
}
