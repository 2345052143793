import React, { createContext, useContext, useEffect, useState } from 'react';
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    scroll: {
      position: 'fixed',
      bottom: theme.spacing(14),
      right: theme.spacing(14),
    },
  })
);

const Scroll = () => {
  const classes = useStyles();
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, []);

  const checkScrollTop = () => {
    if (window.pageYOffset > 100) {
      setShowScroll(true);
    } else if (window.pageYOffset <= 100) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <IconButton color="primary" aria-label="scroll" className={classes.scroll} onClick={scrollTop}>
      <ArrowUpwardIcon color="primary" fontSize="large" style={{ display: showScroll ? 'flex' : 'none' }} />
    </IconButton>
  );
};

const initialContext: any = {
  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
};

const ScrollContext = createContext(initialContext);

export const useScroll = () => {
  return useContext(ScrollContext);
};

const ScrollProvider = ({ children }: any) => {
  const context: any = {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  };

  return (
    <ScrollContext.Provider value={context}>
      {children}
      <Scroll />
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
