import { GraphQLError } from 'graphql/error/GraphQLError';
import { ServerError, ServerParseError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

interface Errors {
  type: string;
  graphQLErrors: readonly GraphQLError[] | undefined;
  networkError: Error | ServerError | ServerParseError | undefined;
}

export const appError = (error: Errors) => {
  switch (error.type) {
    case 'graphql':
      console.error(error.graphQLErrors, error.networkError);
      break;
  }
};
export const graphQlError = onError(({ graphQLErrors, networkError }) =>
  appError({ type: 'graphql', graphQLErrors, networkError })
);
