import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AccountEntities = {
  __typename?: 'AccountEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  email: Scalars['String'];
  userName: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  refresh_token_version: Scalars['Float'];
  terms_accepted?: Maybe<Scalars['Boolean']>;
  hubspot_portal_id?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<TeamEntities>>;
  users?: Maybe<Array<UsersEntities>>;
  signatures?: Maybe<Array<SignatureEntities>>;
  password_history?: Maybe<Array<PasswordHistoryEntities>>;
  hub_token?: Maybe<TokenEntities>;
  company?: Maybe<CompanyEntities>;
  profile?: Maybe<AccountProfileEntities>;
  subscription?: Maybe<SubscriptionEntities>;
  hubDomain: Scalars['String'];
  hsId: Scalars['String'];
};

export type AccountFacebookLoginInput = {
  email: Scalars['String'];
};

export type AccountGoogleLoginInput = {
  credential: Scalars['String'];
};

export type AccountInfo = {
  __typename?: 'AccountInfo';
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  hubDomain?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  terms_accepted?: Maybe<Scalars['String']>;
  profile?: Maybe<AccountProfileEntities>;
  hubspot_portal_id?: Maybe<Scalars['String']>;
};

export type AccountLoginInput = {
  userName: Scalars['String'];
  password: Scalars['String'];
};

export type AccountPasswordChangeInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type AccountProfileEntities = {
  __typename?: 'AccountProfileEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  custom?: Maybe<Scalars['String']>;
  profileLink?: Maybe<Scalars['String']>;
  meetingLink?: Maybe<Scalars['String']>;
  meetingLinkLabel?: Maybe<Scalars['String']>;
  socials?: Maybe<Array<SocialsEntities>>;
  account?: Maybe<AccountEntities>;
};

export type AccountProfileResponse = {
  __typename?: 'AccountProfileResponse';
  errors?: Maybe<Array<FieldError>>;
  profile?: Maybe<AccountProfileEntities>;
  account: AccountEntities;
};

export type AccountProfileUpdateInput = {
  image?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  custom?: Maybe<Scalars['String']>;
  profileLink?: Maybe<Scalars['String']>;
  meetingLink?: Maybe<Scalars['String']>;
  meetingLinkLabel?: Maybe<Scalars['String']>;
};

export type AccountPublicResponse = {
  __typename?: 'AccountPublicResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<UsersEntities>;
  socials?: Maybe<Array<SocialDetailsEntities>>;
  account?: Maybe<AccountInfo>;
};

export type AccountResponse = {
  __typename?: 'AccountResponse';
  errors?: Maybe<Array<FieldError>>;
  teams?: Maybe<Array<TeamEntities>>;
  signatures?: Maybe<Array<SignatureEntities>>;
  users?: Maybe<Array<UsersEntities>>;
  company?: Maybe<CompanyEntities>;
  subscription?: Maybe<SubscriptionEntities>;
  template?: Maybe<Array<TemplateEntities>>;
  socials?: Maybe<Array<SocialDetailsEntities>>;
  account?: Maybe<AccountInfo>;
  accessToken?: Maybe<Scalars['String']>;
  billing?: Maybe<StripeCardType>;
  cursors?: Maybe<CursorsType>;
};

export type AddressEntities = {
  __typename?: 'AddressEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompanyEntities>;
};

export type AvatarProperty = {
  __typename?: 'AvatarProperty';
  size?: Maybe<Scalars['Float']>;
  shape?: Maybe<Scalars['String']>;
  borderRadius?: Maybe<Scalars['String']>;
};

export type AvatarSignatureProperty = {
  size?: Maybe<Scalars['Float']>;
  shape?: Maybe<Scalars['String']>;
  borderRadius?: Maybe<Scalars['String']>;
};

export type CompanyEntities = {
  __typename?: 'CompanyEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  footnote?: Maybe<Scalars['String']>;
  address?: Maybe<Array<AddressEntities>>;
  socials?: Maybe<Array<SocialsEntities>>;
  account?: Maybe<AccountEntities>;
};

export type CompanyUpdateInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  phone: Scalars['String'];
  secondaryPhone: Scalars['String'];
  fax?: Maybe<Scalars['String']>;
  footnote: Scalars['String'];
  socials?: Maybe<Array<SocialInput>>;
  address?: Maybe<Array<OnBoardAddressInput>>;
};

export type CompanyUpdateResponse = {
  __typename?: 'CompanyUpdateResponse';
  errors?: Maybe<Array<FieldError>>;
  company?: Maybe<CompanyEntities>;
};

export type ConnectionArgs = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars['String']>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars['String']>;
  /** Paginate first */
  first?: Maybe<Scalars['Float']>;
  /** Paginate last */
  last?: Maybe<Scalars['Float']>;
};

export type CursorsObjectType = {
  __typename?: 'CursorsObjectType';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type CursorsType = {
  __typename?: 'CursorsType';
  users?: Maybe<CursorsObjectType>;
};


export type DividerProperty = {
  __typename?: 'dividerProperty';
  style?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
};

export type DividerSignatureProperty = {
  style: Scalars['String'];
  thickness?: Maybe<Scalars['Float']>;
  color: Scalars['String'];
  show?: Maybe<Scalars['Boolean']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FontProperty = {
  __typename?: 'fontProperty';
  fontFamily?: Maybe<Scalars['String']>;
  fontStyle?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Float']>;
  fontWeight?: Maybe<Scalars['Float']>;
  lineHeight?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
};

export type FontSignatureProperty = {
  fontStyle?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Float']>;
  fontWeight?: Maybe<Scalars['Float']>;
  lineHeight?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
};

export type GoogleGenerateAuthLinkInput = {
  id: Scalars['String'];
  email: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type GoogleGenerateAuthLinkResponse = {
  __typename?: 'GoogleGenerateAuthLinkResponse';
  errors?: Maybe<Array<FieldError>>;
  url?: Maybe<Scalars['String']>;
};

export type GoogleVerifyInput = {
  code: Scalars['String'];
  state: Scalars['String'];
};

export type GoogleVerifyResponse = {
  __typename?: 'GoogleVerifyResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<UsersEntities>;
  redirectUri?: Maybe<Scalars['String']>;
};

export type HubspotAuthTypes = {
  __typename?: 'HubspotAuthTypes';
  errors?: Maybe<Array<FieldError>>;
  link?: Maybe<Scalars['String']>;
};

export type HubspotListInput = {
  limit?: Maybe<Scalars['Float']>;
  cursor?: Maybe<Scalars['String']>;
};

export type HubspotListItem = {
  __typename?: 'HubspotListItem';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<HubspotTeams>>;
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  linked_google?: Maybe<Scalars['Boolean']>;
  has_owner?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['String']>;
};

export type HubspotListTypes = {
  __typename?: 'HubspotListTypes';
  errors?: Maybe<Array<FieldError>>;
  list?: Maybe<Array<HubspotListItem>>;
  cursor?: Maybe<Scalars['String']>;
};

export type HubspotTeams = {
  __typename?: 'HubspotTeams';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IconProperty = {
  __typename?: 'iconProperty';
  social?: Maybe<IconStyle>;
  contact?: Maybe<IconStyle>;
  size?: Maybe<Scalars['Float']>;
  show?: Maybe<Scalars['Boolean']>;
};

export type IconSignatureProperty = {
  social: IconSignatureStyle;
  contact: IconSignatureStyle;
  size?: Maybe<Scalars['Float']>;
  show?: Maybe<Scalars['Boolean']>;
};

export type IconSignatureStyle = {
  backgroundColor: Scalars['String'];
};

export type IconStyle = {
  __typename?: 'iconStyle';
  backgroundColor?: Maybe<Scalars['String']>;
};

export type LinkedPlatformEntities = {
  __typename?: 'LinkedPlatformEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  token?: Maybe<TokenEntities>;
  linked: Scalars['Boolean'];
  user: UsersEntities;
};

export type LogoProperty = {
  __typename?: 'LogoProperty';
  size?: Maybe<Scalars['Float']>;
};

export type LogoSignatureProperty = {
  size?: Maybe<Scalars['Float']>;
};

export type MeetingLinkProperty = {
  __typename?: 'MeetingLinkProperty';
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Float']>;
  fontWeight?: Maybe<Scalars['Float']>;
  lineHeight?: Maybe<Scalars['Float']>;
  hPadding?: Maybe<Scalars['Float']>;
  vPadding?: Maybe<Scalars['Float']>;
  borderWidth?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderStyle?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
};

export type MeetingLinkSignatureProperty = {
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Float']>;
  fontWeight?: Maybe<Scalars['Float']>;
  lineHeight?: Maybe<Scalars['Float']>;
  hPadding?: Maybe<Scalars['Float']>;
  vPadding?: Maybe<Scalars['Float']>;
  borderWidth?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderStyle?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
};

export type MetaData = {
  __typename?: 'metaData';
  key?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AccountChangePassword: AccountResponse;
  AccountForgotPassword: AccountResponse;
  AccountLogout: Scalars['Boolean'];
  revokeRefreshTokensForAccount: Scalars['Boolean'];
  AccountLogin: AccountResponse;
  AccountGoogleLogin: AccountResponse;
  AccountFacebookLogin: AccountResponse;
  AccountProfileUpdateServiceImage: AccountProfileResponse;
  AccountProfileUpdate: AccountProfileResponse;
  socialItems: SocialItemResponseTypes;
  onBoardHubspot: OnBoardHubspotResponseTypes;
  onBoardForm: OnBoardFormResponseTypes;
  onBoardCompany: OnBoardCompanyResponseTypes;
  onBoardProfile: OnBoardProfileResponseTypes;
  onBoardSubscription: OnBoardSubscriptionResponseTypes;
  onBoardImport: OnBoardImportResponseTypes;
  GoogleVerify: GoogleVerifyResponse;
  GoogleGenerateAuthLink: GoogleGenerateAuthLinkResponse;
  GetGoogleUser: GoogleVerifyResponse;
  templateAdd: TemplateResponse;
  signatureAdd: SignatureResponse;
  signatureDetachUsers: SignatureResponse;
  signaturePublish: SignatureResponse;
  signatureRevert: SignatureResponse;
  signatureRemove: SignatureDeleteResponse;
  signatureAssign: SignatureResponse;
  SignaturePushToGmail: SignatureResponse;
  userSearchList: UserSearchResponseTypes;
  userDetachSignature: UserDetachResponse;
  userRemove: UserDetachResponse;
  UserUpdateServiceImage: UserResponse;
  UserUpdateService: UserResponse;
  UserSend: UserResponse;
  UserVerify: AccountPublicResponse;
  UserLogin: UserLoginResponse;
  userGoogleLogin: UserLoginResponse;
  userImport: OnBoardImportResponseTypes;
  subscriptionPostCreate: OnBoardSubscriptionResponseTypes;
  subscriptionUpdate: SubscriptionUpdateResponse;
  subscriptionCancel: SubscriptionCancelResponse;
  retrieveInvoice: SubscriptionInvoiceResponse;
  subscriptionUpgrade: OnBoardSubscriptionResponseTypes;
  updateCompanyImage: CompanyUpdateResponse;
  updateCompany: CompanyUpdateResponse;
};


export type MutationAccountChangePasswordArgs = {
  options: AccountPasswordChangeInput;
};


export type MutationAccountForgotPasswordArgs = {
  userName: Scalars['String'];
};


export type MutationRevokeRefreshTokensForAccountArgs = {
  accountId: Scalars['Int'];
};


export type MutationAccountLoginArgs = {
  options: AccountLoginInput;
};


export type MutationAccountGoogleLoginArgs = {
  options: AccountGoogleLoginInput;
};


export type MutationAccountFacebookLoginArgs = {
  options: AccountFacebookLoginInput;
};


export type MutationAccountProfileUpdateServiceImageArgs = {
  options: AccountProfileUpdateInput;
  image?: Maybe<Scalars['Upload']>;
};


export type MutationAccountProfileUpdateArgs = {
  options: AccountProfileUpdateInput;
};


export type MutationSocialItemsArgs = {
  options: SocialItemInput;
};


export type MutationOnBoardHubspotArgs = {
  options: OnBoardHubspotInput;
};


export type MutationOnBoardFormArgs = {
  options: OnBoardFormInput;
};


export type MutationOnBoardCompanyArgs = {
  options: OnBoardCompanyInput;
  image: Scalars['Upload'];
};


export type MutationOnBoardProfileArgs = {
  options: OnBoardProfileInput;
  image: Scalars['Upload'];
};


export type MutationOnBoardSubscriptionArgs = {
  options: OnBoardSubscriptionInput;
};


export type MutationOnBoardImportArgs = {
  options: OnBoardImportInput;
};


export type MutationGoogleVerifyArgs = {
  options: GoogleVerifyInput;
};


export type MutationGoogleGenerateAuthLinkArgs = {
  options: GoogleGenerateAuthLinkInput;
};


export type MutationGetGoogleUserArgs = {
  options: GoogleVerifyInput;
};


export type MutationTemplateAddArgs = {
  options: TemplateInput;
};


export type MutationSignatureAddArgs = {
  options: SignatureInput;
};


export type MutationSignatureDetachUsersArgs = {
  options: SignatureDetachInput;
};


export type MutationSignaturePublishArgs = {
  options: SignaturePublishInput;
};


export type MutationSignatureRevertArgs = {
  options: SignatureRevertInput;
};


export type MutationSignatureRemoveArgs = {
  options: SignatureDeleteInput;
};


export type MutationSignatureAssignArgs = {
  options: SignatureAssignInput;
};


export type MutationSignaturePushToGmailArgs = {
  options: SignaturePushInput;
};


export type MutationUserSearchListArgs = {
  arg: ConnectionArgs;
  option: UserSearchInput;
};


export type MutationUserDetachSignatureArgs = {
  options: UserDetachInput;
};


export type MutationUserRemoveArgs = {
  options: UserDetachInput;
};


export type MutationUserUpdateServiceImageArgs = {
  options: UserUpdateInput;
  image: Scalars['Upload'];
};


export type MutationUserUpdateServiceArgs = {
  options: UserUpdateInput;
};


export type MutationUserSendArgs = {
  options: UserSendInput;
};


export type MutationUserVerifyArgs = {
  options: UserVerifyInput;
};


export type MutationUserLoginArgs = {
  options: UserLoginInput;
};


export type MutationUserGoogleLoginArgs = {
  options: UserGoogleLoginInput;
};


export type MutationUserImportArgs = {
  options: OnBoardImportInput;
  image?: Maybe<Scalars['Upload']>;
};


export type MutationSubscriptionPostCreateArgs = {
  options: SubscriptionPostInput;
};


export type MutationSubscriptionUpdateArgs = {
  options: SubscriptionUpdateInput;
};


export type MutationSubscriptionUpgradeArgs = {
  options: OnBoardSubscriptionInput;
};


export type MutationUpdateCompanyImageArgs = {
  options: CompanyUpdateInput;
  image: Scalars['Upload'];
};


export type MutationUpdateCompanyArgs = {
  options: CompanyUpdateInput;
};

export type OnBoardAddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  post_code: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type OnBoardCompanyInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  phone: Scalars['String'];
  secondaryPhone: Scalars['String'];
  footnote: Scalars['String'];
  fax: Scalars['String'];
  socials?: Maybe<Array<SocialInput>>;
  address?: Maybe<Array<OnBoardAddressInput>>;
};

export type OnBoardCompanyResponseTypes = {
  __typename?: 'OnBoardCompanyResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  company?: Maybe<CompanyEntities>;
  account?: Maybe<AccountInfo>;
};

export type OnBoardFormInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userName: Scalars['String'];
  terms_accepted: Scalars['Boolean'];
  password: Scalars['String'];
};

export type OnBoardFormResponseTypes = {
  __typename?: 'OnBoardFormResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  accessToken?: Maybe<Scalars['String']>;
  account?: Maybe<AccountInfo>;
  socials?: Maybe<Array<SocialDetailsEntities>>;
};

export type OnBoardHubspotImportInput = {
  hub_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  teams?: Maybe<Array<TeamInput>>;
  linked_google?: Maybe<Scalars['Boolean']>;
  has_owner?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['String']>;
};

export type OnBoardHubspotInput = {
  hubspot_code: Scalars['String'];
};

export type OnBoardHubspotResponseTypes = {
  __typename?: 'OnBoardHubspotResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  account?: Maybe<AccountInfo>;
  socials?: Maybe<Array<SocialDetailsEntities>>;
};

export type OnBoardImportInput = {
  users?: Maybe<Array<OnBoardHubspotImportInput>>;
};

export type OnBoardImportResponseTypes = {
  __typename?: 'OnBoardImportResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  users?: Maybe<Array<UsersEntities>>;
  account?: Maybe<AccountInfo>;
  subscription?: Maybe<SubscriptionEntities>;
  template?: Maybe<Array<TemplateEntities>>;
  teams?: Maybe<Array<TeamEntities>>;
};

export type OnBoardProfileInput = {
  role?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  custom?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  socials?: Maybe<Array<SocialInput>>;
};

export type OnBoardProfileResponseTypes = {
  __typename?: 'OnBoardProfileResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  account?: Maybe<AccountInfo>;
};

export type OnBoardSubscriptionInput = {
  type: Scalars['String'];
  quantity: Scalars['String'];
  coupon?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
};

export type OnBoardSubscriptionResponseTypes = {
  __typename?: 'OnBoardSubscriptionResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  subscription?: Maybe<SubscriptionEntities>;
  billing?: Maybe<StripeCardType>;
  account?: Maybe<AccountInfo>;
};

export type PageData = {
  __typename?: 'PageData';
  count: Scalars['Float'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type PasswordHistoryEntities = {
  __typename?: 'PasswordHistoryEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  password: Scalars['String'];
  account: AccountEntities;
};

export type PropertiesEntities = {
  __typename?: 'PropertiesEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  heading: FontProperty;
  body: FontProperty;
  link: FontProperty;
  icons: IconProperty;
  dividers: DividerProperty;
  separator?: Maybe<SeparatorProperty>;
  avatar: AvatarProperty;
  socials: SocialsProperty;
  logo: LogoProperty;
  footnote?: Maybe<FontProperty>;
  meetingLink?: Maybe<MeetingLinkProperty>;
  signature?: Maybe<SignatureEntities>;
  draft?: Maybe<SignatureDraftEntities>;
};

export type PropertyInput = {
  heading?: Maybe<FontSignatureProperty>;
  body?: Maybe<FontSignatureProperty>;
  link?: Maybe<FontSignatureProperty>;
  footnote?: Maybe<FontSignatureProperty>;
  icons?: Maybe<IconSignatureProperty>;
  dividers?: Maybe<DividerSignatureProperty>;
  separator?: Maybe<SeparatorSignatureProperty>;
  avatar?: Maybe<AvatarSignatureProperty>;
  socials?: Maybe<SocialsSignatureProperty>;
  logo?: Maybe<LogoSignatureProperty>;
  meetingLink?: Maybe<MeetingLinkSignatureProperty>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<AccountResponse>;
  hubspotAuthUrl: HubspotAuthTypes;
  hubspotUserList: HubspotListTypes;
  socialItemsList: SocialItemListResponseTypes;
  onBoardHubspotAuthUrl: HubspotAuthTypes;
  templateList: TemplateListResponseTypes;
  signatureList?: Maybe<SignatureListResponseTypes>;
  signatureCopyHtml: SignatureCopyResponseTypes;
  teamList: TeamListResponseTypes;
};


export type QueryHubspotUserListArgs = {
  options: HubspotListInput;
};


export type QueryTemplateListArgs = {
  arg: ConnectionArgs;
};


export type QuerySignatureListArgs = {
  arg: ConnectionArgs;
};


export type QuerySignatureCopyHtmlArgs = {
  options: SignatureCopyInput;
};


export type QueryTeamListArgs = {
  arg: ConnectionArgs;
};

export type SeparatorProperty = {
  __typename?: 'separatorProperty';
  type?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type SeparatorSignatureProperty = {
  type?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type SignatureAssignInput = {
  users?: Maybe<Array<UserIdInput>>;
  signature?: Maybe<Scalars['String']>;
};

export type SignatureCopyInput = {
  user?: Maybe<Scalars['String']>;
};

export type SignatureCopyResponseTypes = {
  __typename?: 'SignatureCopyResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  signature?: Maybe<SignatureEntities>;
  template?: Maybe<TemplateEntities>;
  user?: Maybe<UsersEntities>;
  account?: Maybe<AccountInfo>;
  company?: Maybe<CompanyEntities>;
  socials?: Maybe<Array<SocialDetailsEntities>>;
  googleLink?: Maybe<Scalars['String']>;
};

export type SignatureDeleteInput = {
  signature?: Maybe<Scalars['String']>;
};

export type SignatureDeleteResponse = {
  __typename?: 'SignatureDeleteResponse';
  errors?: Maybe<Array<FieldError>>;
  signature?: Maybe<Scalars['String']>;
};

export type SignatureDetachInput = {
  signature?: Maybe<Scalars['String']>;
  users?: Maybe<Array<UserIdInput>>;
};

export type SignatureDraftEntities = {
  __typename?: 'SignatureDraftEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  meetingLinkImg?: Maybe<Scalars['String']>;
  customCode?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  embedCodeTwo?: Maybe<Scalars['String']>;
  template: TemplateEntities;
  properties?: Maybe<PropertiesEntities>;
  signature?: Maybe<SignatureEntities>;
};

export type SignatureEntities = {
  __typename?: 'SignatureEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  meetingLinkImg?: Maybe<Scalars['String']>;
  customCode?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  embedCodeTwo?: Maybe<Scalars['String']>;
  users?: Maybe<Array<UsersEntities>>;
  account?: Maybe<AccountEntities>;
  template?: Maybe<TemplateEntities>;
  properties?: Maybe<PropertiesEntities>;
  hasDraft?: Maybe<Scalars['Boolean']>;
  draft?: Maybe<SignatureDraftEntities>;
};

export type SignatureEntitiesConnection = {
  __typename?: 'SignatureEntitiesConnection';
  edges?: Maybe<Array<SignatureEntitiesEdge>>;
  pageInfo?: Maybe<SignatureEntitiesPageInfo>;
};

export type SignatureEntitiesEdge = {
  __typename?: 'SignatureEntitiesEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SignatureEntities>;
};

export type SignatureEntitiesPageInfo = {
  __typename?: 'SignatureEntitiesPageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
};

export type SignatureInput = {
  meetingLinkImg?: Maybe<Scalars['String']>;
  customCode?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  embedCodeTwo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  properties?: Maybe<PropertyInput>;
};

export type SignatureListResponseTypes = {
  __typename?: 'SignatureListResponseTypes';
  page?: Maybe<SignatureEntitiesConnection>;
  pageData?: Maybe<PageData>;
  errors?: Maybe<Array<FieldError>>;
};

export type SignaturePublishInput = {
  signature?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  meetingLinkImg?: Maybe<Scalars['String']>;
  customCode?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  embedCodeTwo?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  hasDraft?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<PropertyInput>;
};

export type SignaturePushInput = {
  signature?: Maybe<Scalars['String']>;
};

export type SignatureResponse = {
  __typename?: 'SignatureResponse';
  errors?: Maybe<Array<FieldError>>;
  signature?: Maybe<SignatureEntities>;
};

export type SignatureRevertInput = {
  signature?: Maybe<Scalars['String']>;
};

export type SocialDetailsEntities = {
  __typename?: 'SocialDetailsEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  icon_round: Scalars['String'];
  icon_solid: Scalars['String'];
  icon_square: Scalars['String'];
  icon_color: Scalars['String'];
  priority: Scalars['Boolean'];
};

export type SocialInput = {
  link: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type SocialItemInput = {
  name: Scalars['String'];
  icon_round: Scalars['String'];
  icon_solid: Scalars['String'];
  icon_square: Scalars['String'];
  icon_color: Scalars['String'];
  priority: Scalars['Boolean'];
};

export type SocialItemListResponseTypes = {
  __typename?: 'SocialItemListResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  socialItem?: Maybe<Array<SocialDetailsEntities>>;
};

export type SocialItemResponseTypes = {
  __typename?: 'SocialItemResponseTypes';
  errors?: Maybe<Array<FieldError>>;
  socialItem?: Maybe<SocialDetailsEntities>;
};

export type SocialsEntities = {
  __typename?: 'SocialsEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  ownerType?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyEntities>;
  user?: Maybe<UsersEntities>;
  profile?: Maybe<AccountProfileEntities>;
};

export type SocialsProperty = {
  __typename?: 'SocialsProperty';
  show?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Float']>;
  iconStyle?: Maybe<Scalars['String']>;
};

export type SocialsSignatureProperty = {
  show?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Float']>;
  iconStyle?: Maybe<Scalars['String']>;
};

export type Stripe3D = {
  __typename?: 'Stripe3D';
  supported?: Maybe<Scalars['Boolean']>;
};

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type StripeBillingDetails = {
  __typename?: 'StripeBillingDetails';
  address?: Maybe<StripeAddress>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  brand?: Maybe<Scalars['String']>;
  checks?: Maybe<StripeChecks>;
  country?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['String']>;
  exp_year?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  generated_from?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  networks?: Maybe<StripeNetworks>;
  three_d_secure_usage?: Maybe<Stripe3D>;
  wallet?: Maybe<Scalars['String']>;
};

export type StripeCardType = {
  __typename?: 'StripeCardType';
  id?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  billing_details?: Maybe<StripeBillingDetails>;
  card?: Maybe<StripeCard>;
  created?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<MetaData>;
  type?: Maybe<Scalars['String']>;
};

export type StripeChecks = {
  __typename?: 'StripeChecks';
  address_line1_check?: Maybe<Scalars['String']>;
  address_postal_code_check?: Maybe<Scalars['String']>;
  cvc_check?: Maybe<Scalars['String']>;
};

export type StripeNetworks = {
  __typename?: 'StripeNetworks';
  available?: Maybe<Array<Scalars['String']>>;
  preferred?: Maybe<Scalars['String']>;
};

export type SubscriptionCancelResponse = {
  __typename?: 'SubscriptionCancelResponse';
  errors?: Maybe<Array<FieldError>>;
  account?: Maybe<AccountInfo>;
};

export type SubscriptionEntities = {
  __typename?: 'SubscriptionEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  subscriptionId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  renewDate?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  latest_invoice?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  inUse?: Maybe<Scalars['Float']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  account?: Maybe<AccountEntities>;
};

export type SubscriptionInvoiceResponse = {
  __typename?: 'SubscriptionInvoiceResponse';
  errors?: Maybe<Array<FieldError>>;
  invoice?: Maybe<Scalars['String']>;
};

export type SubscriptionPostInput = {
  customerId?: Maybe<Scalars['String']>;
  renewDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  latest_invoice?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
};

export type SubscriptionUpdateInput = {
  type: Scalars['String'];
  quantity?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
};

export type SubscriptionUpdateResponse = {
  __typename?: 'SubscriptionUpdateResponse';
  errors?: Maybe<Array<FieldError>>;
  subscription?: Maybe<SubscriptionEntities>;
  billing?: Maybe<StripeCardType>;
  account?: Maybe<AccountInfo>;
};

export type TeamEntities = {
  __typename?: 'TeamEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  hub_id?: Maybe<Scalars['String']>;
  account: AccountEntities;
  users: Array<UsersEntities>;
};

export type TeamEntitiesConnection = {
  __typename?: 'TeamEntitiesConnection';
  edges?: Maybe<Array<TeamEntitiesEdge>>;
  pageInfo?: Maybe<TeamEntitiesPageInfo>;
};

export type TeamEntitiesEdge = {
  __typename?: 'TeamEntitiesEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<TeamEntities>;
};

export type TeamEntitiesPageInfo = {
  __typename?: 'TeamEntitiesPageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
};

export type TeamInput = {
  name: Scalars['String'];
  id: Scalars['String'];
};

export type TeamListResponseTypes = {
  __typename?: 'TeamListResponseTypes';
  page?: Maybe<TeamEntitiesConnection>;
  pageData?: Maybe<PageData>;
  errors?: Maybe<Array<FieldError>>;
};

export type TeamUserInput = {
  id: Scalars['String'];
};

export type TemplateEntities = {
  __typename?: 'TemplateEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  signatures?: Maybe<Array<SignatureEntities>>;
  drafts?: Maybe<Array<SignatureDraftEntities>>;
};

export type TemplateEntitiesConnection = {
  __typename?: 'TemplateEntitiesConnection';
  edges?: Maybe<Array<TemplateEntitiesEdge>>;
  pageInfo?: Maybe<TemplateEntitiesPageInfo>;
};

export type TemplateEntitiesEdge = {
  __typename?: 'TemplateEntitiesEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<TemplateEntities>;
};

export type TemplateEntitiesPageInfo = {
  __typename?: 'TemplateEntitiesPageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
};

export type TemplateInput = {
  name: Scalars['String'];
  html: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type TemplateListResponseTypes = {
  __typename?: 'TemplateListResponseTypes';
  page?: Maybe<TemplateEntitiesConnection>;
  pageData?: Maybe<PageData>;
  errors?: Maybe<Array<FieldError>>;
};

export type TemplateResponse = {
  __typename?: 'TemplateResponse';
  errors?: Maybe<Array<FieldError>>;
  template?: Maybe<TemplateEntities>;
};

export type TokenEntities = {
  __typename?: 'TokenEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  token: Scalars['String'];
  expiry: Scalars['Float'];
  ownerType: Scalars['String'];
  tokenType: Scalars['String'];
  account?: Maybe<AccountEntities>;
  linkedPlatform?: Maybe<LinkedPlatformEntities>;
};


export type UserDetachInput = {
  users?: Maybe<Array<UserIdInput>>;
};

export type UserDetachResponse = {
  __typename?: 'UserDetachResponse';
  errors?: Maybe<Array<FieldError>>;
  users?: Maybe<Array<UsersEntities>>;
  teams?: Maybe<Array<TeamEntities>>;
  subscription?: Maybe<SubscriptionEntities>;
};

export type UserGoogleLoginInput = {
  credential: Scalars['String'];
};

export type UserIdInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserLoginInput = {
  email: Scalars['String'];
};

export type UserLoginResponse = {
  __typename?: 'UserLoginResponse';
  errors?: Maybe<Array<FieldError>>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<UsersEntities>;
  account?: Maybe<AccountInfo>;
  googleAuth?: Maybe<Scalars['String']>;
};

export type UserSearchInput = {
  orderBy: Scalars['String'];
  sort: Scalars['String'];
  keyword: Scalars['String'];
  teams: Array<TeamUserInput>;
};

export type UserSearchResponseTypes = {
  __typename?: 'UserSearchResponseTypes';
  page?: Maybe<UsersEntitiesConnection>;
  pageData?: Maybe<PageData>;
  errors?: Maybe<Array<FieldError>>;
};

export type UserSendInput = {
  userId: Scalars['String'];
};

export type UsersEntities = {
  __typename?: 'UsersEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  hub_id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  passcode?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  custom?: Maybe<Scalars['String']>;
  meetingLink?: Maybe<Scalars['String']>;
  meetingLinkLabel?: Maybe<Scalars['String']>;
  assigned?: Maybe<Scalars['Boolean']>;
  profileLink?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<TeamEntities>>;
  account?: Maybe<AccountEntities>;
  signature?: Maybe<SignatureEntities>;
  socials?: Maybe<Array<SocialsEntities>>;
  isLinked?: Maybe<Scalars['Boolean']>;
  linkedPlatform?: Maybe<Array<LinkedPlatformEntities>>;
  signatureHistory?: Maybe<Array<UserSignatureHistoryEntities>>;
  latestSignature?: Maybe<Scalars['String']>;
};

export type UsersEntitiesConnection = {
  __typename?: 'UsersEntitiesConnection';
  edges?: Maybe<Array<UsersEntitiesEdge>>;
  pageInfo?: Maybe<UsersEntitiesPageInfo>;
};

export type UsersEntitiesEdge = {
  __typename?: 'UsersEntitiesEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UsersEntities>;
};

export type UsersEntitiesPageInfo = {
  __typename?: 'UsersEntitiesPageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
};

export type UserSignatureHistoryEntities = {
  __typename?: 'UserSignatureHistoryEntities';
  id?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  signature_name: Scalars['String'];
  signature_html: Scalars['String'];
  user: UsersEntities;
};

export type UserUpdateInput = {
  accountId: Scalars['String'];
  passcode: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  phone: Scalars['String'];
  department: Scalars['String'];
  custom: Scalars['String'];
  mobile: Scalars['String'];
  meetingLink?: Maybe<Scalars['String']>;
  meetingLinkLabel?: Maybe<Scalars['String']>;
  profileLink?: Maybe<Scalars['String']>;
  socials?: Maybe<Array<SocialInput>>;
};

export type UserVerifyInput = {
  accountId: Scalars['String'];
  passcode: Scalars['String'];
  email: Scalars['String'];
};

export type AccountProfileUpdateMutationVariables = Exact<{
  options: AccountProfileUpdateInput;
}>;


export type AccountProfileUpdateMutation = (
  { __typename?: 'Mutation' }
  & { AccountProfileUpdate: (
    { __typename?: 'AccountProfileResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account: (
      { __typename?: 'AccountEntities' }
      & Pick<AccountEntities, 'displayName' | 'firstName' | 'type' | 'lastName' | 'status' | 'hubDomain' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    ), profile?: Maybe<(
      { __typename?: 'AccountProfileEntities' }
      & Pick<AccountProfileEntities, 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
      & { socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details'>
      )>> }
    )> }
  ) }
);

export type AccountChangePasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type AccountChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { AccountChangePassword: (
    { __typename?: 'AccountResponse' }
    & Pick<AccountResponse, 'accessToken'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'email'>
    )> }
  ) }
);

export type AccountForgotPasswordMutationVariables = Exact<{
  userName: Scalars['String'];
}>;


export type AccountForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { AccountForgotPassword: (
    { __typename?: 'AccountResponse' }
    & Pick<AccountResponse, 'accessToken'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'userName'>
    )> }
  ) }
);

export type AccountLoginMutationVariables = Exact<{
  userName: Scalars['String'];
  password: Scalars['String'];
}>;


export type AccountLoginMutation = (
  { __typename?: 'Mutation' }
  & { AccountLogin: (
    { __typename?: 'AccountResponse' }
    & Pick<AccountResponse, 'accessToken'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, cursors?: Maybe<(
      { __typename?: 'CursorsType' }
      & { users?: Maybe<(
        { __typename?: 'CursorsObjectType' }
        & Pick<CursorsObjectType, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
      )> }
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, signatures?: Maybe<Array<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'customCode' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'name' | 'email' | 'id' | 'image'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'name' | 'id'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'customCode' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'show' | 'size'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'coupon' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'id' | 'phone' | 'image' | 'url' | 'fax' | 'footnote' | 'name'>
      & { socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>>, address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'isDefault' | 'city' | 'state' | 'street' | 'country' | 'post_code' | 'id'>
      )>> }
    )>, teams?: Maybe<Array<(
      { __typename?: 'TeamEntities' }
      & Pick<TeamEntities, 'id' | 'name'>
    )>>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_color' | 'icon_solid' | 'icon_square' | 'name'>
    )>>, template?: Maybe<Array<(
      { __typename?: 'TemplateEntities' }
      & Pick<TemplateEntities, 'id' | 'name' | 'html' | 'previewImage' | 'isDefault'>
    )>>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>>, signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )> }
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'type' | 'status' | 'hubDomain' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type AccountGoogleLoginMutationVariables = Exact<{
  credential: Scalars['String'];
}>;


export type AccountGoogleLoginMutation = (
  { __typename?: 'Mutation' }
  & { AccountGoogleLogin: (
    { __typename?: 'AccountResponse' }
    & Pick<AccountResponse, 'accessToken'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, cursors?: Maybe<(
      { __typename?: 'CursorsType' }
      & { users?: Maybe<(
        { __typename?: 'CursorsObjectType' }
        & Pick<CursorsObjectType, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
      )> }
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, signatures?: Maybe<Array<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'customCode' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'name' | 'email' | 'id' | 'image'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'name' | 'id'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'customCode' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'show' | 'size'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'coupon' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'id' | 'phone' | 'image' | 'url' | 'fax' | 'footnote' | 'name'>
      & { socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>>, address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'isDefault' | 'city' | 'state' | 'street' | 'country' | 'post_code' | 'id'>
      )>> }
    )>, teams?: Maybe<Array<(
      { __typename?: 'TeamEntities' }
      & Pick<TeamEntities, 'id' | 'name'>
    )>>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_color' | 'icon_solid' | 'icon_square' | 'name'>
    )>>, template?: Maybe<Array<(
      { __typename?: 'TemplateEntities' }
      & Pick<TemplateEntities, 'id' | 'name' | 'html' | 'previewImage' | 'isDefault'>
    )>>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>>, signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )> }
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'type' | 'status' | 'hubDomain' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type AccountFacebookLoginMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AccountFacebookLoginMutation = (
  { __typename?: 'Mutation' }
  & { AccountFacebookLogin: (
    { __typename?: 'AccountResponse' }
    & Pick<AccountResponse, 'accessToken'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, cursors?: Maybe<(
      { __typename?: 'CursorsType' }
      & { users?: Maybe<(
        { __typename?: 'CursorsObjectType' }
        & Pick<CursorsObjectType, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
      )> }
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, signatures?: Maybe<Array<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'name' | 'email' | 'id' | 'image'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'name' | 'id'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'customCode' | 'embedCode' | 'embedCodeTwo' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'show' | 'size'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'coupon' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'id' | 'phone' | 'image' | 'url' | 'fax' | 'footnote' | 'name'>
      & { socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>>, address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'isDefault' | 'city' | 'state' | 'street' | 'country' | 'post_code' | 'id'>
      )>> }
    )>, teams?: Maybe<Array<(
      { __typename?: 'TeamEntities' }
      & Pick<TeamEntities, 'id' | 'name'>
    )>>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_color' | 'icon_solid' | 'icon_square' | 'name'>
    )>>, template?: Maybe<Array<(
      { __typename?: 'TemplateEntities' }
      & Pick<TemplateEntities, 'id' | 'name' | 'html' | 'previewImage' | 'isDefault'>
    )>>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>>, signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )> }
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'type' | 'status' | 'hubDomain' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type AccountLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type AccountLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountLogout'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'AccountResponse' }
    & Pick<AccountResponse, 'accessToken'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, cursors?: Maybe<(
      { __typename?: 'CursorsType' }
      & { users?: Maybe<(
        { __typename?: 'CursorsObjectType' }
        & Pick<CursorsObjectType, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
      )> }
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, signatures?: Maybe<Array<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'meetingLinkImg' | 'customCode' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'name' | 'email' | 'id' | 'image'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'name' | 'id'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'customCode' | 'embedCode' | 'embedCodeTwo' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'show' | 'size'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ), separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )> }
      )> }
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'coupon' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'id' | 'phone' | 'secondaryPhone' | 'image' | 'url' | 'fax' | 'footnote' | 'name'>
      & { socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>>, address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'state' | 'city' | 'street' | 'country' | 'isDefault' | 'post_code' | 'id'>
      )>> }
    )>, teams?: Maybe<Array<(
      { __typename?: 'TeamEntities' }
      & Pick<TeamEntities, 'id' | 'name'>
    )>>, template?: Maybe<Array<(
      { __typename?: 'TemplateEntities' }
      & Pick<TemplateEntities, 'id' | 'name' | 'html' | 'previewImage' | 'isDefault'>
    )>>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_solid' | 'icon_square' | 'icon_color' | 'name'>
    )>>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'label' | 'details'>
      )>>, signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )> }
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'type' | 'hubDomain' | 'lastName' | 'status' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  )> }
);

export type UpdateCompanyImageMutationVariables = Exact<{
  options: CompanyUpdateInput;
  image: Scalars['Upload'];
}>;


export type UpdateCompanyImageMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyImage: (
    { __typename?: 'CompanyUpdateResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'name' | 'id' | 'url' | 'phone' | 'secondaryPhone' | 'image' | 'fax' | 'footnote'>
      & { address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'state' | 'street' | 'city' | 'country' | 'post_code' | 'id' | 'isDefault'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )> }
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  options: CompanyUpdateInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'CompanyUpdateResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'name' | 'id' | 'url' | 'phone' | 'secondaryPhone' | 'image' | 'fax' | 'footnote'>
      & { address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'state' | 'street' | 'country' | 'city' | 'post_code' | 'id' | 'isDefault'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )> }
  ) }
);

export type GoogleGenerateAuthLinkMutationVariables = Exact<{
  options: GoogleGenerateAuthLinkInput;
}>;


export type GoogleGenerateAuthLinkMutation = (
  { __typename?: 'Mutation' }
  & { GoogleGenerateAuthLink: (
    { __typename?: 'GoogleGenerateAuthLinkResponse' }
    & Pick<GoogleGenerateAuthLinkResponse, 'url'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  ) }
);

export type GoogleVerifyMutationVariables = Exact<{
  code: Scalars['String'];
  state: Scalars['String'];
}>;


export type GoogleVerifyMutation = (
  { __typename?: 'Mutation' }
  & { GoogleVerify: (
    { __typename?: 'GoogleVerifyResponse' }
    & Pick<GoogleVerifyResponse, 'redirectUri'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, user?: Maybe<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'email' | 'isLinked' | 'id'>
    )> }
  ) }
);

export type HubspotAuthUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type HubspotAuthUrlQuery = (
  { __typename?: 'Query' }
  & { hubspotAuthUrl: (
    { __typename?: 'HubspotAuthTypes' }
    & Pick<HubspotAuthTypes, 'link'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  ) }
);

export type HubspotUserListQueryVariables = Exact<{
  limit: Scalars['Float'];
  cursor: Scalars['String'];
}>;


export type HubspotUserListQuery = (
  { __typename?: 'Query' }
  & { hubspotUserList: (
    { __typename?: 'HubspotListTypes' }
    & Pick<HubspotListTypes, 'cursor'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, list?: Maybe<Array<(
      { __typename?: 'HubspotListItem' }
      & Pick<HubspotListItem, 'lastName' | 'firstName' | 'owner' | 'has_owner' | 'userId' | 'id' | 'linked_google' | 'email'>
      & { teams?: Maybe<Array<(
        { __typename?: 'HubspotTeams' }
        & Pick<HubspotTeams, 'id' | 'name'>
      )>> }
    )>> }
  ) }
);

export type OnBoardCompanyMutationVariables = Exact<{
  options: OnBoardCompanyInput;
  image: Scalars['Upload'];
}>;


export type OnBoardCompanyMutation = (
  { __typename?: 'Mutation' }
  & { onBoardCompany: (
    { __typename?: 'OnBoardCompanyResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'name' | 'id' | 'url' | 'phone' | 'secondaryPhone' | 'image' | 'fax' | 'footnote'>
      & { address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'state' | 'street' | 'country' | 'city' | 'post_code' | 'id' | 'isDefault'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details'>
      )>> }
    )>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'hubDomain' | 'type' | 'status' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type OnBoardFormMutationVariables = Exact<{
  options: OnBoardFormInput;
}>;


export type OnBoardFormMutation = (
  { __typename?: 'Mutation' }
  & { onBoardForm: (
    { __typename?: 'OnBoardFormResponseTypes' }
    & Pick<OnBoardFormResponseTypes, 'accessToken'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_solid' | 'icon_square' | 'name'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'hubDomain' | 'type' | 'status' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type OnBoardHubspotMutationVariables = Exact<{
  hubspot_code: Scalars['String'];
}>;


export type OnBoardHubspotMutation = (
  { __typename?: 'Mutation' }
  & { onBoardHubspot: (
    { __typename?: 'OnBoardHubspotResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_solid' | 'icon_square' | 'name'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'hubDomain' | 'type' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type OnBoardImportMutationVariables = Exact<{
  options: OnBoardImportInput;
}>;


export type OnBoardImportMutation = (
  { __typename?: 'Mutation' }
  & { onBoardImport: (
    { __typename?: 'OnBoardImportResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, template?: Maybe<Array<(
      { __typename?: 'TemplateEntities' }
      & Pick<TemplateEntities, 'id' | 'name' | 'html' | 'isDefault'>
    )>>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id'>
      & { teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details'>
      )>>, signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )> }
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, teams?: Maybe<Array<(
      { __typename?: 'TeamEntities' }
      & Pick<TeamEntities, 'id' | 'name'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'hubDomain' | 'lastName' | 'status' | 'type' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type OnBoardProfileMutationVariables = Exact<{
  options: OnBoardProfileInput;
  image: Scalars['Upload'];
}>;


export type OnBoardProfileMutation = (
  { __typename?: 'Mutation' }
  & { onBoardProfile: (
    { __typename?: 'OnBoardProfileResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'hubDomain' | 'email' | 'type' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type OnBoardSubscriptionMutationVariables = Exact<{
  options: OnBoardSubscriptionInput;
}>;


export type OnBoardSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { onBoardSubscription: (
    { __typename?: 'OnBoardSubscriptionResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'coupon' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'type' | 'email' | 'userName' | 'hubDomain' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type SignaturePushToGmailMutationVariables = Exact<{
  options: SignaturePushInput;
}>;


export type SignaturePushToGmailMutation = (
  { __typename?: 'Mutation' }
  & { SignaturePushToGmail: (
    { __typename?: 'SignatureResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  ) }
);

export type SignatureAddMutationVariables = Exact<{
  options: SignatureInput;
}>;


export type SignatureAddMutation = (
  { __typename?: 'Mutation' }
  & { signatureAdd: (
    { __typename?: 'SignatureResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, signature?: Maybe<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'type' | 'hub_id' | 'image' | 'isLinked' | 'assigned' | 'email' | 'id' | 'custom' | 'meetingLink' | 'meetingLinkLabel'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'id' | 'name'>
        )>>, socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'customCode' | 'embedCode' | 'embedCodeTwo' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'size' | 'show'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )> }
  ) }
);

export type SignatureAssignMutationVariables = Exact<{
  options: SignatureAssignInput;
}>;


export type SignatureAssignMutation = (
  { __typename?: 'Mutation' }
  & { signatureAssign: (
    { __typename?: 'SignatureResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, signature?: Maybe<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'custom' | 'meetingLink' | 'meetingLinkLabel'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'id' | 'name'>
        )>>, socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'customCode' | 'embedCode' | 'embedCodeTwo' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'size' | 'show'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )> }
  ) }
);

export type SignatureCopyHtmlQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type SignatureCopyHtmlQuery = (
  { __typename?: 'Query' }
  & { signatureCopyHtml: (
    { __typename?: 'SignatureCopyResponseTypes' }
    & Pick<SignatureCopyResponseTypes, 'googleLink'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'email' | 'userName' | 'hubDomain' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_color' | 'icon_solid' | 'icon_square' | 'name'>
    )>>, company?: Maybe<(
      { __typename?: 'CompanyEntities' }
      & Pick<CompanyEntities, 'id' | 'phone' | 'secondaryPhone' | 'image' | 'url' | 'name'>
      & { socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details'>
      )>>, address?: Maybe<Array<(
        { __typename?: 'AddressEntities' }
        & Pick<AddressEntities, 'state' | 'street' | 'city' | 'country' | 'post_code' | 'id'>
      )>> }
    )>, signature?: Maybe<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo'>
      & { properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )>, template?: Maybe<(
      { __typename?: 'TemplateEntities' }
      & Pick<TemplateEntities, 'id' | 'name' | 'html' | 'previewImage' | 'isDefault'>
    )>, user?: Maybe<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel'>
      & { teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details'>
      )>>, signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type SignatureDetachUsersMutationVariables = Exact<{
  options: SignatureDetachInput;
}>;


export type SignatureDetachUsersMutation = (
  { __typename?: 'Mutation' }
  & { signatureDetachUsers: (
    { __typename?: 'SignatureResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, signature?: Maybe<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'custom' | 'meetingLink' | 'meetingLinkLabel'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'id' | 'name'>
        )>>, socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'customCode' | 'embedCode' | 'embedCodeTwo' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'size' | 'show'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'size' | 'show'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )> }
  ) }
);

export type SignatureListQueryVariables = Exact<{
  arg: ConnectionArgs;
}>;


export type SignatureListQuery = (
  { __typename?: 'Query' }
  & { signatureList?: Maybe<(
    { __typename?: 'SignatureListResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, page?: Maybe<(
      { __typename?: 'SignatureEntitiesConnection' }
      & { edges?: Maybe<Array<(
        { __typename?: 'SignatureEntitiesEdge' }
        & { node?: Maybe<(
          { __typename?: 'SignatureEntities' }
          & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
          & { users?: Maybe<Array<(
            { __typename?: 'UsersEntities' }
            & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'custom' | 'meetingLink' | 'meetingLinkLabel'>
            & { teams?: Maybe<Array<(
              { __typename?: 'TeamEntities' }
              & Pick<TeamEntities, 'id' | 'name'>
            )>>, socials?: Maybe<Array<(
              { __typename?: 'SocialsEntities' }
              & Pick<SocialsEntities, 'link' | 'details'>
            )>> }
          )>>, template?: Maybe<(
            { __typename?: 'TemplateEntities' }
            & Pick<TemplateEntities, 'id' | 'name'>
          )>, draft?: Maybe<(
            { __typename?: 'SignatureDraftEntities' }
            & Pick<SignatureDraftEntities, 'name' | 'meetingLinkImg' | 'customCode' | 'embedCode' | 'embedCodeTwo' | 'id'>
            & { properties?: Maybe<(
              { __typename?: 'PropertiesEntities' }
              & { separator?: Maybe<(
                { __typename?: 'separatorProperty' }
                & Pick<SeparatorProperty, 'type' | 'color'>
              )>, heading: (
                { __typename?: 'fontProperty' }
                & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
              ), link: (
                { __typename?: 'fontProperty' }
                & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
              ), body: (
                { __typename?: 'fontProperty' }
                & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
              ), footnote?: Maybe<(
                { __typename?: 'fontProperty' }
                & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
              )>, meetingLink?: Maybe<(
                { __typename?: 'MeetingLinkProperty' }
                & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
              )>, icons: (
                { __typename?: 'iconProperty' }
                & Pick<IconProperty, 'show' | 'size'>
                & { social?: Maybe<(
                  { __typename?: 'iconStyle' }
                  & Pick<IconStyle, 'backgroundColor'>
                )>, contact?: Maybe<(
                  { __typename?: 'iconStyle' }
                  & Pick<IconStyle, 'backgroundColor'>
                )> }
              ), avatar: (
                { __typename?: 'AvatarProperty' }
                & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
              ), socials: (
                { __typename?: 'SocialsProperty' }
                & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
              ), logo: (
                { __typename?: 'LogoProperty' }
                & Pick<LogoProperty, 'size'>
              ), dividers: (
                { __typename?: 'dividerProperty' }
                & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
              ) }
            )>, template: (
              { __typename?: 'TemplateEntities' }
              & Pick<TemplateEntities, 'id' | 'name'>
            ) }
          )>, properties?: Maybe<(
            { __typename?: 'PropertiesEntities' }
            & { separator?: Maybe<(
              { __typename?: 'separatorProperty' }
              & Pick<SeparatorProperty, 'type' | 'color'>
            )>, heading: (
              { __typename?: 'fontProperty' }
              & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
            ), link: (
              { __typename?: 'fontProperty' }
              & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
            ), body: (
              { __typename?: 'fontProperty' }
              & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
            ), footnote?: Maybe<(
              { __typename?: 'fontProperty' }
              & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
            )>, meetingLink?: Maybe<(
              { __typename?: 'MeetingLinkProperty' }
              & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
            )>, icons: (
              { __typename?: 'iconProperty' }
              & Pick<IconProperty, 'show' | 'size'>
              & { social?: Maybe<(
                { __typename?: 'iconStyle' }
                & Pick<IconStyle, 'backgroundColor'>
              )>, contact?: Maybe<(
                { __typename?: 'iconStyle' }
                & Pick<IconStyle, 'backgroundColor'>
              )> }
            ), avatar: (
              { __typename?: 'AvatarProperty' }
              & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
            ), socials: (
              { __typename?: 'SocialsProperty' }
              & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
            ), logo: (
              { __typename?: 'LogoProperty' }
              & Pick<LogoProperty, 'size'>
            ), dividers: (
              { __typename?: 'dividerProperty' }
              & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
            ) }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type SignaturePublishMutationVariables = Exact<{
  options: SignaturePublishInput;
}>;


export type SignaturePublishMutation = (
  { __typename?: 'Mutation' }
  & { signaturePublish: (
    { __typename?: 'SignatureResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, signature?: Maybe<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'custom' | 'meetingLink' | 'meetingLinkLabel'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'id' | 'name'>
        )>>, socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'size' | 'show'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )> }
  ) }
);

export type SignatureRemoveMutationVariables = Exact<{
  options: SignatureDeleteInput;
}>;


export type SignatureRemoveMutation = (
  { __typename?: 'Mutation' }
  & { signatureRemove: (
    { __typename?: 'SignatureDeleteResponse' }
    & Pick<SignatureDeleteResponse, 'signature'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  ) }
);

export type SignatureRevertMutationVariables = Exact<{
  options: SignatureRevertInput;
}>;


export type SignatureRevertMutation = (
  { __typename?: 'Mutation' }
  & { signatureRevert: (
    { __typename?: 'SignatureResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, signature?: Maybe<(
      { __typename?: 'SignatureEntities' }
      & Pick<SignatureEntities, 'id' | 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'hasDraft'>
      & { users?: Maybe<Array<(
        { __typename?: 'UsersEntities' }
        & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'custom' | 'meetingLink' | 'meetingLinkLabel'>
        & { teams?: Maybe<Array<(
          { __typename?: 'TeamEntities' }
          & Pick<TeamEntities, 'id' | 'name'>
        )>>, socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )>>, template?: Maybe<(
        { __typename?: 'TemplateEntities' }
        & Pick<TemplateEntities, 'id' | 'name'>
      )>, draft?: Maybe<(
        { __typename?: 'SignatureDraftEntities' }
        & Pick<SignatureDraftEntities, 'name' | 'customCode' | 'meetingLinkImg' | 'embedCode' | 'embedCodeTwo' | 'id'>
        & { properties?: Maybe<(
          { __typename?: 'PropertiesEntities' }
          & { separator?: Maybe<(
            { __typename?: 'separatorProperty' }
            & Pick<SeparatorProperty, 'type' | 'color'>
          )>, heading: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), link: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), body: (
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          ), footnote?: Maybe<(
            { __typename?: 'fontProperty' }
            & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
          )>, meetingLink?: Maybe<(
            { __typename?: 'MeetingLinkProperty' }
            & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
          )>, icons: (
            { __typename?: 'iconProperty' }
            & Pick<IconProperty, 'size' | 'show'>
            & { social?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )>, contact?: Maybe<(
              { __typename?: 'iconStyle' }
              & Pick<IconStyle, 'backgroundColor'>
            )> }
          ), avatar: (
            { __typename?: 'AvatarProperty' }
            & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
          ), socials: (
            { __typename?: 'SocialsProperty' }
            & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
          ), logo: (
            { __typename?: 'LogoProperty' }
            & Pick<LogoProperty, 'size'>
          ), dividers: (
            { __typename?: 'dividerProperty' }
            & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
          ) }
        )>, template: (
          { __typename?: 'TemplateEntities' }
          & Pick<TemplateEntities, 'id' | 'name'>
        ) }
      )>, properties?: Maybe<(
        { __typename?: 'PropertiesEntities' }
        & { separator?: Maybe<(
          { __typename?: 'separatorProperty' }
          & Pick<SeparatorProperty, 'type' | 'color'>
        )>, heading: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), link: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), body: (
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        ), footnote?: Maybe<(
          { __typename?: 'fontProperty' }
          & Pick<FontProperty, 'fontStyle' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'color' | 'lineHeight'>
        )>, meetingLink?: Maybe<(
          { __typename?: 'MeetingLinkProperty' }
          & Pick<MeetingLinkProperty, 'fontFamily' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'color' | 'backgroundColor' | 'hPadding' | 'vPadding' | 'borderWidth' | 'borderStyle' | 'borderColor'>
        )>, icons: (
          { __typename?: 'iconProperty' }
          & Pick<IconProperty, 'show' | 'size'>
          & { social?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )>, contact?: Maybe<(
            { __typename?: 'iconStyle' }
            & Pick<IconStyle, 'backgroundColor'>
          )> }
        ), avatar: (
          { __typename?: 'AvatarProperty' }
          & Pick<AvatarProperty, 'size' | 'shape' | 'borderRadius'>
        ), socials: (
          { __typename?: 'SocialsProperty' }
          & Pick<SocialsProperty, 'size' | 'show' | 'iconStyle'>
        ), logo: (
          { __typename?: 'LogoProperty' }
          & Pick<LogoProperty, 'size'>
        ), dividers: (
          { __typename?: 'dividerProperty' }
          & Pick<DividerProperty, 'thickness' | 'style' | 'color' | 'show'>
        ) }
      )> }
    )> }
  ) }
);

export type SocialItemsListQueryVariables = Exact<{ [key: string]: never; }>;


export type SocialItemsListQuery = (
  { __typename?: 'Query' }
  & { socialItemsList: (
    { __typename?: 'SocialItemListResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, socialItem?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'icon_round' | 'icon_solid' | 'icon_square' | 'icon_color' | 'name' | 'priority'>
    )>> }
  ) }
);

export type RetrieveInvoiceMutationVariables = Exact<{ [key: string]: never; }>;


export type RetrieveInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { retrieveInvoice: (
    { __typename?: 'SubscriptionInvoiceResponse' }
    & Pick<SubscriptionInvoiceResponse, 'invoice'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  ) }
);

export type SubscriptionCancelMutationVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionCancelMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionCancel: (
    { __typename?: 'SubscriptionCancelResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'type' | 'firstName' | 'lastName' | 'status' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type SubscriptionPostCreateMutationVariables = Exact<{
  options: SubscriptionPostInput;
}>;


export type SubscriptionPostCreateMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionPostCreate: (
    { __typename?: 'OnBoardSubscriptionResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'coupon' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'type' | 'status' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type SubscriptionUpdateMutationVariables = Exact<{
  options: SubscriptionUpdateInput;
}>;


export type SubscriptionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionUpdate: (
    { __typename?: 'SubscriptionUpdateResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'coupon' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'email' | 'type' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type SubscriptionUpgradeMutationVariables = Exact<{
  options: OnBoardSubscriptionInput;
}>;


export type SubscriptionUpgradeMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionUpgrade: (
    { __typename?: 'OnBoardSubscriptionResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'coupon' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, billing?: Maybe<(
      { __typename?: 'StripeCardType' }
      & Pick<StripeCardType, 'id'>
      & { billing_details?: Maybe<(
        { __typename?: 'StripeBillingDetails' }
        & Pick<StripeBillingDetails, 'name' | 'email'>
        & { address?: Maybe<(
          { __typename?: 'StripeAddress' }
          & Pick<StripeAddress, 'line2' | 'city' | 'country' | 'postal_code' | 'state'>
        )> }
      )>, card?: Maybe<(
        { __typename?: 'StripeCard' }
        & Pick<StripeCard, 'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'>
      )> }
    )>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'type' | 'email' | 'userName' | 'hubDomain' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type UserImportMutationVariables = Exact<{
  options: OnBoardImportInput;
  image?: Maybe<Scalars['Upload']>;
}>;


export type UserImportMutation = (
  { __typename?: 'Mutation' }
  & { userImport: (
    { __typename?: 'OnBoardImportResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, teams?: Maybe<Array<(
      { __typename?: 'TeamEntities' }
      & Pick<TeamEntities, 'id' | 'name'>
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'type' | 'image' | 'isLinked' | 'assigned' | 'email' | 'id' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )>, teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )>> }
  ) }
);

export type UserLoginMutationVariables = Exact<{
  options: UserLoginInput;
}>;


export type UserLoginMutation = (
  { __typename?: 'Mutation' }
  & { UserLogin: (
    { __typename?: 'UserLoginResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  ) }
);

export type UserSendMutationVariables = Exact<{
  options: UserSendInput;
}>;


export type UserSendMutation = (
  { __typename?: 'Mutation' }
  & { UserSend: (
    { __typename?: 'UserResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, user?: Maybe<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'type' | 'image' | 'isLinked' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )>, teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )> }
  ) }
);

export type UserUpdateServiceMutationVariables = Exact<{
  options: UserUpdateInput;
}>;


export type UserUpdateServiceMutation = (
  { __typename?: 'Mutation' }
  & { UserUpdateService: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'googleAuth'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'email' | 'hubDomain' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )>, user?: Maybe<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'type' | 'image' | 'isLinked' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )>, teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )> }
  ) }
);

export type UserUpdateServiceImageMutationVariables = Exact<{
  options: UserUpdateInput;
  image: Scalars['Upload'];
}>;


export type UserUpdateServiceImageMutation = (
  { __typename?: 'Mutation' }
  & { UserUpdateServiceImage: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'googleAuth'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'lastName' | 'status' | 'email' | 'userName' | 'hubDomain' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )>, user?: Maybe<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'isLinked' | 'assigned' | 'type' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )>, teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )> }
  ) }
);

export type UserVerifyMutationVariables = Exact<{
  options: UserVerifyInput;
}>;


export type UserVerifyMutation = (
  { __typename?: 'Mutation' }
  & { UserVerify: (
    { __typename?: 'AccountPublicResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, socials?: Maybe<Array<(
      { __typename?: 'SocialDetailsEntities' }
      & Pick<SocialDetailsEntities, 'id' | 'priority' | 'icon_round' | 'icon_solid' | 'icon_square' | 'name'>
    )>>, user?: Maybe<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'passcode' | 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'type' | 'image' | 'isLinked' | 'assigned' | 'email' | 'id' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )>, teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )>, account?: Maybe<(
      { __typename?: 'AccountInfo' }
      & Pick<AccountInfo, 'displayName' | 'firstName' | 'hubDomain' | 'lastName' | 'status' | 'email' | 'userName' | 'id' | 'terms_accepted' | 'hubspot_portal_id'>
      & { profile?: Maybe<(
        { __typename?: 'AccountProfileEntities' }
        & Pick<AccountProfileEntities, 'image' | 'mobile' | 'role' | 'phone' | 'department' | 'custom'>
        & { socials?: Maybe<Array<(
          { __typename?: 'SocialsEntities' }
          & Pick<SocialsEntities, 'link' | 'details'>
        )>> }
      )> }
    )> }
  ) }
);

export type UserDetachSignatureMutationVariables = Exact<{
  options: UserDetachInput;
}>;


export type UserDetachSignatureMutation = (
  { __typename?: 'Mutation' }
  & { userDetachSignature: (
    { __typename?: 'UserDetachResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'isLinked' | 'type' | 'assigned' | 'email' | 'id' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )>, teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )>> }
  ) }
);

export type UserRemoveMutationVariables = Exact<{
  options: UserDetachInput;
}>;


export type UserRemoveMutation = (
  { __typename?: 'Mutation' }
  & { userRemove: (
    { __typename?: 'UserDetachResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, teams?: Maybe<Array<(
      { __typename?: 'TeamEntities' }
      & Pick<TeamEntities, 'id' | 'name'>
    )>>, subscription?: Maybe<(
      { __typename?: 'SubscriptionEntities' }
      & Pick<SubscriptionEntities, 'paymentMethodId' | 'latest_invoice' | 'status' | 'invoiceId' | 'price' | 'productId' | 'invoiceStatus' | 'quantity' | 'subscriptionPlan' | 'subscriptionId' | 'customerId' | 'renewDate' | 'inUse'>
    )>, users?: Maybe<Array<(
      { __typename?: 'UsersEntities' }
      & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'type' | 'isLinked' | 'assigned' | 'email' | 'id' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
      & { signature?: Maybe<(
        { __typename?: 'SignatureEntities' }
        & Pick<SignatureEntities, 'id' | 'name'>
      )>, teams?: Maybe<Array<(
        { __typename?: 'TeamEntities' }
        & Pick<TeamEntities, 'id' | 'name'>
      )>>, socials?: Maybe<Array<(
        { __typename?: 'SocialsEntities' }
        & Pick<SocialsEntities, 'link' | 'details' | 'label'>
      )>> }
    )>> }
  ) }
);

export type UserSearchListMutationVariables = Exact<{
  arg: ConnectionArgs;
  option: UserSearchInput;
}>;


export type UserSearchListMutation = (
  { __typename?: 'Mutation' }
  & { userSearchList: (
    { __typename?: 'UserSearchResponseTypes' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, page?: Maybe<(
      { __typename?: 'UsersEntitiesConnection' }
      & { pageInfo?: Maybe<(
        { __typename?: 'UsersEntitiesPageInfo' }
        & Pick<UsersEntitiesPageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
      )>, edges?: Maybe<Array<(
        { __typename?: 'UsersEntitiesEdge' }
        & { node?: Maybe<(
          { __typename?: 'UsersEntities' }
          & Pick<UsersEntities, 'name' | 'mobile' | 'role' | 'phone' | 'hub_id' | 'image' | 'isLinked' | 'type' | 'assigned' | 'email' | 'id' | 'department' | 'custom' | 'meetingLink' | 'meetingLinkLabel' | 'profileLink'>
          & { signature?: Maybe<(
            { __typename?: 'SignatureEntities' }
            & Pick<SignatureEntities, 'id' | 'name'>
          )>, teams?: Maybe<Array<(
            { __typename?: 'TeamEntities' }
            & Pick<TeamEntities, 'id' | 'name'>
          )>>, socials?: Maybe<Array<(
            { __typename?: 'SocialsEntities' }
            & Pick<SocialsEntities, 'link' | 'details' | 'label'>
          )>> }
        )> }
      )>> }
    )> }
  ) }
);


export const AccountProfileUpdateDocument = gql`
    mutation AccountProfileUpdate($options: AccountProfileUpdateInput!) {
  AccountProfileUpdate(options: $options) {
    errors {
      field
      message
    }
    account {
      displayName
      firstName
      type
      lastName
      status
      hubDomain
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    profile {
      mobile
      role
      phone
      department
      custom
      socials {
        link
        details
      }
    }
  }
}
    `;
export type AccountProfileUpdateMutationFn = Apollo.MutationFunction<AccountProfileUpdateMutation, AccountProfileUpdateMutationVariables>;

/**
 * __useAccountProfileUpdateMutation__
 *
 * To run a mutation, you first call `useAccountProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountProfileUpdateMutation, { data, loading, error }] = useAccountProfileUpdateMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAccountProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AccountProfileUpdateMutation, AccountProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountProfileUpdateMutation, AccountProfileUpdateMutationVariables>(AccountProfileUpdateDocument, options);
      }
export type AccountProfileUpdateMutationHookResult = ReturnType<typeof useAccountProfileUpdateMutation>;
export type AccountProfileUpdateMutationResult = Apollo.MutationResult<AccountProfileUpdateMutation>;
export type AccountProfileUpdateMutationOptions = Apollo.BaseMutationOptions<AccountProfileUpdateMutation, AccountProfileUpdateMutationVariables>;
export const AccountChangePasswordDocument = gql`
    mutation AccountChangePassword($token: String!, $password: String!) {
  AccountChangePassword(options: {token: $token, password: $password}) {
    errors {
      field
      message
    }
    account {
      email
    }
    accessToken
  }
}
    `;
export type AccountChangePasswordMutationFn = Apollo.MutationFunction<AccountChangePasswordMutation, AccountChangePasswordMutationVariables>;

/**
 * __useAccountChangePasswordMutation__
 *
 * To run a mutation, you first call `useAccountChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountChangePasswordMutation, { data, loading, error }] = useAccountChangePasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAccountChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<AccountChangePasswordMutation, AccountChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountChangePasswordMutation, AccountChangePasswordMutationVariables>(AccountChangePasswordDocument, options);
      }
export type AccountChangePasswordMutationHookResult = ReturnType<typeof useAccountChangePasswordMutation>;
export type AccountChangePasswordMutationResult = Apollo.MutationResult<AccountChangePasswordMutation>;
export type AccountChangePasswordMutationOptions = Apollo.BaseMutationOptions<AccountChangePasswordMutation, AccountChangePasswordMutationVariables>;
export const AccountForgotPasswordDocument = gql`
    mutation AccountForgotPassword($userName: String!) {
  AccountForgotPassword(userName: $userName) {
    errors {
      field
      message
    }
    account {
      userName
    }
    accessToken
  }
}
    `;
export type AccountForgotPasswordMutationFn = Apollo.MutationFunction<AccountForgotPasswordMutation, AccountForgotPasswordMutationVariables>;

/**
 * __useAccountForgotPasswordMutation__
 *
 * To run a mutation, you first call `useAccountForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountForgotPasswordMutation, { data, loading, error }] = useAccountForgotPasswordMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useAccountForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<AccountForgotPasswordMutation, AccountForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountForgotPasswordMutation, AccountForgotPasswordMutationVariables>(AccountForgotPasswordDocument, options);
      }
export type AccountForgotPasswordMutationHookResult = ReturnType<typeof useAccountForgotPasswordMutation>;
export type AccountForgotPasswordMutationResult = Apollo.MutationResult<AccountForgotPasswordMutation>;
export type AccountForgotPasswordMutationOptions = Apollo.BaseMutationOptions<AccountForgotPasswordMutation, AccountForgotPasswordMutationVariables>;
export const AccountLoginDocument = gql`
    mutation AccountLogin($userName: String!, $password: String!) {
  AccountLogin(options: {userName: $userName, password: $password}) {
    errors {
      field
      message
    }
    cursors {
      users {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    signatures {
      users {
        name
        email
        id
        image
        teams {
          name
          id
        }
      }
      template {
        id
        name
      }
      id
      name
      meetingLinkImg
      embedCode
      embedCodeTwo
      customCode
      hasDraft
      draft {
        name
        meetingLinkImg
        embedCode
        embedCodeTwo
        customCode
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            show
            size
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        separator {
          type
          color
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      coupon
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    company {
      id
      phone
      image
      url
      fax
      footnote
      socials {
        link
        details
        label
      }
      name
      address {
        isDefault
        city
        state
        street
        country
        post_code
        id
      }
    }
    teams {
      id
      name
    }
    socials {
      id
      priority
      icon_round
      icon_color
      icon_solid
      icon_square
      name
    }
    template {
      id
      name
      html
      previewImage
      isDefault
    }
    users {
      passcode
      name
      mobile
      role
      phone
      hub_id
      image
      type
      isLinked
      assigned
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      profileLink
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
      signature {
        id
        name
      }
    }
    account {
      displayName
      firstName
      lastName
      type
      status
      hubDomain
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    accessToken
  }
}
    `;
export type AccountLoginMutationFn = Apollo.MutationFunction<AccountLoginMutation, AccountLoginMutationVariables>;

/**
 * __useAccountLoginMutation__
 *
 * To run a mutation, you first call `useAccountLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountLoginMutation, { data, loading, error }] = useAccountLoginMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAccountLoginMutation(baseOptions?: Apollo.MutationHookOptions<AccountLoginMutation, AccountLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountLoginMutation, AccountLoginMutationVariables>(AccountLoginDocument, options);
      }
export type AccountLoginMutationHookResult = ReturnType<typeof useAccountLoginMutation>;
export type AccountLoginMutationResult = Apollo.MutationResult<AccountLoginMutation>;
export type AccountLoginMutationOptions = Apollo.BaseMutationOptions<AccountLoginMutation, AccountLoginMutationVariables>;
export const AccountGoogleLoginDocument = gql`
    mutation AccountGoogleLogin($credential: String!) {
  AccountGoogleLogin(options: {credential: $credential}) {
    errors {
      field
      message
    }
    cursors {
      users {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    signatures {
      users {
        name
        email
        id
        image
        teams {
          name
          id
        }
      }
      template {
        id
        name
      }
      id
      name
      meetingLinkImg
      embedCode
      embedCodeTwo
      customCode
      hasDraft
      draft {
        name
        meetingLinkImg
        embedCode
        embedCodeTwo
        customCode
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            show
            size
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        separator {
          type
          color
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      coupon
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    company {
      id
      phone
      image
      url
      fax
      footnote
      socials {
        link
        details
        label
      }
      name
      address {
        isDefault
        city
        state
        street
        country
        post_code
        id
      }
    }
    teams {
      id
      name
    }
    socials {
      id
      priority
      icon_round
      icon_color
      icon_solid
      icon_square
      name
    }
    template {
      id
      name
      html
      previewImage
      isDefault
    }
    users {
      passcode
      name
      mobile
      role
      phone
      hub_id
      image
      type
      isLinked
      assigned
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      profileLink
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
      signature {
        id
        name
      }
    }
    account {
      displayName
      firstName
      lastName
      type
      status
      hubDomain
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    accessToken
  }
}
    `;
export type AccountGoogleLoginMutationFn = Apollo.MutationFunction<AccountGoogleLoginMutation, AccountGoogleLoginMutationVariables>;

/**
 * __useAccountGoogleLoginMutation__
 *
 * To run a mutation, you first call `useAccountGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountGoogleLoginMutation, { data, loading, error }] = useAccountGoogleLoginMutation({
 *   variables: {
 *      credential: // value for 'credential'
 *   },
 * });
 */
export function useAccountGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<AccountGoogleLoginMutation, AccountGoogleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountGoogleLoginMutation, AccountGoogleLoginMutationVariables>(AccountGoogleLoginDocument, options);
      }
export type AccountGoogleLoginMutationHookResult = ReturnType<typeof useAccountGoogleLoginMutation>;
export type AccountGoogleLoginMutationResult = Apollo.MutationResult<AccountGoogleLoginMutation>;
export type AccountGoogleLoginMutationOptions = Apollo.BaseMutationOptions<AccountGoogleLoginMutation, AccountGoogleLoginMutationVariables>;
export const AccountFacebookLoginDocument = gql`
    mutation AccountFacebookLogin($email: String!) {
  AccountFacebookLogin(options: {email: $email}) {
    errors {
      field
      message
    }
    cursors {
      users {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    signatures {
      users {
        name
        email
        id
        image
        teams {
          name
          id
        }
      }
      template {
        id
        name
      }
      id
      name
      customCode
      meetingLinkImg
      embedCode
      embedCodeTwo
      hasDraft
      draft {
        name
        meetingLinkImg
        customCode
        embedCode
        embedCodeTwo
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            show
            size
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        separator {
          type
          color
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      coupon
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    company {
      id
      phone
      image
      url
      fax
      footnote
      socials {
        link
        details
        label
      }
      name
      address {
        isDefault
        city
        state
        street
        country
        post_code
        id
      }
    }
    teams {
      id
      name
    }
    socials {
      id
      priority
      icon_round
      icon_color
      icon_solid
      icon_square
      name
    }
    template {
      id
      name
      html
      previewImage
      isDefault
    }
    users {
      passcode
      name
      mobile
      role
      phone
      hub_id
      image
      type
      isLinked
      assigned
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      profileLink
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
      signature {
        id
        name
      }
    }
    account {
      displayName
      firstName
      lastName
      type
      status
      hubDomain
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    accessToken
  }
}
    `;
export type AccountFacebookLoginMutationFn = Apollo.MutationFunction<AccountFacebookLoginMutation, AccountFacebookLoginMutationVariables>;

/**
 * __useAccountFacebookLoginMutation__
 *
 * To run a mutation, you first call `useAccountFacebookLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountFacebookLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountFacebookLoginMutation, { data, loading, error }] = useAccountFacebookLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountFacebookLoginMutation(baseOptions?: Apollo.MutationHookOptions<AccountFacebookLoginMutation, AccountFacebookLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountFacebookLoginMutation, AccountFacebookLoginMutationVariables>(AccountFacebookLoginDocument, options);
      }
export type AccountFacebookLoginMutationHookResult = ReturnType<typeof useAccountFacebookLoginMutation>;
export type AccountFacebookLoginMutationResult = Apollo.MutationResult<AccountFacebookLoginMutation>;
export type AccountFacebookLoginMutationOptions = Apollo.BaseMutationOptions<AccountFacebookLoginMutation, AccountFacebookLoginMutationVariables>;
export const AccountLogoutDocument = gql`
    mutation AccountLogout {
  AccountLogout
}
    `;
export type AccountLogoutMutationFn = Apollo.MutationFunction<AccountLogoutMutation, AccountLogoutMutationVariables>;

/**
 * __useAccountLogoutMutation__
 *
 * To run a mutation, you first call `useAccountLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountLogoutMutation, { data, loading, error }] = useAccountLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAccountLogoutMutation(baseOptions?: Apollo.MutationHookOptions<AccountLogoutMutation, AccountLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountLogoutMutation, AccountLogoutMutationVariables>(AccountLogoutDocument, options);
      }
export type AccountLogoutMutationHookResult = ReturnType<typeof useAccountLogoutMutation>;
export type AccountLogoutMutationResult = Apollo.MutationResult<AccountLogoutMutation>;
export type AccountLogoutMutationOptions = Apollo.BaseMutationOptions<AccountLogoutMutation, AccountLogoutMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    errors {
      field
      message
    }
    cursors {
      users {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    signatures {
      users {
        name
        email
        id
        image
        teams {
          name
          id
        }
      }
      template {
        id
        name
      }
      id
      name
      meetingLinkImg
      customCode
      embedCode
      embedCodeTwo
      hasDraft
      draft {
        name
        meetingLinkImg
        customCode
        embedCode
        embedCodeTwo
        id
        properties {
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            show
            size
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          separator {
            type
            color
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
        separator {
          type
          color
        }
      }
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      coupon
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    company {
      id
      phone
      secondaryPhone
      image
      url
      fax
      footnote
      socials {
        link
        details
        label
      }
      name
      address {
        state
        city
        street
        country
        isDefault
        post_code
        id
      }
    }
    teams {
      id
      name
    }
    template {
      id
      name
      html
      previewImage
      isDefault
    }
    socials {
      id
      priority
      icon_round
      icon_solid
      icon_square
      icon_color
      name
    }
    users {
      passcode
      name
      mobile
      role
      phone
      hub_id
      image
      type
      isLinked
      assigned
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      profileLink
      teams {
        id
        name
      }
      socials {
        link
        label
        details
      }
      signature {
        id
        name
      }
    }
    account {
      displayName
      firstName
      type
      hubDomain
      lastName
      status
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    accessToken
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateCompanyImageDocument = gql`
    mutation UpdateCompanyImage($options: CompanyUpdateInput!, $image: Upload!) {
  updateCompanyImage(options: $options, image: $image) {
    errors {
      field
      message
    }
    company {
      name
      id
      url
      phone
      secondaryPhone
      image
      fax
      footnote
      address {
        state
        street
        city
        country
        post_code
        id
        isDefault
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UpdateCompanyImageMutationFn = Apollo.MutationFunction<UpdateCompanyImageMutation, UpdateCompanyImageMutationVariables>;

/**
 * __useUpdateCompanyImageMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyImageMutation, { data, loading, error }] = useUpdateCompanyImageMutation({
 *   variables: {
 *      options: // value for 'options'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateCompanyImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyImageMutation, UpdateCompanyImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyImageMutation, UpdateCompanyImageMutationVariables>(UpdateCompanyImageDocument, options);
      }
export type UpdateCompanyImageMutationHookResult = ReturnType<typeof useUpdateCompanyImageMutation>;
export type UpdateCompanyImageMutationResult = Apollo.MutationResult<UpdateCompanyImageMutation>;
export type UpdateCompanyImageMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyImageMutation, UpdateCompanyImageMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($options: CompanyUpdateInput!) {
  updateCompany(options: $options) {
    errors {
      field
      message
    }
    company {
      name
      id
      url
      phone
      secondaryPhone
      image
      fax
      footnote
      address {
        state
        street
        country
        city
        post_code
        id
        isDefault
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const GoogleGenerateAuthLinkDocument = gql`
    mutation GoogleGenerateAuthLink($options: GoogleGenerateAuthLinkInput!) {
  GoogleGenerateAuthLink(options: $options) {
    errors {
      field
      message
    }
    url
  }
}
    `;
export type GoogleGenerateAuthLinkMutationFn = Apollo.MutationFunction<GoogleGenerateAuthLinkMutation, GoogleGenerateAuthLinkMutationVariables>;

/**
 * __useGoogleGenerateAuthLinkMutation__
 *
 * To run a mutation, you first call `useGoogleGenerateAuthLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleGenerateAuthLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleGenerateAuthLinkMutation, { data, loading, error }] = useGoogleGenerateAuthLinkMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGoogleGenerateAuthLinkMutation(baseOptions?: Apollo.MutationHookOptions<GoogleGenerateAuthLinkMutation, GoogleGenerateAuthLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleGenerateAuthLinkMutation, GoogleGenerateAuthLinkMutationVariables>(GoogleGenerateAuthLinkDocument, options);
      }
export type GoogleGenerateAuthLinkMutationHookResult = ReturnType<typeof useGoogleGenerateAuthLinkMutation>;
export type GoogleGenerateAuthLinkMutationResult = Apollo.MutationResult<GoogleGenerateAuthLinkMutation>;
export type GoogleGenerateAuthLinkMutationOptions = Apollo.BaseMutationOptions<GoogleGenerateAuthLinkMutation, GoogleGenerateAuthLinkMutationVariables>;
export const GoogleVerifyDocument = gql`
    mutation GoogleVerify($code: String!, $state: String!) {
  GoogleVerify(options: {code: $code, state: $state}) {
    errors {
      field
      message
    }
    user {
      name
      email
      isLinked
      id
    }
    redirectUri
  }
}
    `;
export type GoogleVerifyMutationFn = Apollo.MutationFunction<GoogleVerifyMutation, GoogleVerifyMutationVariables>;

/**
 * __useGoogleVerifyMutation__
 *
 * To run a mutation, you first call `useGoogleVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleVerifyMutation, { data, loading, error }] = useGoogleVerifyMutation({
 *   variables: {
 *      code: // value for 'code'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGoogleVerifyMutation(baseOptions?: Apollo.MutationHookOptions<GoogleVerifyMutation, GoogleVerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleVerifyMutation, GoogleVerifyMutationVariables>(GoogleVerifyDocument, options);
      }
export type GoogleVerifyMutationHookResult = ReturnType<typeof useGoogleVerifyMutation>;
export type GoogleVerifyMutationResult = Apollo.MutationResult<GoogleVerifyMutation>;
export type GoogleVerifyMutationOptions = Apollo.BaseMutationOptions<GoogleVerifyMutation, GoogleVerifyMutationVariables>;
export const HubspotAuthUrlDocument = gql`
    query HubspotAuthUrl {
  hubspotAuthUrl {
    errors {
      field
      message
    }
    link
  }
}
    `;

/**
 * __useHubspotAuthUrlQuery__
 *
 * To run a query within a React component, call `useHubspotAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubspotAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubspotAuthUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useHubspotAuthUrlQuery(baseOptions?: Apollo.QueryHookOptions<HubspotAuthUrlQuery, HubspotAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HubspotAuthUrlQuery, HubspotAuthUrlQueryVariables>(HubspotAuthUrlDocument, options);
      }
export function useHubspotAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HubspotAuthUrlQuery, HubspotAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HubspotAuthUrlQuery, HubspotAuthUrlQueryVariables>(HubspotAuthUrlDocument, options);
        }
export type HubspotAuthUrlQueryHookResult = ReturnType<typeof useHubspotAuthUrlQuery>;
export type HubspotAuthUrlLazyQueryHookResult = ReturnType<typeof useHubspotAuthUrlLazyQuery>;
export type HubspotAuthUrlQueryResult = Apollo.QueryResult<HubspotAuthUrlQuery, HubspotAuthUrlQueryVariables>;
export const HubspotUserListDocument = gql`
    query HubspotUserList($limit: Float!, $cursor: String!) {
  hubspotUserList(options: {limit: $limit, cursor: $cursor}) {
    errors {
      field
      message
    }
    cursor
    list {
      lastName
      firstName
      owner
      has_owner
      teams {
        id
        name
      }
      userId
      id
      linked_google
      email
    }
  }
}
    `;

/**
 * __useHubspotUserListQuery__
 *
 * To run a query within a React component, call `useHubspotUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubspotUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubspotUserListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useHubspotUserListQuery(baseOptions: Apollo.QueryHookOptions<HubspotUserListQuery, HubspotUserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HubspotUserListQuery, HubspotUserListQueryVariables>(HubspotUserListDocument, options);
      }
export function useHubspotUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HubspotUserListQuery, HubspotUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HubspotUserListQuery, HubspotUserListQueryVariables>(HubspotUserListDocument, options);
        }
export type HubspotUserListQueryHookResult = ReturnType<typeof useHubspotUserListQuery>;
export type HubspotUserListLazyQueryHookResult = ReturnType<typeof useHubspotUserListLazyQuery>;
export type HubspotUserListQueryResult = Apollo.QueryResult<HubspotUserListQuery, HubspotUserListQueryVariables>;
export const OnBoardCompanyDocument = gql`
    mutation OnBoardCompany($options: onBoardCompanyInput!, $image: Upload!) {
  onBoardCompany(options: $options, image: $image) {
    errors {
      field
      message
    }
    company {
      name
      id
      url
      phone
      secondaryPhone
      image
      fax
      footnote
      address {
        state
        street
        country
        city
        post_code
        id
        isDefault
      }
      socials {
        link
        details
      }
    }
    account {
      displayName
      firstName
      lastName
      hubDomain
      type
      status
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type OnBoardCompanyMutationFn = Apollo.MutationFunction<OnBoardCompanyMutation, OnBoardCompanyMutationVariables>;

/**
 * __useOnBoardCompanyMutation__
 *
 * To run a mutation, you first call `useOnBoardCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnBoardCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onBoardCompanyMutation, { data, loading, error }] = useOnBoardCompanyMutation({
 *   variables: {
 *      options: // value for 'options'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useOnBoardCompanyMutation(baseOptions?: Apollo.MutationHookOptions<OnBoardCompanyMutation, OnBoardCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnBoardCompanyMutation, OnBoardCompanyMutationVariables>(OnBoardCompanyDocument, options);
      }
export type OnBoardCompanyMutationHookResult = ReturnType<typeof useOnBoardCompanyMutation>;
export type OnBoardCompanyMutationResult = Apollo.MutationResult<OnBoardCompanyMutation>;
export type OnBoardCompanyMutationOptions = Apollo.BaseMutationOptions<OnBoardCompanyMutation, OnBoardCompanyMutationVariables>;
export const OnBoardFormDocument = gql`
    mutation OnBoardForm($options: onBoardFormInput!) {
  onBoardForm(options: $options) {
    errors {
      field
      message
    }
    accessToken
    socials {
      id
      priority
      icon_round
      icon_solid
      icon_square
      name
    }
    account {
      displayName
      firstName
      lastName
      hubDomain
      type
      status
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type OnBoardFormMutationFn = Apollo.MutationFunction<OnBoardFormMutation, OnBoardFormMutationVariables>;

/**
 * __useOnBoardFormMutation__
 *
 * To run a mutation, you first call `useOnBoardFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnBoardFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onBoardFormMutation, { data, loading, error }] = useOnBoardFormMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useOnBoardFormMutation(baseOptions?: Apollo.MutationHookOptions<OnBoardFormMutation, OnBoardFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnBoardFormMutation, OnBoardFormMutationVariables>(OnBoardFormDocument, options);
      }
export type OnBoardFormMutationHookResult = ReturnType<typeof useOnBoardFormMutation>;
export type OnBoardFormMutationResult = Apollo.MutationResult<OnBoardFormMutation>;
export type OnBoardFormMutationOptions = Apollo.BaseMutationOptions<OnBoardFormMutation, OnBoardFormMutationVariables>;
export const OnBoardHubspotDocument = gql`
    mutation OnBoardHubspot($hubspot_code: String!) {
  onBoardHubspot(options: {hubspot_code: $hubspot_code}) {
    errors {
      field
      message
    }
    socials {
      id
      priority
      icon_round
      icon_solid
      icon_square
      name
    }
    account {
      displayName
      firstName
      lastName
      status
      hubDomain
      type
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type OnBoardHubspotMutationFn = Apollo.MutationFunction<OnBoardHubspotMutation, OnBoardHubspotMutationVariables>;

/**
 * __useOnBoardHubspotMutation__
 *
 * To run a mutation, you first call `useOnBoardHubspotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnBoardHubspotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onBoardHubspotMutation, { data, loading, error }] = useOnBoardHubspotMutation({
 *   variables: {
 *      hubspot_code: // value for 'hubspot_code'
 *   },
 * });
 */
export function useOnBoardHubspotMutation(baseOptions?: Apollo.MutationHookOptions<OnBoardHubspotMutation, OnBoardHubspotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnBoardHubspotMutation, OnBoardHubspotMutationVariables>(OnBoardHubspotDocument, options);
      }
export type OnBoardHubspotMutationHookResult = ReturnType<typeof useOnBoardHubspotMutation>;
export type OnBoardHubspotMutationResult = Apollo.MutationResult<OnBoardHubspotMutation>;
export type OnBoardHubspotMutationOptions = Apollo.BaseMutationOptions<OnBoardHubspotMutation, OnBoardHubspotMutationVariables>;
export const OnBoardImportDocument = gql`
    mutation OnBoardImport($options: onBoardImportInput!) {
  onBoardImport(options: $options) {
    errors {
      field
      message
    }
    template {
      id
      name
      html
      isDefault
    }
    users {
      passcode
      name
      mobile
      role
      phone
      hub_id
      image
      type
      isLinked
      assigned
      email
      id
      teams {
        id
        name
      }
      socials {
        link
        details
      }
      signature {
        id
        name
      }
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    teams {
      id
      name
    }
    account {
      displayName
      firstName
      hubDomain
      lastName
      status
      type
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type OnBoardImportMutationFn = Apollo.MutationFunction<OnBoardImportMutation, OnBoardImportMutationVariables>;

/**
 * __useOnBoardImportMutation__
 *
 * To run a mutation, you first call `useOnBoardImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnBoardImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onBoardImportMutation, { data, loading, error }] = useOnBoardImportMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useOnBoardImportMutation(baseOptions?: Apollo.MutationHookOptions<OnBoardImportMutation, OnBoardImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnBoardImportMutation, OnBoardImportMutationVariables>(OnBoardImportDocument, options);
      }
export type OnBoardImportMutationHookResult = ReturnType<typeof useOnBoardImportMutation>;
export type OnBoardImportMutationResult = Apollo.MutationResult<OnBoardImportMutation>;
export type OnBoardImportMutationOptions = Apollo.BaseMutationOptions<OnBoardImportMutation, OnBoardImportMutationVariables>;
export const OnBoardProfileDocument = gql`
    mutation OnBoardProfile($options: OnBoardProfileInput!, $image: Upload!) {
  onBoardProfile(options: $options, image: $image) {
    errors {
      field
      message
    }
    account {
      displayName
      firstName
      lastName
      status
      hubDomain
      email
      type
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type OnBoardProfileMutationFn = Apollo.MutationFunction<OnBoardProfileMutation, OnBoardProfileMutationVariables>;

/**
 * __useOnBoardProfileMutation__
 *
 * To run a mutation, you first call `useOnBoardProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnBoardProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onBoardProfileMutation, { data, loading, error }] = useOnBoardProfileMutation({
 *   variables: {
 *      options: // value for 'options'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useOnBoardProfileMutation(baseOptions?: Apollo.MutationHookOptions<OnBoardProfileMutation, OnBoardProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnBoardProfileMutation, OnBoardProfileMutationVariables>(OnBoardProfileDocument, options);
      }
export type OnBoardProfileMutationHookResult = ReturnType<typeof useOnBoardProfileMutation>;
export type OnBoardProfileMutationResult = Apollo.MutationResult<OnBoardProfileMutation>;
export type OnBoardProfileMutationOptions = Apollo.BaseMutationOptions<OnBoardProfileMutation, OnBoardProfileMutationVariables>;
export const OnBoardSubscriptionDocument = gql`
    mutation OnBoardSubscription($options: onBoardSubscriptionInput!) {
  onBoardSubscription(options: $options) {
    errors {
      field
      message
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      coupon
      price
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    account {
      displayName
      firstName
      lastName
      status
      type
      email
      userName
      hubDomain
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type OnBoardSubscriptionMutationFn = Apollo.MutationFunction<OnBoardSubscriptionMutation, OnBoardSubscriptionMutationVariables>;

/**
 * __useOnBoardSubscriptionMutation__
 *
 * To run a mutation, you first call `useOnBoardSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnBoardSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onBoardSubscriptionMutation, { data, loading, error }] = useOnBoardSubscriptionMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useOnBoardSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<OnBoardSubscriptionMutation, OnBoardSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnBoardSubscriptionMutation, OnBoardSubscriptionMutationVariables>(OnBoardSubscriptionDocument, options);
      }
export type OnBoardSubscriptionMutationHookResult = ReturnType<typeof useOnBoardSubscriptionMutation>;
export type OnBoardSubscriptionMutationResult = Apollo.MutationResult<OnBoardSubscriptionMutation>;
export type OnBoardSubscriptionMutationOptions = Apollo.BaseMutationOptions<OnBoardSubscriptionMutation, OnBoardSubscriptionMutationVariables>;
export const SignaturePushToGmailDocument = gql`
    mutation SignaturePushToGmail($options: SignaturePushInput!) {
  SignaturePushToGmail(options: $options) {
    errors {
      field
      message
    }
  }
}
    `;
export type SignaturePushToGmailMutationFn = Apollo.MutationFunction<SignaturePushToGmailMutation, SignaturePushToGmailMutationVariables>;

/**
 * __useSignaturePushToGmailMutation__
 *
 * To run a mutation, you first call `useSignaturePushToGmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignaturePushToGmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signaturePushToGmailMutation, { data, loading, error }] = useSignaturePushToGmailMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSignaturePushToGmailMutation(baseOptions?: Apollo.MutationHookOptions<SignaturePushToGmailMutation, SignaturePushToGmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignaturePushToGmailMutation, SignaturePushToGmailMutationVariables>(SignaturePushToGmailDocument, options);
      }
export type SignaturePushToGmailMutationHookResult = ReturnType<typeof useSignaturePushToGmailMutation>;
export type SignaturePushToGmailMutationResult = Apollo.MutationResult<SignaturePushToGmailMutation>;
export type SignaturePushToGmailMutationOptions = Apollo.BaseMutationOptions<SignaturePushToGmailMutation, SignaturePushToGmailMutationVariables>;
export const SignatureAddDocument = gql`
    mutation SignatureAdd($options: SignatureInput!) {
  signatureAdd(options: $options) {
    errors {
      field
      message
    }
    signature {
      users {
        passcode
        name
        mobile
        role
        phone
        type
        hub_id
        image
        isLinked
        assigned
        email
        id
        custom
        meetingLink
        meetingLinkLabel
        teams {
          id
          name
        }
        socials {
          link
          details
        }
      }
      template {
        id
        name
      }
      id
      name
      customCode
      meetingLinkImg
      embedCode
      embedCodeTwo
      hasDraft
      draft {
        name
        meetingLinkImg
        customCode
        embedCode
        embedCodeTwo
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            size
            show
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        separator {
          type
          color
        }
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
  }
}
    `;
export type SignatureAddMutationFn = Apollo.MutationFunction<SignatureAddMutation, SignatureAddMutationVariables>;

/**
 * __useSignatureAddMutation__
 *
 * To run a mutation, you first call `useSignatureAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignatureAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signatureAddMutation, { data, loading, error }] = useSignatureAddMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSignatureAddMutation(baseOptions?: Apollo.MutationHookOptions<SignatureAddMutation, SignatureAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignatureAddMutation, SignatureAddMutationVariables>(SignatureAddDocument, options);
      }
export type SignatureAddMutationHookResult = ReturnType<typeof useSignatureAddMutation>;
export type SignatureAddMutationResult = Apollo.MutationResult<SignatureAddMutation>;
export type SignatureAddMutationOptions = Apollo.BaseMutationOptions<SignatureAddMutation, SignatureAddMutationVariables>;
export const SignatureAssignDocument = gql`
    mutation SignatureAssign($options: SignatureAssignInput!) {
  signatureAssign(options: $options) {
    errors {
      field
      message
    }
    signature {
      users {
        passcode
        name
        mobile
        role
        phone
        hub_id
        image
        type
        isLinked
        assigned
        email
        id
        custom
        meetingLink
        meetingLinkLabel
        teams {
          id
          name
        }
        socials {
          link
          details
        }
      }
      template {
        id
        name
      }
      id
      name
      customCode
      meetingLinkImg
      embedCode
      embedCodeTwo
      hasDraft
      draft {
        name
        meetingLinkImg
        customCode
        embedCode
        embedCodeTwo
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            size
            show
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        separator {
          type
          color
        }
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
  }
}
    `;
export type SignatureAssignMutationFn = Apollo.MutationFunction<SignatureAssignMutation, SignatureAssignMutationVariables>;

/**
 * __useSignatureAssignMutation__
 *
 * To run a mutation, you first call `useSignatureAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignatureAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signatureAssignMutation, { data, loading, error }] = useSignatureAssignMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSignatureAssignMutation(baseOptions?: Apollo.MutationHookOptions<SignatureAssignMutation, SignatureAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignatureAssignMutation, SignatureAssignMutationVariables>(SignatureAssignDocument, options);
      }
export type SignatureAssignMutationHookResult = ReturnType<typeof useSignatureAssignMutation>;
export type SignatureAssignMutationResult = Apollo.MutationResult<SignatureAssignMutation>;
export type SignatureAssignMutationOptions = Apollo.BaseMutationOptions<SignatureAssignMutation, SignatureAssignMutationVariables>;
export const SignatureCopyHtmlDocument = gql`
    query signatureCopyHtml($user: String!) {
  signatureCopyHtml(options: {user: $user}) {
    errors {
      field
      message
    }
    googleLink
    account {
      displayName
      firstName
      lastName
      status
      email
      userName
      hubDomain
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    socials {
      id
      priority
      icon_round
      icon_color
      icon_solid
      icon_square
      name
    }
    company {
      id
      phone
      secondaryPhone
      image
      url
      socials {
        link
        details
      }
      name
      address {
        state
        street
        city
        country
        post_code
        id
      }
    }
    signature {
      id
      name
      customCode
      meetingLinkImg
      embedCode
      embedCodeTwo
      properties {
        separator {
          type
          color
        }
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
    template {
      id
      name
      html
      previewImage
      isDefault
    }
    user {
      name
      mobile
      role
      phone
      hub_id
      image
      type
      isLinked
      assigned
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      teams {
        id
        name
      }
      socials {
        link
        details
      }
      signature {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSignatureCopyHtmlQuery__
 *
 * To run a query within a React component, call `useSignatureCopyHtmlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignatureCopyHtmlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignatureCopyHtmlQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSignatureCopyHtmlQuery(baseOptions: Apollo.QueryHookOptions<SignatureCopyHtmlQuery, SignatureCopyHtmlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignatureCopyHtmlQuery, SignatureCopyHtmlQueryVariables>(SignatureCopyHtmlDocument, options);
      }
export function useSignatureCopyHtmlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignatureCopyHtmlQuery, SignatureCopyHtmlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignatureCopyHtmlQuery, SignatureCopyHtmlQueryVariables>(SignatureCopyHtmlDocument, options);
        }
export type SignatureCopyHtmlQueryHookResult = ReturnType<typeof useSignatureCopyHtmlQuery>;
export type SignatureCopyHtmlLazyQueryHookResult = ReturnType<typeof useSignatureCopyHtmlLazyQuery>;
export type SignatureCopyHtmlQueryResult = Apollo.QueryResult<SignatureCopyHtmlQuery, SignatureCopyHtmlQueryVariables>;
export const SignatureDetachUsersDocument = gql`
    mutation SignatureDetachUsers($options: SignatureDetachInput!) {
  signatureDetachUsers(options: $options) {
    errors {
      field
      message
    }
    signature {
      users {
        passcode
        name
        mobile
        role
        phone
        hub_id
        image
        type
        isLinked
        assigned
        email
        id
        custom
        meetingLink
        meetingLinkLabel
        teams {
          id
          name
        }
        socials {
          link
          details
        }
      }
      template {
        id
        name
      }
      id
      name
      customCode
      meetingLinkImg
      embedCode
      embedCodeTwo
      hasDraft
      draft {
        name
        meetingLinkImg
        customCode
        embedCode
        embedCodeTwo
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            size
            show
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        separator {
          type
          color
        }
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          size
          show
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
  }
}
    `;
export type SignatureDetachUsersMutationFn = Apollo.MutationFunction<SignatureDetachUsersMutation, SignatureDetachUsersMutationVariables>;

/**
 * __useSignatureDetachUsersMutation__
 *
 * To run a mutation, you first call `useSignatureDetachUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignatureDetachUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signatureDetachUsersMutation, { data, loading, error }] = useSignatureDetachUsersMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSignatureDetachUsersMutation(baseOptions?: Apollo.MutationHookOptions<SignatureDetachUsersMutation, SignatureDetachUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignatureDetachUsersMutation, SignatureDetachUsersMutationVariables>(SignatureDetachUsersDocument, options);
      }
export type SignatureDetachUsersMutationHookResult = ReturnType<typeof useSignatureDetachUsersMutation>;
export type SignatureDetachUsersMutationResult = Apollo.MutationResult<SignatureDetachUsersMutation>;
export type SignatureDetachUsersMutationOptions = Apollo.BaseMutationOptions<SignatureDetachUsersMutation, SignatureDetachUsersMutationVariables>;
export const SignatureListDocument = gql`
    query signatureList($arg: ConnectionArgs!) {
  signatureList(arg: $arg) {
    errors {
      field
      message
    }
    page {
      edges {
        node {
          users {
            passcode
            name
            mobile
            role
            phone
            hub_id
            image
            type
            isLinked
            assigned
            email
            id
            custom
            meetingLink
            meetingLinkLabel
            teams {
              id
              name
            }
            socials {
              link
              details
            }
          }
          template {
            id
            name
          }
          id
          name
          customCode
          meetingLinkImg
          embedCode
          embedCodeTwo
          hasDraft
          draft {
            name
            meetingLinkImg
            customCode
            embedCode
            embedCodeTwo
            id
            properties {
              separator {
                type
                color
              }
              heading {
                fontStyle
                fontSize
                fontFamily
                fontWeight
                color
                lineHeight
              }
              link {
                fontStyle
                fontSize
                fontFamily
                fontWeight
                color
                lineHeight
              }
              body {
                fontStyle
                fontSize
                fontFamily
                fontWeight
                color
                lineHeight
              }
              footnote {
                fontStyle
                fontSize
                fontFamily
                fontWeight
                color
                lineHeight
              }
              meetingLink {
                fontFamily
                fontSize
                fontWeight
                lineHeight
                color
                backgroundColor
                hPadding
                vPadding
                borderWidth
                borderStyle
                borderColor
              }
              icons {
                social {
                  backgroundColor
                }
                contact {
                  backgroundColor
                }
                show
                size
              }
              avatar {
                size
                shape
                borderRadius
              }
              socials {
                size
                show
                iconStyle
              }
              logo {
                size
              }
              dividers {
                thickness
                style
                color
                show
              }
            }
            template {
              id
              name
            }
          }
          properties {
            separator {
              type
              color
            }
            heading {
              fontStyle
              fontSize
              fontFamily
              fontWeight
              color
              lineHeight
            }
            link {
              fontStyle
              fontSize
              fontFamily
              fontWeight
              color
              lineHeight
            }
            body {
              fontStyle
              fontSize
              fontFamily
              fontWeight
              color
              lineHeight
            }
            footnote {
              fontStyle
              fontSize
              fontFamily
              fontWeight
              color
              lineHeight
            }
            meetingLink {
              fontFamily
              fontSize
              fontWeight
              lineHeight
              color
              backgroundColor
              hPadding
              vPadding
              borderWidth
              borderStyle
              borderColor
            }
            icons {
              social {
                backgroundColor
              }
              contact {
                backgroundColor
              }
              show
              size
            }
            avatar {
              size
              shape
              borderRadius
            }
            socials {
              size
              show
              iconStyle
            }
            logo {
              size
            }
            dividers {
              thickness
              style
              color
              show
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSignatureListQuery__
 *
 * To run a query within a React component, call `useSignatureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignatureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignatureListQuery({
 *   variables: {
 *      arg: // value for 'arg'
 *   },
 * });
 */
export function useSignatureListQuery(baseOptions: Apollo.QueryHookOptions<SignatureListQuery, SignatureListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignatureListQuery, SignatureListQueryVariables>(SignatureListDocument, options);
      }
export function useSignatureListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignatureListQuery, SignatureListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignatureListQuery, SignatureListQueryVariables>(SignatureListDocument, options);
        }
export type SignatureListQueryHookResult = ReturnType<typeof useSignatureListQuery>;
export type SignatureListLazyQueryHookResult = ReturnType<typeof useSignatureListLazyQuery>;
export type SignatureListQueryResult = Apollo.QueryResult<SignatureListQuery, SignatureListQueryVariables>;
export const SignaturePublishDocument = gql`
    mutation SignaturePublish($options: SignaturePublishInput!) {
  signaturePublish(options: $options) {
    errors {
      field
      message
    }
    signature {
      users {
        passcode
        name
        mobile
        role
        phone
        hub_id
        image
        type
        isLinked
        assigned
        email
        id
        custom
        meetingLink
        meetingLinkLabel
        teams {
          id
          name
        }
        socials {
          link
          details
        }
      }
      template {
        id
        name
      }
      id
      name
      customCode
      meetingLinkImg
      embedCode
      embedCodeTwo
      hasDraft
      draft {
        name
        customCode
        meetingLinkImg
        embedCode
        embedCodeTwo
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            size
            show
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        separator {
          type
          color
        }
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
  }
}
    `;
export type SignaturePublishMutationFn = Apollo.MutationFunction<SignaturePublishMutation, SignaturePublishMutationVariables>;

/**
 * __useSignaturePublishMutation__
 *
 * To run a mutation, you first call `useSignaturePublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignaturePublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signaturePublishMutation, { data, loading, error }] = useSignaturePublishMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSignaturePublishMutation(baseOptions?: Apollo.MutationHookOptions<SignaturePublishMutation, SignaturePublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignaturePublishMutation, SignaturePublishMutationVariables>(SignaturePublishDocument, options);
      }
export type SignaturePublishMutationHookResult = ReturnType<typeof useSignaturePublishMutation>;
export type SignaturePublishMutationResult = Apollo.MutationResult<SignaturePublishMutation>;
export type SignaturePublishMutationOptions = Apollo.BaseMutationOptions<SignaturePublishMutation, SignaturePublishMutationVariables>;
export const SignatureRemoveDocument = gql`
    mutation SignatureRemove($options: SignatureDeleteInput!) {
  signatureRemove(options: $options) {
    errors {
      field
      message
    }
    signature
  }
}
    `;
export type SignatureRemoveMutationFn = Apollo.MutationFunction<SignatureRemoveMutation, SignatureRemoveMutationVariables>;

/**
 * __useSignatureRemoveMutation__
 *
 * To run a mutation, you first call `useSignatureRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignatureRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signatureRemoveMutation, { data, loading, error }] = useSignatureRemoveMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSignatureRemoveMutation(baseOptions?: Apollo.MutationHookOptions<SignatureRemoveMutation, SignatureRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignatureRemoveMutation, SignatureRemoveMutationVariables>(SignatureRemoveDocument, options);
      }
export type SignatureRemoveMutationHookResult = ReturnType<typeof useSignatureRemoveMutation>;
export type SignatureRemoveMutationResult = Apollo.MutationResult<SignatureRemoveMutation>;
export type SignatureRemoveMutationOptions = Apollo.BaseMutationOptions<SignatureRemoveMutation, SignatureRemoveMutationVariables>;
export const SignatureRevertDocument = gql`
    mutation SignatureRevert($options: SignatureRevertInput!) {
  signatureRevert(options: $options) {
    errors {
      field
      message
    }
    signature {
      users {
        passcode
        name
        mobile
        role
        phone
        hub_id
        image
        type
        isLinked
        assigned
        email
        id
        custom
        meetingLink
        meetingLinkLabel
        teams {
          id
          name
        }
        socials {
          link
          details
        }
      }
      template {
        id
        name
      }
      id
      name
      customCode
      meetingLinkImg
      embedCode
      embedCodeTwo
      hasDraft
      draft {
        name
        customCode
        meetingLinkImg
        embedCode
        embedCodeTwo
        id
        properties {
          separator {
            type
            color
          }
          heading {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          link {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          body {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          footnote {
            fontStyle
            fontSize
            fontFamily
            fontWeight
            color
            lineHeight
          }
          meetingLink {
            fontFamily
            fontSize
            fontWeight
            lineHeight
            color
            backgroundColor
            hPadding
            vPadding
            borderWidth
            borderStyle
            borderColor
          }
          icons {
            social {
              backgroundColor
            }
            contact {
              backgroundColor
            }
            size
            show
          }
          avatar {
            size
            shape
            borderRadius
          }
          socials {
            size
            show
            iconStyle
          }
          logo {
            size
          }
          dividers {
            thickness
            style
            color
            show
          }
        }
        template {
          id
          name
        }
      }
      properties {
        separator {
          type
          color
        }
        heading {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        link {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        body {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        footnote {
          fontStyle
          fontSize
          fontFamily
          fontWeight
          color
          lineHeight
        }
        meetingLink {
          fontFamily
          fontSize
          fontWeight
          lineHeight
          color
          backgroundColor
          hPadding
          vPadding
          borderWidth
          borderStyle
          borderColor
        }
        icons {
          social {
            backgroundColor
          }
          contact {
            backgroundColor
          }
          show
          size
        }
        avatar {
          size
          shape
          borderRadius
        }
        socials {
          size
          show
          iconStyle
        }
        logo {
          size
        }
        dividers {
          thickness
          style
          color
          show
        }
      }
    }
  }
}
    `;
export type SignatureRevertMutationFn = Apollo.MutationFunction<SignatureRevertMutation, SignatureRevertMutationVariables>;

/**
 * __useSignatureRevertMutation__
 *
 * To run a mutation, you first call `useSignatureRevertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignatureRevertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signatureRevertMutation, { data, loading, error }] = useSignatureRevertMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSignatureRevertMutation(baseOptions?: Apollo.MutationHookOptions<SignatureRevertMutation, SignatureRevertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignatureRevertMutation, SignatureRevertMutationVariables>(SignatureRevertDocument, options);
      }
export type SignatureRevertMutationHookResult = ReturnType<typeof useSignatureRevertMutation>;
export type SignatureRevertMutationResult = Apollo.MutationResult<SignatureRevertMutation>;
export type SignatureRevertMutationOptions = Apollo.BaseMutationOptions<SignatureRevertMutation, SignatureRevertMutationVariables>;
export const SocialItemsListDocument = gql`
    query SocialItemsList {
  socialItemsList {
    errors {
      field
      message
    }
    socialItem {
      id
      icon_round
      icon_solid
      icon_square
      icon_color
      name
      priority
    }
  }
}
    `;

/**
 * __useSocialItemsListQuery__
 *
 * To run a query within a React component, call `useSocialItemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialItemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialItemsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialItemsListQuery(baseOptions?: Apollo.QueryHookOptions<SocialItemsListQuery, SocialItemsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialItemsListQuery, SocialItemsListQueryVariables>(SocialItemsListDocument, options);
      }
export function useSocialItemsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialItemsListQuery, SocialItemsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialItemsListQuery, SocialItemsListQueryVariables>(SocialItemsListDocument, options);
        }
export type SocialItemsListQueryHookResult = ReturnType<typeof useSocialItemsListQuery>;
export type SocialItemsListLazyQueryHookResult = ReturnType<typeof useSocialItemsListLazyQuery>;
export type SocialItemsListQueryResult = Apollo.QueryResult<SocialItemsListQuery, SocialItemsListQueryVariables>;
export const RetrieveInvoiceDocument = gql`
    mutation RetrieveInvoice {
  retrieveInvoice {
    errors {
      field
      message
    }
    invoice
  }
}
    `;
export type RetrieveInvoiceMutationFn = Apollo.MutationFunction<RetrieveInvoiceMutation, RetrieveInvoiceMutationVariables>;

/**
 * __useRetrieveInvoiceMutation__
 *
 * To run a mutation, you first call `useRetrieveInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveInvoiceMutation, { data, loading, error }] = useRetrieveInvoiceMutation({
 *   variables: {
 *   },
 * });
 */
export function useRetrieveInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RetrieveInvoiceMutation, RetrieveInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetrieveInvoiceMutation, RetrieveInvoiceMutationVariables>(RetrieveInvoiceDocument, options);
      }
export type RetrieveInvoiceMutationHookResult = ReturnType<typeof useRetrieveInvoiceMutation>;
export type RetrieveInvoiceMutationResult = Apollo.MutationResult<RetrieveInvoiceMutation>;
export type RetrieveInvoiceMutationOptions = Apollo.BaseMutationOptions<RetrieveInvoiceMutation, RetrieveInvoiceMutationVariables>;
export const SubscriptionCancelDocument = gql`
    mutation subscriptionCancel {
  subscriptionCancel {
    errors {
      field
      message
    }
    account {
      displayName
      type
      firstName
      lastName
      status
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type SubscriptionCancelMutationFn = Apollo.MutationFunction<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>;

/**
 * __useSubscriptionCancelMutation__
 *
 * To run a mutation, you first call `useSubscriptionCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionCancelMutation, { data, loading, error }] = useSubscriptionCancelMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionCancelMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>(SubscriptionCancelDocument, options);
      }
export type SubscriptionCancelMutationHookResult = ReturnType<typeof useSubscriptionCancelMutation>;
export type SubscriptionCancelMutationResult = Apollo.MutationResult<SubscriptionCancelMutation>;
export type SubscriptionCancelMutationOptions = Apollo.BaseMutationOptions<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>;
export const SubscriptionPostCreateDocument = gql`
    mutation subscriptionPostCreate($options: SubscriptionPostInput!) {
  subscriptionPostCreate(options: $options) {
    errors {
      field
      message
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      coupon
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    account {
      displayName
      firstName
      lastName
      type
      status
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type SubscriptionPostCreateMutationFn = Apollo.MutationFunction<SubscriptionPostCreateMutation, SubscriptionPostCreateMutationVariables>;

/**
 * __useSubscriptionPostCreateMutation__
 *
 * To run a mutation, you first call `useSubscriptionPostCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPostCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionPostCreateMutation, { data, loading, error }] = useSubscriptionPostCreateMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSubscriptionPostCreateMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionPostCreateMutation, SubscriptionPostCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionPostCreateMutation, SubscriptionPostCreateMutationVariables>(SubscriptionPostCreateDocument, options);
      }
export type SubscriptionPostCreateMutationHookResult = ReturnType<typeof useSubscriptionPostCreateMutation>;
export type SubscriptionPostCreateMutationResult = Apollo.MutationResult<SubscriptionPostCreateMutation>;
export type SubscriptionPostCreateMutationOptions = Apollo.BaseMutationOptions<SubscriptionPostCreateMutation, SubscriptionPostCreateMutationVariables>;
export const SubscriptionUpdateDocument = gql`
    mutation subscriptionUpdate($options: SubscriptionUpdateInput!) {
  subscriptionUpdate(options: $options) {
    errors {
      field
      message
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      coupon
      invoiceId
      price
      coupon
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    account {
      displayName
      firstName
      lastName
      status
      email
      type
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type SubscriptionUpdateMutationFn = Apollo.MutationFunction<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>;

/**
 * __useSubscriptionUpdateMutation__
 *
 * To run a mutation, you first call `useSubscriptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionUpdateMutation, { data, loading, error }] = useSubscriptionUpdateMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSubscriptionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>(SubscriptionUpdateDocument, options);
      }
export type SubscriptionUpdateMutationHookResult = ReturnType<typeof useSubscriptionUpdateMutation>;
export type SubscriptionUpdateMutationResult = Apollo.MutationResult<SubscriptionUpdateMutation>;
export type SubscriptionUpdateMutationOptions = Apollo.BaseMutationOptions<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>;
export const SubscriptionUpgradeDocument = gql`
    mutation SubscriptionUpgrade($options: onBoardSubscriptionInput!) {
  subscriptionUpgrade(options: $options) {
    errors {
      field
      message
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      coupon
      price
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    billing {
      id
      billing_details {
        name
        address {
          line2
          city
          country
          postal_code
          state
        }
        email
      }
      card {
        brand
        country
        exp_year
        exp_month
        last4
      }
    }
    account {
      displayName
      firstName
      lastName
      status
      type
      email
      userName
      hubDomain
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type SubscriptionUpgradeMutationFn = Apollo.MutationFunction<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>;

/**
 * __useSubscriptionUpgradeMutation__
 *
 * To run a mutation, you first call `useSubscriptionUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionUpgradeMutation, { data, loading, error }] = useSubscriptionUpgradeMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSubscriptionUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>(SubscriptionUpgradeDocument, options);
      }
export type SubscriptionUpgradeMutationHookResult = ReturnType<typeof useSubscriptionUpgradeMutation>;
export type SubscriptionUpgradeMutationResult = Apollo.MutationResult<SubscriptionUpgradeMutation>;
export type SubscriptionUpgradeMutationOptions = Apollo.BaseMutationOptions<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>;
export const UserImportDocument = gql`
    mutation userImport($options: onBoardImportInput!, $image: Upload) {
  userImport(options: $options, image: $image) {
    errors {
      field
      message
    }
    teams {
      id
      name
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    users {
      name
      mobile
      role
      phone
      hub_id
      type
      image
      isLinked
      assigned
      email
      id
      meetingLink
      meetingLinkLabel
      profileLink
      signature {
        id
        name
      }
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UserImportMutationFn = Apollo.MutationFunction<UserImportMutation, UserImportMutationVariables>;

/**
 * __useUserImportMutation__
 *
 * To run a mutation, you first call `useUserImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userImportMutation, { data, loading, error }] = useUserImportMutation({
 *   variables: {
 *      options: // value for 'options'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUserImportMutation(baseOptions?: Apollo.MutationHookOptions<UserImportMutation, UserImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserImportMutation, UserImportMutationVariables>(UserImportDocument, options);
      }
export type UserImportMutationHookResult = ReturnType<typeof useUserImportMutation>;
export type UserImportMutationResult = Apollo.MutationResult<UserImportMutation>;
export type UserImportMutationOptions = Apollo.BaseMutationOptions<UserImportMutation, UserImportMutationVariables>;
export const UserLoginDocument = gql`
    mutation UserLogin($options: UserLoginInput!) {
  UserLogin(options: $options) {
    errors {
      field
      message
    }
  }
}
    `;
export type UserLoginMutationFn = Apollo.MutationFunction<UserLoginMutation, UserLoginMutationVariables>;

/**
 * __useUserLoginMutation__
 *
 * To run a mutation, you first call `useUserLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLoginMutation, { data, loading, error }] = useUserLoginMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserLoginMutation(baseOptions?: Apollo.MutationHookOptions<UserLoginMutation, UserLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLoginMutation, UserLoginMutationVariables>(UserLoginDocument, options);
      }
export type UserLoginMutationHookResult = ReturnType<typeof useUserLoginMutation>;
export type UserLoginMutationResult = Apollo.MutationResult<UserLoginMutation>;
export type UserLoginMutationOptions = Apollo.BaseMutationOptions<UserLoginMutation, UserLoginMutationVariables>;
export const UserSendDocument = gql`
    mutation UserSend($options: UserSendInput!) {
  UserSend(options: $options) {
    errors {
      field
      message
    }
    user {
      name
      mobile
      role
      phone
      hub_id
      type
      image
      isLinked
      assigned
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      profileLink
      signature {
        id
        name
      }
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UserSendMutationFn = Apollo.MutationFunction<UserSendMutation, UserSendMutationVariables>;

/**
 * __useUserSendMutation__
 *
 * To run a mutation, you first call `useUserSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSendMutation, { data, loading, error }] = useUserSendMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserSendMutation(baseOptions?: Apollo.MutationHookOptions<UserSendMutation, UserSendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSendMutation, UserSendMutationVariables>(UserSendDocument, options);
      }
export type UserSendMutationHookResult = ReturnType<typeof useUserSendMutation>;
export type UserSendMutationResult = Apollo.MutationResult<UserSendMutation>;
export type UserSendMutationOptions = Apollo.BaseMutationOptions<UserSendMutation, UserSendMutationVariables>;
export const UserUpdateServiceDocument = gql`
    mutation UserUpdateService($options: UserUpdateInput!) {
  UserUpdateService(options: $options) {
    errors {
      field
      message
    }
    googleAuth
    account {
      displayName
      firstName
      lastName
      status
      email
      hubDomain
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    user {
      name
      mobile
      role
      phone
      hub_id
      type
      image
      isLinked
      assigned
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      profileLink
      signature {
        id
        name
      }
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UserUpdateServiceMutationFn = Apollo.MutationFunction<UserUpdateServiceMutation, UserUpdateServiceMutationVariables>;

/**
 * __useUserUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUserUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateServiceMutation, { data, loading, error }] = useUserUpdateServiceMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateServiceMutation, UserUpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateServiceMutation, UserUpdateServiceMutationVariables>(UserUpdateServiceDocument, options);
      }
export type UserUpdateServiceMutationHookResult = ReturnType<typeof useUserUpdateServiceMutation>;
export type UserUpdateServiceMutationResult = Apollo.MutationResult<UserUpdateServiceMutation>;
export type UserUpdateServiceMutationOptions = Apollo.BaseMutationOptions<UserUpdateServiceMutation, UserUpdateServiceMutationVariables>;
export const UserUpdateServiceImageDocument = gql`
    mutation UserUpdateServiceImage($options: UserUpdateInput!, $image: Upload!) {
  UserUpdateServiceImage(options: $options, image: $image) {
    errors {
      field
      message
    }
    googleAuth
    account {
      displayName
      firstName
      lastName
      status
      email
      userName
      hubDomain
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
    user {
      name
      mobile
      role
      phone
      hub_id
      image
      isLinked
      assigned
      type
      email
      id
      department
      custom
      meetingLink
      meetingLinkLabel
      profileLink
      signature {
        id
        name
      }
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UserUpdateServiceImageMutationFn = Apollo.MutationFunction<UserUpdateServiceImageMutation, UserUpdateServiceImageMutationVariables>;

/**
 * __useUserUpdateServiceImageMutation__
 *
 * To run a mutation, you first call `useUserUpdateServiceImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateServiceImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateServiceImageMutation, { data, loading, error }] = useUserUpdateServiceImageMutation({
 *   variables: {
 *      options: // value for 'options'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUserUpdateServiceImageMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateServiceImageMutation, UserUpdateServiceImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateServiceImageMutation, UserUpdateServiceImageMutationVariables>(UserUpdateServiceImageDocument, options);
      }
export type UserUpdateServiceImageMutationHookResult = ReturnType<typeof useUserUpdateServiceImageMutation>;
export type UserUpdateServiceImageMutationResult = Apollo.MutationResult<UserUpdateServiceImageMutation>;
export type UserUpdateServiceImageMutationOptions = Apollo.BaseMutationOptions<UserUpdateServiceImageMutation, UserUpdateServiceImageMutationVariables>;
export const UserVerifyDocument = gql`
    mutation UserVerify($options: UserVerifyInput!) {
  UserVerify(options: $options) {
    errors {
      field
      message
    }
    socials {
      id
      priority
      icon_round
      icon_solid
      icon_square
      name
    }
    user {
      passcode
      name
      mobile
      role
      phone
      hub_id
      type
      image
      isLinked
      assigned
      email
      id
      meetingLink
      meetingLinkLabel
      profileLink
      signature {
        id
        name
      }
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
    }
    account {
      displayName
      firstName
      hubDomain
      lastName
      status
      email
      userName
      id
      terms_accepted
      hubspot_portal_id
      profile {
        image
        mobile
        role
        phone
        department
        custom
        socials {
          link
          details
        }
      }
    }
  }
}
    `;
export type UserVerifyMutationFn = Apollo.MutationFunction<UserVerifyMutation, UserVerifyMutationVariables>;

/**
 * __useUserVerifyMutation__
 *
 * To run a mutation, you first call `useUserVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userVerifyMutation, { data, loading, error }] = useUserVerifyMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserVerifyMutation(baseOptions?: Apollo.MutationHookOptions<UserVerifyMutation, UserVerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserVerifyMutation, UserVerifyMutationVariables>(UserVerifyDocument, options);
      }
export type UserVerifyMutationHookResult = ReturnType<typeof useUserVerifyMutation>;
export type UserVerifyMutationResult = Apollo.MutationResult<UserVerifyMutation>;
export type UserVerifyMutationOptions = Apollo.BaseMutationOptions<UserVerifyMutation, UserVerifyMutationVariables>;
export const UserDetachSignatureDocument = gql`
    mutation userDetachSignature($options: UserDetachInput!) {
  userDetachSignature(options: $options) {
    errors {
      field
      message
    }
    users {
      name
      mobile
      role
      phone
      hub_id
      image
      isLinked
      type
      assigned
      email
      id
      meetingLink
      meetingLinkLabel
      profileLink
      signature {
        id
        name
      }
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UserDetachSignatureMutationFn = Apollo.MutationFunction<UserDetachSignatureMutation, UserDetachSignatureMutationVariables>;

/**
 * __useUserDetachSignatureMutation__
 *
 * To run a mutation, you first call `useUserDetachSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDetachSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDetachSignatureMutation, { data, loading, error }] = useUserDetachSignatureMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserDetachSignatureMutation(baseOptions?: Apollo.MutationHookOptions<UserDetachSignatureMutation, UserDetachSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDetachSignatureMutation, UserDetachSignatureMutationVariables>(UserDetachSignatureDocument, options);
      }
export type UserDetachSignatureMutationHookResult = ReturnType<typeof useUserDetachSignatureMutation>;
export type UserDetachSignatureMutationResult = Apollo.MutationResult<UserDetachSignatureMutation>;
export type UserDetachSignatureMutationOptions = Apollo.BaseMutationOptions<UserDetachSignatureMutation, UserDetachSignatureMutationVariables>;
export const UserRemoveDocument = gql`
    mutation userRemove($options: UserDetachInput!) {
  userRemove(options: $options) {
    errors {
      field
      message
    }
    teams {
      id
      name
    }
    subscription {
      paymentMethodId
      latest_invoice
      status
      invoiceId
      price
      productId
      invoiceStatus
      quantity
      subscriptionPlan
      subscriptionId
      customerId
      renewDate
      inUse
    }
    users {
      name
      mobile
      role
      phone
      hub_id
      image
      type
      isLinked
      assigned
      email
      id
      meetingLink
      meetingLinkLabel
      profileLink
      signature {
        id
        name
      }
      teams {
        id
        name
      }
      socials {
        link
        details
        label
      }
    }
  }
}
    `;
export type UserRemoveMutationFn = Apollo.MutationFunction<UserRemoveMutation, UserRemoveMutationVariables>;

/**
 * __useUserRemoveMutation__
 *
 * To run a mutation, you first call `useUserRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRemoveMutation, { data, loading, error }] = useUserRemoveMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserRemoveMutation(baseOptions?: Apollo.MutationHookOptions<UserRemoveMutation, UserRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRemoveMutation, UserRemoveMutationVariables>(UserRemoveDocument, options);
      }
export type UserRemoveMutationHookResult = ReturnType<typeof useUserRemoveMutation>;
export type UserRemoveMutationResult = Apollo.MutationResult<UserRemoveMutation>;
export type UserRemoveMutationOptions = Apollo.BaseMutationOptions<UserRemoveMutation, UserRemoveMutationVariables>;
export const UserSearchListDocument = gql`
    mutation userSearchList($arg: ConnectionArgs!, $option: UserSearchInput!) {
  userSearchList(arg: $arg, option: $option) {
    errors {
      field
      message
    }
    page {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          mobile
          role
          phone
          hub_id
          image
          isLinked
          type
          assigned
          email
          id
          department
          custom
          meetingLink
          meetingLinkLabel
          profileLink
          signature {
            id
            name
          }
          teams {
            id
            name
          }
          socials {
            link
            details
            label
          }
        }
      }
    }
  }
}
    `;
export type UserSearchListMutationFn = Apollo.MutationFunction<UserSearchListMutation, UserSearchListMutationVariables>;

/**
 * __useUserSearchListMutation__
 *
 * To run a mutation, you first call `useUserSearchListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSearchListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSearchListMutation, { data, loading, error }] = useUserSearchListMutation({
 *   variables: {
 *      arg: // value for 'arg'
 *      option: // value for 'option'
 *   },
 * });
 */
export function useUserSearchListMutation(baseOptions?: Apollo.MutationHookOptions<UserSearchListMutation, UserSearchListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSearchListMutation, UserSearchListMutationVariables>(UserSearchListDocument, options);
      }
export type UserSearchListMutationHookResult = ReturnType<typeof useUserSearchListMutation>;
export type UserSearchListMutationResult = Apollo.MutationResult<UserSearchListMutation>;
export type UserSearchListMutationOptions = Apollo.BaseMutationOptions<UserSearchListMutation, UserSearchListMutationVariables>;