import React, { useContext, useState, createContext } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { DarkTheme, LightTheme } from './Themes';

const initialSideBox = {
  theme: 'light',
  changeTheme: null,
};

const ThemeContext = createContext(initialSideBox);

export const useCustomTheme = () => {
  const context: any = useContext(ThemeContext);
  return context;
};

const getThemeFromProp = (props: any) => {
  if (props.theme) {
    switch (props.theme) {
      case 'light': {
        return { name: 'light', component: LightTheme };
      }
      case 'dark': {
        return { name: 'dark', component: DarkTheme };
      }
      //add as many theme you want cool
      default: {
        return { name: 'light', component: LightTheme };
      }
    }
  }
  return { name: 'light', component: LightTheme };
};

export const ThemeProviderMaterial = (props: any) => {
  const { children } = props;
  const [theme, setTheme] = useState(getThemeFromProp(props)); //initialise theme if theme is provided in props
  const context: any = {
    theme: theme,
    changeTheme(theme: string) {
      switch (theme) {
        case 'light': {
          setTheme({ name: 'light', component: LightTheme });
          break;
        }
        case 'dark': {
          setTheme({ name: 'dark', component: DarkTheme });
          break;
        }
        //add as many theme you want cool
        default: {
          setTheme({ name: 'light', component: LightTheme });
          break;
        }
      }
    },
  };

  return (
    <ThemeContext.Provider value={context}>
      <ThemeProvider theme={context.theme.component}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
