import { configureStore, getDefaultMiddleware, Store } from '@reduxjs/toolkit';
//import createSagaMiddleware from "redux-saga";
import { reduxBatch } from '@manaflair/redux-batch';
import { Persistor } from 'redux-persist/es/types';
import { persistStore } from 'redux-persist';
import { rootReducer } from './rootReducer';

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
];

export const store: Store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
});

export const storePersistor: Persistor = persistStore(store);

//sagaMiddleware.run(rootSaga);
