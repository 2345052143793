import { createSlice } from '@reduxjs/toolkit';
import { SignatureEntities } from '../signature';

interface TeamInput {
  name: string;
  id: string;
}

/*
interface SignatureEntities {
    id: string,
    name: string,
}*/

export interface SocialsUserEntities {
  id: string;
  link: string;
  details: string;
}

export interface UsersEntities {
  id: string;
  email: string;
  name: string;
  hub_id: string;
  type: string;
  image: string;
  role: string;
  phone: string;
  mobile: string;
  passcode: string;
  department: string;
  custom: string;
  assigned: boolean;
  profileLink: string;
  meetingLink: string;
  meetingLinkLabel: string;
  teams: TeamInput[];
  signature: SignatureEntities;
  socials: SocialsUserEntities[];
  isLinked: boolean;
}

/*
interface PropertiesState {
    heading: {
        // @ts-ignore
        fontFamily?: Maybe<Scalars['String']>;
        fontSize?: Maybe<Scalars['Float']>;
        fontWeight?: Maybe<Scalars['Float']>;
        lineHeight?: Maybe<Scalars['Float']>;
        color?: Maybe<Scalars['String']>;
    },
    body: {
        fontFamily?: Maybe<Scalars['String']>;
        fontSize?: Maybe<Scalars['Float']>;
        fontWeight?: Maybe<Scalars['Float']>;
        lineHeight?: Maybe<Scalars['Float']>;
        color?: Maybe<Scalars['String']>;
    },
    link: {
        fontFamily?: Maybe<Scalars['String']>;
        fontSize?: Maybe<Scalars['Float']>;
        fontWeight?: Maybe<Scalars['Float']>;
        lineHeight?: Maybe<Scalars['Float']>;
        color?: Maybe<Scalars['String']>;
    },
    icons: {
        social: {
            backgroundColor: Maybe<Scalars['String']>
        },
        contact: {
            backgroundColor: Maybe<Scalars['String']>
        },
        size: Maybe<Scalars['Float']>,
        show: Maybe<Scalars['Boolean']>
    },
    avatar: {
        size: Maybe<Scalars['Float']>,
        shape: Maybe<Scalars['String']>,
        borderRadius: Maybe<Scalars['String']> //this is the output value of the shape, to avoid the need for template logic
    },
    socials: {
        show: Maybe<Scalars['Boolean']>,
        size: Maybe<Scalars['Float']>
    },
    logo: {
        size: Maybe<Scalars['Float']>,
    },
    dividers: {
        style: Maybe<Scalars['String']>,
        thickness: Maybe<Scalars['Boolean']>,
        color: Maybe<Scalars['String']>,
        show: Maybe<Scalars['Boolean']>,
    }
}*/
interface templateType {
  name: string;
  id: string;
  html: string;
}

/*
interface SignatureEntities {
    id: string,
    name: string;
    embedCode: string;
    template: templateType;
    properties: PropertiesState;
}*/

interface initialType {
  lastAction: string | null;
  usersList: UsersEntities[] | null | [];
  searchList: UsersEntities[] | null | [];
  searchField: {
    isSearch: boolean;
    team: string;
    name: string;
    limit: number;
    cursor: string;
    orderBy: string;
    sort: string;
    loadMore: boolean;
  };
  assignSignature: UsersEntities[] | [];
  current: {
    user: UsersEntities | null;
    signature: SignatureEntities | null;
  };
}

const UsersState: initialType = {
  lastAction: null,
  usersList: [],
  searchList: [],
  searchField: {
    loadMore: false,
    isSearch: false,
    team: '',
    name: '',
    cursor: '',
    limit: 20,
    orderBy: 'date',
    sort: 'desc',
  },
  assignSignature: [],
  current: {
    user: null,
    signature: null,
  },
};

export const callTypesUsers = {
  list: 'list',
  action: 'action',
};

const userStateUpdate = (userState: any, signature: any, callback: (user: any) => void) => {
  let user: any = { ...userState, signature: userState.signature ? userState.signature : null };
  //update user list
  const match = signature.users.filter((signUser: any) => signUser.id === userState.id);
  if (match.length > 0) {
    match[0].signature = {
      id: signature.id,
      name: signature.name,
      customCode: signature.customCode,
      meetingLink: signature.meetingLink,
    };
    user = match[0];
  }

  callback(user);
};

export const UsersSlice = createSlice({
  name: 'Users Objects',
  initialState: UsersState,
  reducers: {
    loadUsers: (state: any, action) => {
      const { users } = action.payload;
      state.usersList = users;
      state.lastAction = action.type;
    },
    setCurrent: (state: any, action) => {
      const { user, signature } = action.payload;
      state.current = {
        user,
        signature,
      };
      state.lastAction = action.type;
    },
    updateUser: (state: any, action) => {
      const { user } = action.payload; //array of signature
      const newUsers: UsersEntities[] = [];
      const searchUsers: UsersEntities[] = [];
      state.usersList.forEach((userState: UsersEntities) => {
        if (userState.id === user.id) {
          newUsers.push(user);
        } else {
          newUsers.push(userState);
        }
      });
      state.searchList.forEach((userState: UsersEntities) => {
        if (userState.id === user.id) {
          newUsers.push(user);
        } else {
          newUsers.push(userState);
        }
      });
      state.usersList = newUsers;
      state.searchList = searchUsers;
      state.lastAction = action.type;
    },
    updateSearch: (state: any, action) => {
      try {
        const { searchField, searchList } = action.payload;
        state.searchField = searchField;
        if (searchField.loadMore) {
          state.searchList = [...state.searchList, searchList];
        } else {
          state.searchList = searchList;
        }

        state.lastAction = action.type;
      } catch (e) {
        console.error(e.message, 'i am programming language');
      }
    },
    clearSearch: (state: any, action) => {
      state.searchList = [];
      state.searchField = {
        loadMore: false,
        isSearch: false,
        team: 'any',
        name: '',
        cursor: '',
        limit: 20,
        orderBy: 'date',
        sort: 'desc',
      };
      state.lastAction = action.type;
    },
    removeUser: (state: any, action) => {
      const { user } = action.payload; //array of signature
      const newUsers: UsersEntities[] = [];
      const searchUsers: UsersEntities[] = [];
      state.usersList.forEach((userState: UsersEntities) => {
        if (userState.id !== user) {
          newUsers.push(userState);
        }
      });
      state.searchList.forEach((userState: UsersEntities) => {
        if (userState.id !== user) {
          newUsers.push(userState);
        }
      });
      state.usersList = newUsers;
      state.searchList = searchUsers;

      state.lastAction = action.type;
    },
    removeUserSignature: (state: any, action) => {
      //from use wise
      const { user } = action.payload; //array of signature
      const newUsers: UsersEntities[] = [];
      const searchUsers: UsersEntities[] = [];
      state.usersList.forEach((userState: any) => {
        //update user list
        if (userState.id === user) {
          userState.signature = null;
        }
        newUsers.push(userState);
      });
      state.searchList.forEach((userState: any) => {
        //update user list
        if (userState.id === user) {
          userState.signature = null;
        }
        searchUsers.push(userState);
      });
      state.usersList = newUsers;
      state.searchList = searchUsers;
      state.lastAction = action.type;
    },
    removeSignatureUser: (state: any, action) => {
      //form signature wise
      const { signature } = action.payload; //array of signature
      state.usersList.forEach((userState: any) => {
        //update user list
        if (userState.signature && userState.signature.id === signature.id) {
          userState.signature = null;
        }
      });
      state.searchList.forEach((userState: any) => {
        //update user list
        if (userState.signature && userState.signature.id === signature.id) {
          userState.signature = null;
        }
      });
    },
    assignUsers: (state: any, action) => {
      try {
        const { signature } = action.payload;
        const newUsers: UsersEntities[] = [];
        const searchUsers: UsersEntities[] = [];

        state.usersList.forEach((userState: any) => {
          userStateUpdate(userState, signature, (user) => newUsers.push(user));
        });
        state.searchList.forEach((userState: any) => {
          userStateUpdate(userState, signature, (user) => searchUsers.push(user));
        });
        state.usersList = newUsers;
        state.searchList = searchUsers;
        state.lastAction = action.type;
      } catch (e) {
        console.error(e.message);
      }
    },
    updateAssignStateUsers: (state: any, action) => {
      try {
        const { users } = action.payload;
        state.assignSignature = users;
        state.lastAction = action.type;
      } catch (e) {
        console.error(e.message);
      }
    },
  },
});
