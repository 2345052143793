import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ButtonCustom } from '../../../style';
import { useUserDetachSignatureMutation, useUserRemoveMutation, useUserSendMutation } from '../../../api';
import { SignatureSlice, SubscriptionSlice, TeamSlice, UsersSlice } from '../../../store';
import { toastr } from 'react-redux-toastr';

export const UserActions = ({ user, signature, mode }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { detachSignatureUser } = SignatureSlice.actions;
  const { loadTeam } = TeamSlice.actions;
  const { removeUser, removeUserSignature } = UsersSlice.actions;
  const { loadSubscription } = SubscriptionSlice.actions;
  const [userSend] = useUserSendMutation();
  const [userDetachSignature] = useUserDetachSignatureMutation();
  const [userRemove] = useUserRemoveMutation();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl]: any = useState(null);

  const handleMenuClick = (event: any, id: string, signature: string) => {
    setAnchorEl(event.target);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    const id = user.id;
    handleMenuClose();
    history.push(`/users/preview/${id}`);
  };

  const handleSend = async () => {
    const id = user.id;
    await userSend({
      variables: {
        options: {
          userId: id,
        },
      } as any,
    });
    handleMenuClose();
  };

  const handleSignatureRemove = async () => {
    handleMenuClose();
    setLoading(true);
    const response = await userDetachSignature({
      variables: {
        options: {
          users: [{ id: user.id }],
        },
      } as any,
    });
    if (!response.errors && !response.data!.userDetachSignature.errors) {
      response.data!.userDetachSignature!.users!.forEach((user) => {
        dispatch(detachSignatureUser({ user: user.id, signature: signature.id }));
        dispatch(removeUserSignature({ user: user.id }));
      });
    } else {
      response.data!.userDetachSignature.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
    }
    setLoading(false);
  };

  const handleRemove = async () => {
    const id = user.id;
    const signature = user.signature;
    handleMenuClose();
    setLoading(true);
    const response = await userRemove({
      variables: {
        options: {
          users: [{ id: id }],
        },
      },
    });
    if (!response.errors && !response.data!.userRemove.errors) {
      response.data!.userRemove!.users!.forEach(() => {
        dispatch(removeUser({ user: id }));
        dispatch(detachSignatureUser({ user: id, signature: signature }));
      });
      dispatch(loadSubscription({ subscription: response.data!.userRemove.subscription }));
      dispatch(loadTeam({ team: response.data!.userRemove.teams }));
    } else {
      response.data!.userRemove.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
    }
    setLoading(false);
  };

  return (
    <>
      <ButtonCustom
        disabled={loading === user.id}
        aria-controls={`${user.id}-menu-actions-btn`}
        aria-haspopup="true"
        onClick={(event) => handleMenuClick(event, user.id, user.signature ? user.signature.id : '')}
      >
        {loading && <CircularProgress />}
        {!loading && <MoreVertIcon />}
      </ButtonCustom>
      {anchorEl && (
        <Menu id={`${user.id}-menu-actions`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleView}>Edit</MenuItem>
          {(user.signature || mode === 'signature') && <MenuItem onClick={handleSend}>Send</MenuItem>}
          {mode === 'user' && <MenuItem onClick={handleRemove}>Remove</MenuItem>}
          {mode === 'signature' && <MenuItem onClick={handleSignatureRemove}>Remove</MenuItem>}
        </Menu>
      )}
    </>
  );
};
