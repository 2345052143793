import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { animated, useTransition } from 'react-spring';
import clsx from 'clsx';
import { AlertContext } from './AlertProvider';

const useClass = makeStyles(() =>
  createStyles({
    alertGlobal: {
      position: 'fixed',
      maxHeight: '100vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      zIndex: 999999,
    },
    alertBasic: {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    alertTopLeft: {
      top: 0,
      left: 0,
    },
    alertTopRight: {
      top: 0,
      right: 0,
    },
    alertBottomLeft: {
      bottom: 0,
      left: 0,
    },
    alertBottomRight: {
      bottom: 0,
      right: 0,
    },
  })
);

const AlertLayout = ({ children, ...props }: any) => {
  const classes = useClass();
  const dir = () => {
    if ((props.type = 'global')) {
      switch (props.dir) {
        case 'top-left': {
          return classes.alertTopLeft;
        }
        case 'top-right': {
          return classes.alertTopRight;
        }
        case 'bottom-left': {
          return classes.alertBottomLeft;
        }
        case 'bottom-right': {
          return classes.alertBottomRight;
        }
        default: {
          return classes.alertTopLeft;
        }
      }
    }
  };

  if (props.type === 'global') {
    return (
      <Box display="flex" flexDirection="column" className={clsx(classes.alertGlobal, dir())}>
        {children}
      </Box>
    );
  } else {
    return (
      <Box display="flex" flexDirection="column" className={classes.alertBasic}>
        {children}
      </Box>
    );
  }
};

export const AlertBox = (props: any) => {
  const contextAlert: any = useContext(AlertContext);
  const components = contextAlert.components ? contextAlert.components : [];
  const configDefault = { tension: 125, friction: 20, precision: 0.1 };
  // const durationDefault = 40000000;
  const container = props.container ? props.container : 'global';
  const animationDirection = () => {
    if (container === 'global') {
      switch (props.dir) {
        case 'top-left': {
          return { start: 'translate3d(-100%,0,0)', end: 'translate3d(0,0,0)' };
        }
        case 'bottom-left': {
          return { start: 'translate3d(-100%,0,0)', end: 'translate3d(0,0,0)' };
        }
        case 'top-right': {
          return { start: 'translate3d(100%,0,0)', end: 'translate3d(0,0,0)' };
        }
        case 'bottom-right': {
          return { start: 'translate3d(100%,0,0)', end: 'translate3d(0,0,0)' };
        }
        default: {
          return null;
        }
      }
    }
    return null;
  };

  const transitions = useTransition(components, {
    key: (item: any) => item.key,
    from: { opacity: 0, height: 0, life: '100%', transform: animationDirection() ? animationDirection()!.start : '' },
    enter: (item: any) => async (next: any) => {
      const config = item.options.spring ? item.options.spring : configDefault;
      await next({
        opacity: 1,
        transform: animationDirection() ? animationDirection()!.end : '',
        height: 'auto',
        config,
      });
    },
    // leave: (item: any) => async (next: any) => {
    //   await next({ opacity: 0, config });
    // },
  } as any);

  return (
    <AlertLayout type={container} dir={props.dir}>
      {components &&
        transitions(
          ({ life, ...style }, item) =>
            container === item.options.container && (
              <animated.div key={`${item.key}_container_item`} style={style}>
                {item.component}
              </animated.div>
            )
        )}
    </AlertLayout>
  );
};
