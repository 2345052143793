import React, { ReactElement } from 'react';
import {
  FormControl,
  FormHelperText,
  CheckboxProps,
  Checkbox,
  ButtonClassKey,
  FormControlLabel,
  makeStyles,
  createStyles,
  FormGroup,
} from '@material-ui/core';

interface Styles extends Partial<Record<ButtonClassKey, string>> {}

interface Props extends CheckboxProps {
  classes?: Styles;
  label?: string;
  error?: string;
  disabled?: boolean;
  children?: ReactElement;
}

const useStyles = makeStyles(() =>
  createStyles({
    fieldset: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
  })
);

export const CheckboxCustom = ({ error, disabled, label, children, ...props }: Props) => {
  const localClasses = useStyles();

  return (
    <FormControl error={!!error} component="fieldset" variant="standard">
      <FormGroup className={localClasses.fieldset}>
        <FormControlLabel {...(disabled ? 'disabled' : '')} control={<Checkbox {...props} />} label={label ? label : ''} />
        {children}
      </FormGroup>
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
