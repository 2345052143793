import React, { forwardRef, useContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { AlertData } from './types';
import { AlertContext } from './AlertProvider';

const useStylesCard = makeStyles(() =>
  createStyles({
    alert: {
      marginBottom: 15,
      fontSize: 18,
      alignItems: 'flex-start',
    },
    alertHeader: {
      fontSize: 18,
    },
    alertBody: {
      fontSize: 18,
    },
  })
);

export const AlertCustom = forwardRef((props: any, ref) => {
  const classes = useStylesCard();
  const contextAlert: any = useContext(AlertContext);
  const data: AlertData = props.options.data
    ? props.options.data
    : {
        type: 'error', //warning,error,info,success
        title: 'Error',
        message: 'You are having some errors',
      };

  return (
    <Alert
      ref={ref}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            contextAlert.remove(props.id);
            if (props.options.onDismiss) {
              props.options.onDismiss(props.id);
            }
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      className={classes.alert}
      severity={data.type ? data.type : 'error'}
    >
      <AlertTitle className={classes.alertHeader}>{data.title ? data.title : 'Alert Title'}</AlertTitle>
      <div className={classes.alertBody}>{data.message ? data.message : 'Alert Message'}</div>
    </Alert>
  );
});
