import { lazy } from 'react';

export * from './ErrorPage';
export * from './SplashPage';

//lazy loaded until suspense page will appear
const HomePage = lazy(() => import('./HomePage'));
const AccountPage = lazy(() => import('./AccountPage'));
const OnBoardPage = lazy(() => import('./OnBoardPage'));
const LogoutPage = lazy(() => import('./LogoutPage'));
const SignaturePage = lazy(() => import('./SignaturePage'));
const UsersPage = lazy(() => import('./UsersPage'));
const OrganisationPage = lazy(() => import('./OrganisationPage'));

export { HomePage, AccountPage, OnBoardPage, LogoutPage, SignaturePage, UsersPage, OrganisationPage };
