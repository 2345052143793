import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core';
import { ButtonCustom } from '../../../index';

const getCurrentUrl = (location: any) => {
  return location.pathname.split(/[?#]/)[0];
};

const checkIsActive = (location: any, url: string) => {
  const current = getCurrentUrl(location);

  if (!current || !url) {
    return false;
  }
  if (current === url) {
    return true;
  }
  return current.indexOf(url) > -1;
};

export const useHeaderStyles = makeStyles(() =>
  createStyles({
    list: {
      listStyle: 'none',
      paddingLeft: 0,
      marginBottom: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    menuItem: {
      '&.active .MuiButton-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    buttonItem: {
      padding: '0.75rem 1.25rem',
      fontWeight: 400,
      textTransform: 'capitalize',
      margin: 0,
    },
    trigger: {
      cursor: 'pointer',
    },
  })
);

export const HeaderLayoutMenu = () => {
  const location = useLocation();
  const useClass = useHeaderStyles();
  const { subscription } = useSelector(
    (state: any) => ({
      subscription: state.subscription.subscription,
    }),
    shallowEqual
  );

  const getMenuItemActive = (url: string) => {
    return checkIsActive(location, url) ? 'active' : '';
  };

  return (
    <div>
      {/*begin::Header Nav*/}
      <ul className={useClass.list}>
        {/*begin::1 Level*/}
        {subscription && subscription.status !== 'incomplete_expired' && (
          <>
            {/*<li className={`${useClass.menuItem} ${getMenuItemActive('/users')}`}>*/}
            {/*  <NavLink to="/dashboard">*/}
            {/*    <ButtonCustom className={useClass.buttonItem} color="default">*/}
            {/*      Dashboard*/}
            {/*    </ButtonCustom>*/}
            {/*  </NavLink>*/}
            {/*</li>*/}
            <li className={`${useClass.menuItem} ${getMenuItemActive('/users')}`}>
              <NavLink to="/users">
                <ButtonCustom className={useClass.buttonItem} color="default">
                  Users
                </ButtonCustom>
              </NavLink>
            </li>
            <li className={`${useClass.menuItem} ${getMenuItemActive('/signature')}`}>
              <NavLink to="/signature">
                <ButtonCustom className={useClass.buttonItem} color="default">
                  Signatures
                </ButtonCustom>
              </NavLink>
            </li>
            <li className={`${useClass.menuItem} ${getMenuItemActive('/organization')}`}>
              <NavLink to="/organization">
                <ButtonCustom className={useClass.buttonItem} color="default">
                  Settings
                </ButtonCustom>
              </NavLink>
            </li>
          </>
        )}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
};
