import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    baseSpace: {
      marginTop: theme.spacing(4),
    },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    form: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    w100: {
      width: '100%',
      position: 'relative',
    },
  })
);
