import { createSlice } from '@reduxjs/toolkit';

interface billingAddress {
  city: string;
  country: string;
  line1: string;
  postal_code: string;
  state: string;
}

interface billingType {
  email: string;
  name: string;
  address: billingAddress;
}

interface cardType {
  brand: string;
  country: string;
  exp_year: string;
  exp_month: string;
  last4: string;
}

interface paymentType {
  id: string;
  customer: string;
  billing_details: billingType;
  card: cardType;
}

interface initialType {
  lastAction: string | null;
  billing: paymentType | null;
}

const BillingState: initialType = {
  lastAction: null,
  billing: null,
};

export const callTypesBilling = {
  list: 'list',
  action: 'action',
};

export const BillingSlice = createSlice({
  name: 'Billing Objects',
  initialState: BillingState,
  reducers: {
    loadBilling: (state: any, action) => {
      const { billing } = action.payload;
      state.billing = billing;
      state.lastAction = action.type;
    },
  },
});
