import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from '../../style';
import { GoogleConfirm } from '../modules/Public/google/GoogleConfirm';
import { User } from '../modules/Public/user';
import { GoogleSuccess } from '../modules/Public/google/GoogleSuccess';
import { GoogleError } from '../modules/Public/google/GoogleError';

const PublicPage = () => {
  return (
    <Layout isPublic>
      <Switch>
        <Route path="/public/google-success/:userId" component={GoogleSuccess} />
        <Route path="/public/google-error" component={GoogleError} />
        <Route path="/public/google-redirect" component={GoogleConfirm} />
        <Route path="/public/user/:userId/:authorizationCode" component={User} />
        <Redirect exact from="/public" to="/" />
      </Switch>
    </Layout>
  );
};

export default PublicPage;
