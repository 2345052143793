import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Button, { ButtonClassKey, ButtonProps } from '@material-ui/core/Button';
import { withStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { removeFromObject } from '../../../util';

interface Styles extends Partial<Record<ButtonClassKey, string>> {
  // focusVisible?: string;  add as many calss you wnat for type strict
  spinnerClass?: string;
  spanSpaceBetween?: string;
  span?: string;
}

interface Props extends ButtonProps {
  classes: Styles;
  spaceBetween?: boolean;
  spinner?: string | boolean;
}

export const ButtonCustom = withStyles((theme: Theme) =>
  createStyles({
    root: {
      //can put global button style here also
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'capitalize',
    },
    spinnerClass: {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(3),
    },
    span: {
      display: 'flex',
      alignItems: 'center',
    },
    spanSpaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
  })
)(({ classes, children, ...props }: Props) => {
  const theme = useTheme();
  const newProp = removeFromObject('spinner', { ...props });
  return (
    <Button
      classes={{
        root: classes.root,
      }}
      {...newProp}
    >
      <span className={props.spaceBetween ? classes.spanSpaceBetween : classes.span}>{children}</span>
      {props.spinner && (
        <CircularProgress size={theme.spacing(4)} thickness={theme.spacing(1)} className={classes.spinnerClass} />
      )}
    </Button>
  );
});
