import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
    },
    ctaButton: {
      marginBottom: theme.spacing(4),
    },
    applyButton: {
      marginLeft: theme.spacing(2),
    },
    tooltip: {
      maxHeight: theme.spacing(100),
      maxWidth: theme.spacing(70),
      background: theme.palette.common.white,
      border: `1px solid ${lighten(theme.palette.secondary.light, 0.85)}`,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey.A200,
        borderRadius: theme.spacing(4),
      },
    },
    list: {
      display: 'flex',
      alignItems: 'flex-start',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
    },
    listHeader: {
      background: `${lighten(theme.palette.secondary.light, 0.85)} !important`,
      padding: theme.spacing(4),
      fontWeight: 600,
    },
    listSubheader: {
      background: `${lighten(theme.palette.secondary.light, 0.85)} !important`,
    },
  })
);

export { useStyles };
