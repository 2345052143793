import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      //marginTop:theme.spacing(16)
    },
    subscription: {},
    billing: {
      paddingLeft: theme.spacing(4),
    },
    Body: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    heading: {
      marginBottom: theme.spacing(4),
    },
    card: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(2),
      width: '100%',
      padding: theme.spacing(8),
      position: 'relative',
    },
    cardBrand: {
      marginBottom: theme.spacing(12),
      fontSize: theme.spacing(18),
    },
    actions: {
      marginTop: theme.spacing(9),
    },
    white: {
      color: theme.palette.background.paper,
    },
    legal: {
      marginTop: theme.spacing(9),
    },
    stripe: {
      marginTop: theme.spacing(2),
    },
  })
);

export const BillingExpire = () => {
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="flex-start" className={classes.root}>
      <Grid item>
        <Typography variant="h4">
          We were unable to collect payment from you. Please contact support for assistance for now.
        </Typography>
      </Grid>
    </Grid>
  );
};
