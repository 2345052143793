import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { UserActions } from '../../../Users/UserActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
    },
    radio: {
      position: 'absolute',
      right: -theme.spacing(2),
      top: -theme.spacing(1),
    },
    title: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  })
);

export const SignatureUser = () => {
  const classes = useStyles();
  const { signature } = useSelector(
    (state: any) => ({
      signature: state.signature.active,
    }),
    shallowEqual
  );

  const handleChange = (id: any) => {
    // const newTemplate=template.filter((temp:any)=>temp.id===id)[0]
    // const newSignature={...signature,draft:{...signature.draft,template:newTemplate}}
    // dispatch(setActive({signature:newSignature}));
  };

  return (
    <Grid className={classes.container} container direction="row" justify="flex-start">
      {signature.users && signature.users.length > 0 && (
        <Grid item lg={12} className={classes.title}>
          <Typography variant="h4">List of Assigned Users</Typography>
        </Grid>
      )}
      {!signature.users ||
        (!signature.users.length && (
          <Grid item lg={12} className={classes.title}>
            <Typography variant="h4">No User Assigned</Typography>
          </Grid>
        ))}
      <Grid item lg={12}>
        <List dense>
          {signature.users.map((user: any, index: number) => (
            <ListItem key={`${index}-template`}>
              <Grid container item lg={12} onClick={() => handleChange(user.id)} alignItems="center">
                <ListItemText primary={user.name} />
                <ListItemSecondaryAction>
                  <UserActions user={user} mode="signature" signature={signature} />
                </ListItemSecondaryAction>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
