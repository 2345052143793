import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { createStyles, makeStyles, Theme, IconButton } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { HeaderLayoutMenu } from './header-menu/HeaderMenu';
import { useSideBox } from './sideBox';
import { Dropdown, ActionsDropdownMenu, UserDropdownMenu } from './dropdown';
import { ButtonCustom } from '../../components';
import { ReactComponent as PlusLight } from './svg/plusLight.svg';
import { ReactComponent as CircleUserLight } from './svg/circleUserLight.svg';
import { useGoogleGenerateAuthLinkMutation, useUserSendMutation } from '../../../api';
import { PublicSlice } from '../../../store/redux/Reducer/public';
import { toastr } from 'react-redux-toastr';

const toAbsoluteUrl = (pathname: any) => process.env.PUBLIC_URL + pathname;
// const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    header: {
      flexFlow: 'row nowrap',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(theme.breakpoints.up('lg') ? 4 : 3),
    },
    userActions: {
      fill: '#9A9AB0',
      background: 'none',
      transition: 'all 250ms',
    },
    actions: {
      background: theme.palette.primary.main,
      fill: theme.palette.common.white,
      padding: theme.spacing(3),
    },
    authorize: {
      padding: theme.spacing(32),
      minHeight: theme.spacing(36),
    },
    button: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export function HeaderLayout({ isPublic }: HeaderProps) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { loadPublic } = PublicSlice.actions;
  const { initialize, open, options } = useSideBox(); // trriger sidebox
  const { publicAccount, publicUser, publicSignature, isAuthorized, outputCopy } = useSelector(
    (state: any) => ({
      publicAccount: state.public.account,
      publicUser: state.public.user,
      publicSignature: state.public.signature,
      outputCopy: state.signature.outputCopy,
      isAuthorized: state.account.accessToken !== null && state.account.account !== null,
    }),
    shallowEqual
  );
  const [generateAuthLink] = useGoogleGenerateAuthLinkMutation();
  const [userSend] = useUserSendMutation();

  useEffect(() => {
    const options = {
      anchor: 'left',
      variant: 'persistent',
      open: false,
    };
    const observe = {
      onClose: handleOnClose,
    };
    initialize(options, observe); //initialize the sidebar component
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClose = (event: any) => {
    // if needed
  };

  const downloadHtml = () => {
    const user = publicUser;
    const file = new Blob([outputCopy], { type: 'text/html' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = user.name + '-signature.html';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const openHubspot = () => {
    window.open(`https://app.hubspot.com/settings/${publicAccount.hubspot_portal_id}/user-preferences/email`);
  };

  const copyToGmail = async () => {
    if (!publicUser.isLinked) {
      const response = await generateAuthLink({
        variables: {
          options: {
            id: publicAccount.id,
            email: publicUser.email,
            redirectUri: location.pathname,
          },
        },
      });
      return window.open(response.data!.GoogleGenerateAuthLink.url as string);
    }

    const response = await userSend({
      variables: {
        options: {
          userId: publicUser.id,
        },
      } as any,
    });
    if (!response.errors && !response.data!.UserSend.errors) {
      toastr.success('Success', 'Signature successfully added');
    } else {
      response.data!.UserSend.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
    }
  };

  const onExit = () => {
    dispatch(
      loadPublic({
        googleLink: null,
        template: null,
        user: null,
        account: null,
        company: null,
        signature: null,
      })
    );
    history.push(isAuthorized ? '/' : '/login');
  };

  return (
    // begin::Header
    <Paper className={classes.root}>
      <Container>
        <Grid container justify="space-between" alignItems="center">
          <Grid
            container
            item
            xs={publicSignature ? 4 : desktop ? 5 : 12}
            lg={publicSignature ? 4 : 8}
            justify={desktop ? 'flex-start' : 'space-between'}
            alignItems="center"
            className={classes.header}
          >
            {!desktop && (
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={open}
                  className={clsx(classes.menuButton, options.open && classes.hide)}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <Link to="/" className={classes.logo}>
                <img style={{ height: '40px' }} alt="Logo" src={toAbsoluteUrl('/media/logo/Signature-Builder-Colour.svg')} />
              </Link>
            </Grid>
            {!isPublic && desktop && (
              <Grid item>
                <HeaderLayoutMenu />
              </Grid>
            )}
          </Grid>
          {publicSignature && (
            <Grid xs={8} container justify="flex-end" spacing={2}>
              <Grid item lg="auto">
                <ButtonCustom onClick={copyToGmail} className={classes.button} color="primary" variant="outlined" fullWidth>
                  {publicUser.isLinked ? 'Copy To Gmail' : 'Login to Google'}
                </ButtonCustom>
              </Grid>
              <Grid item lg="auto">
                <CopyToClipboard text={outputCopy}>
                  <ButtonCustom onClick={openHubspot} color="primary" variant="outlined" className={classes.button}>
                    Copy to HubSpot
                  </ButtonCustom>
                </CopyToClipboard>
              </Grid>
              <Grid item lg="auto">
                <ButtonCustom onClick={downloadHtml} color="primary" variant="outlined" className={classes.button}>
                  Download HTML
                </ButtonCustom>
              </Grid>
              <Grid item lg="auto">
                <CopyToClipboard text={outputCopy}>
                  <ButtonCustom color="primary" variant="outlined" className={classes.button}>
                    Copy HTML to Clipboard
                  </ButtonCustom>
                </CopyToClipboard>
              </Grid>
              <Grid item lg="auto">
                <ButtonCustom color="primary" variant="contained" onClick={onExit}>
                  Exit
                </ButtonCustom>
              </Grid>
              {/*{publicUser.isLinked&&<Grid item lg={"auto"}>*/}
              {/*    <ButtonCustom onClick={()=>deploySignature()} color={"primary"} variant={"outlined"} className={classes.button}>Deploy Signature</ButtonCustom>*/}
              {/*</Grid>}*/}
              {/*{!publicUser.isLinked&&<Grid item lg={"auto"}>*/}
              {/*    <ButtonCustom variant={"outlined"}><LinkCustom href={publicGoogleLink as string} className={classes.link}   >*/}
              {/*        Connect Google &nbsp;<ArrowForward/>*/}
              {/*    </LinkCustom></ButtonCustom>*/}
              {/*</Grid>}*/}
            </Grid>
          )}
          {!isPublic && desktop && (
            <Grid container item xs={7} lg={4} justify="flex-end">
              <Grid item>
                <Dropdown icon={<PlusLight width={theme.spacing(10)} height={theme.spacing(10)} className={classes.actions} />}>
                  <ActionsDropdownMenu />
                </Dropdown>
              </Grid>
              <Grid item>
                <Dropdown
                  icon={<CircleUserLight width={theme.spacing(10)} height={theme.spacing(10)} className={classes.userActions} />}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <UserDropdownMenu />
                </Dropdown>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </Paper>
    // end::Header
  );
}

type HeaderProps = {
  isPublic: boolean;
};
