import { createMuiTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core';
import light from '@material-ui/core/colors/lightBlue';
import { themeBase, themeOptions } from './_theme';
const theme = createMuiTheme({
  ...themeOptions, //provide base
  palette: {
    type: 'light',
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    primary: {
      main: '#5541D7',
    },
    secondary: {
      main: '#5541D7',
    },
    error: {
      main: '#e57373',
    },
    neutral: light,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    ...themeBase.overrides,
    MuiTextField: {},
    MuiIconButton: {
      sizeSmall: {
        // Adjust spacing to reach minimal touch target hitbox
        marginLeft: 4,
        marginRight: 4,
        padding: 6,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: themeBase.spacing(4),
        marginBottom: themeBase.spacing(2),
      },
    },
    MuiButton: {
      outlinedPrimary: {
        borderWidth: '2px!important',
      },
      outlined: {
        borderWidth: '2px!important',
      },
      /*sizeLarge: {
        marginTop:themeBase.spacing(4),
        marginBottom:themeBase.spacing(2),
        paddingTop:themeBase.spacing(4),
        paddingBottom:themeBase.spacing(4),
        [themeBase.breakpoints.up('lg')]: {
            paddingLeft:themeBase.spacing(8),
            paddingRight:themeBase.spacing(8),
        },
        [themeBase.breakpoints.down('lg')]: {
            paddingLeft:themeBase.spacing(6),
            paddingRight:themeBase.spacing(6),
        }
    },*/
    },
    MuiTab: {
      root: {
        minWidth: '120px!important',
      },
      fullWidth: {
        maxWidth: 120,
        minHeight: 60,
      },
      wrapper: {
        textTransform: 'capitalize',
      },
    },
  },
} as ThemeOptions);

export const LightTheme = responsiveFontSizes(theme);
