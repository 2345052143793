import { createSlice } from '@reduxjs/toolkit';

interface CursorType {
  startCursor: string | null;
  endCursor: string | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

interface initialType {
  lastAction: string | null;
  users: CursorType;
}

const CursorState: initialType = {
  lastAction: null,
  users: {
    startCursor: null,
    endCursor: null,
    hasPreviousPage: false,
    hasNextPage: false,
  },
};

export const callTypesCursor = {
  list: 'list',
  action: 'action',
};

export const CursorSlice = createSlice({
  name: 'Cursor Objects',
  initialState: CursorState,
  reducers: {
    updateUserCursor: (state: any, action) => {
      const { users } = action.payload; //Put accessToken user
      state.users = users;
    },
  },
});
