import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 180,
    },
    mode: {
      padding: theme.spacing(4),
    },
    menuItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    signOut: {
      padding: '0.75rem 1.25rem',
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    link: {
      color: theme.palette.background.paper,
    },
  })
);
