import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { TextFieldCustom, ButtonCustom } from '../../../../../style';
import { SignatureSlice } from '../../../../../store';
import { useStyles } from './styles';

export const EmbedCode = () => {
  const { signature, account } = useSelector(
    (state: any) => ({
      signature: state.signature.active,
      account: state.account.account,
    }),
    shallowEqual
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setActive } = SignatureSlice.actions;
  const [error, setError] = useState(false);
  const [embed, setEmbed] = useState(signature.draft.embedCode);
  const [embedTwo, setEmbedTwo] = useState(signature.draft.embedCodeTwo);

  useEffect(() => {
    //when user reload the browser
    setEmbedTwo(signature.draft.embedCodeTwo);
    setEmbed(signature.draft.embedCode);
  }, [signature.draft.embedCode, signature.draft.embedCodeTwo]);

  const handlePaste = (evt: any) => {
    setEmbed(evt.target.value);
    setError(false);
  };
  const handlePasteTwo = (evt: any) => {
    setEmbedTwo(evt.target.value);
    setError(false);
  };
  function clearCta() {
    setEmbed('');
    setEmbedTwo('');
    setError(false);
  }

  function openHubspotCTAs() {
    window.open(`https://app.hubspot.com/ctas/${account.hubspot_portal_id}`);
  }

  function applyCta() {
    if (!embed.length) {
      const newSignature = { ...signature, draft: { ...signature.draft, embedCode: embed, embedCodeTwo: embedTwo } };
      dispatch(setActive({ signature: newSignature }));
    } else {
      if (embed.includes('<!--HubSpot Call-to-Action Code -->') && embed.includes('<!-- end HubSpot Call-to-Action Code -->')) {
        setError(false);
        let newSignature = { ...signature, draft: { ...signature.draft, embedCode: embed, embedCodeTwo: embedTwo } };
        dispatch(setActive({ signature: newSignature }));
      } else {
        setError(true);
      }
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item lg={4}>
        <ButtonCustom className={classes.ctaButton} onClick={() => openHubspotCTAs()} variant="outlined" color="primary">
          View CTAs
        </ButtonCustom>
      </Grid>
      <Grid item lg={12}>
        <Typography gutterBottom color="textPrimary" component="h5" variant="h5">
          Please paste your HubSpot CTA Embed Code below to embed a CTA in your email signature
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <TextFieldCustom
          fullWidth
          id="embed-1"
          label="HubSpot CTA Embed Code"
          multiline
          rows={4}
          value={embed}
          placeholder="Paste Your CTA Embed Code"
          variant="outlined"
          onChange={handlePaste}
          error={error}
          helperText={error && 'Invalid Embed Code'}
        />
      </Grid>
      <Grid item lg={12}>
        <TextFieldCustom
          fullWidth
          id="embed-2"
          label="HubSpot CTA Embed Code 2"
          multiline
          rows={4}
          value={embedTwo}
          placeholder="Paste Your Second CTA Embed Code"
          variant="outlined"
          onChange={handlePasteTwo}
          error={error}
          helperText={error && 'Invalid Embed Code'}
        />
      </Grid>

      <Grid container justify="flex-end">
        <ButtonCustom onClick={clearCta} variant="outlined" color="primary">
          Clear
        </ButtonCustom>
        <ButtonCustom onClick={applyCta} className={classes.applyButton} variant="contained" color="primary">
          Apply
        </ButtonCustom>
      </Grid>
    </Grid>
  );
};
