import { BreakpointOverrides } from '@material-ui/core/styles/createBreakpoints';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { createMuiTheme, ThemeOptions } from '@material-ui/core';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    ml: true;
    xl: true;
    tablet: true; // adds the `tablet` breakpoint
    laptop: true;
    desktop: true;
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}
declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    Breakpoints: BreakpointOverrides;
    Palette: PaletteOptions;
  }
}
const pxToRem = (value: number) => {
  return `${value / 16}rem`;
};

export const themeOptions = {
  // shadows:Array(25).fill("none") as Shadows,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      ml: 1440,
      xl: 1920,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
  // direction: "rtl",
  typography: {
    fontFamily: ['Inter'].join(','),
    htmlFontSize: 16,

    h1: {
      fontSize: pxToRem(60),
      lineHeight: 1.13,
      fontWeight: 700,
    },
    h2: {
      fontSize: pxToRem(46),
      lineHeight: 1.26,
      fontWeight: 700,
    },
    h3: {
      fontSize: pxToRem(34),
      lineHeight: 1.29,
      fontWeight: 700,
    },
    h4: {
      fontSize: pxToRem(20),
      lineHeight: 1.4,
      fontWeight: 700,
    },
    h5: {
      fontSize: pxToRem(16),
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h6: {
      fontSize: pxToRem(16),
      lineHeight: 1.42,
      fontWeight: 400,
    },
    button: {
      fontSize: `${pxToRem(13)} !important`,
      lineHeight: 1.5,
      fontWeight: 400,
    },
    body1: {
      fontSize: pxToRem(14),
      lineHeight: 1.42,
      fontWeight: 400,
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 1.42,
      fontWeight: 400,
    },
  },
  spacing: 4,
  palette: {},
  /**
   * @see https://material-ui.com/customization/globals/#default-props
   */
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      disableRipple: false, // No more ripple, on the whole application 💣!
    },
    MuiPopover: {
      elevation: 2,
    },
    MuiButton: {
      size: 'large',
    },
    MuiFilledInput: {
      margin: 'none',
    },
    MuiFormControl: {
      margin: 'normal',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'none',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'normal',
    },
    MuiToolbar: {
      variant: 'dense',
    },
  },
  overrides: {
    MuiInputLabel: {
      shrink: {
        transform: 'translate(14px, -12px) scale(0.75) !important',
      },
    },
  },
};

export const themeBase = createMuiTheme({
  ...themeOptions,
} as ThemeOptions);
