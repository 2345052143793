import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, createStyles, Grid, makeStyles, Menu, MenuItem, Slider, Switch, Theme, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import { SignatureSlice } from '../../../../../store';
import { SelectCustom } from '../../../../../style';

const SliderShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const CustomSlider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      height: 5,
      padding: '15px 0',
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: theme.palette.primary.main,
      boxShadow: SliderShadow,
      marginTop: -12,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: SliderShadow,
        },
      },
    },
    valueLabel: {
      left: 'calc(-50% + 12px)',
      top: -15,
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
    track: {
      height: 5,
    },
    rail: {
      height: 5,
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    mark: {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      marginTop: -3,
    },
    markActive: {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  })
)(Slider);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signatureControl: {
      paddingLeft: theme.spacing(4),
    },
    slider: {
      minWidth: 200,
    },
    control: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    select: {
      margin: 0,
      '& .MuiSelect-select': {
        minWidth: 156,
      },
    },
    component: {
      '& .MuiFormControl-root': {
        margin: '0!important',
      },
    },
    picker: {
      width: 35,
      height: 35,
      border: '1px solid #666666',
      cursor: 'pointer',
    },
    colors: {
      minWidth: 200,
    },
  })
);

const fontStyle = [
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Italic',
    value: 'italic',
  },
  {
    label: 'Oblique',
    value: 'oblique',
  },
];

const iconStyle = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Color',
    value: 'color',
  },
];

const fontFamily = [
  {
    label: 'Anonymous, Courier New',
    value: "'Anonymous', 'Courier New', Courier, monospace",
  },
  {
    label: 'Helvetica, Arial',
    value: "'Helvetica Neue',Helvetica,Arial,sans-serif",
  },
  {
    label: 'Times New Roman',
    value: "'Times New Roman', serif",
  },
  {
    label: 'Tahoma, Verdana',
    value: 'Tahoma,Verdana,Arial,Helvetica,sans-serif',
  },
  {
    label: 'Comic Sans, Chalkboard',
    value: "'Comic Sans MS', 'Chalkboard', sans-serif",
  },
  {
    label: 'Sans Serif',
    value: 'sans-serif',
  },
];

const dividerStyle = [
  {
    label: 'Solid',
    value: 'solid',
  },
  {
    label: 'Dotted',
    value: 'dotted',
  },
  {
    label: 'Double',
    value: 'double',
  },
  {
    label: 'Dashed',
    value: 'dashed',
  },
];

const entityType = [
  {
    label: ' Space',
    value: ' ',
  },
  {
    label: '│ Pipe',
    value: '│',
  },
  {
    label: '─ Bar',
    value: '─',
  },
  {
    label: '* Flake',
    value: '*',
  },
  {
    label: '• Bullet',
    value: '•',
  },
  {
    label: ', Comma',
    value: ',',
  },
  {
    label: '< AngleLeft',
    value: '<',
  },
  {
    label: '> AngleRight',
    value: '>',
  },
  {
    label: '✶ Star',
    value: '✶',
  },
];

export const Control = ({ level1, level2, level3 = '', range, label, component }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { signature } = useSelector(
    (state: any) => ({
      signature: state.signature.active,
    }),
    shallowEqual
  );
  const { setActive, setHasChanges } = SignatureSlice.actions;
  const [anchorEl, setAnchorEl] = useState(null);

  const getPropsValue = () => {
    const properties = signature.draft.properties;
    if (properties[level1]) {
      if (level1 && level2) {
        if (level3) {
          return properties[level1][level2][level3];
        } else {
          return properties[level1][level2];
        }
      }
    } else {
      if (level2 === 'color') {
        return '#444444';
      }
      if (level2 === 'type') {
        return 'space';
      }
      return null;
    }
  };

  const updateStore = (event: any) => {
    let value: any;
    if (component === 'select') {
      value = event.target.value;
    } else {
      value = event;
    }
    let properties = signature.draft.properties;
    if (level1 && level2) {
      if (level3) {
        properties = {
          ...properties,
          [level1]: { ...properties[level1], [level2]: { ...properties[level1][level2], [level3]: value } },
        };
      } else {
        properties = { ...properties, [level1]: { ...properties[level1], [level2]: value } };
      }
    }
    const newSignature = { ...signature, draft: { ...signature.draft, properties } };
    dispatch(setActive({ signature: newSignature }));
    dispatch(setHasChanges({ change: true }));
  };

  const provideOptions: any = () => {
    if (level2 === 'fontFamily' || level3 === 'fontFamily') {
      return fontFamily;
    } else if (level2 === 'fontStyle' || level3 === 'fontStyle') {
      return fontStyle;
    } else if (level2 === 'style' || level3 === 'style' || level2 === 'borderStyle') {
      return dividerStyle;
    } else if (level2 === 'type' || level3 === 'type') {
      return entityType;
    } else if (level2 === 'iconStyle') {
      return iconStyle;
    } else {
      return fontFamily;
    }
  };

  const chooseComponent = () => {
    switch (component) {
      case 'color': {
        return (
          <Box className={classes.colors}>
            <Box
              className={classes.picker}
              style={{ backgroundColor: getPropsValue() }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <SketchPicker
                disableAlpha
                presetColors={[
                  '#000000',
                  '#f44336',
                  '#e91e63',
                  '#9c27b0',
                  '#673ab7',
                  '#3f51b5',
                  '#2196f3',
                  '#03a9f4',
                  '#00bcd4',
                  '#009688',
                  '#4caf50',
                  '#8bc34a',
                  '#cddc39',
                  '#ffeb3b',
                  '#ffc107',
                  '#ff9800',
                  '#ff5722',
                  '#795548',
                  '#607d8b',
                ]}
                color={getPropsValue() ? getPropsValue() : '#444444'}
                onChangeComplete={(color: any) => updateStore(color ? color.hex : '#444444')}
              />
            </Menu>
          </Box>
        );
      }
      case 'slider': {
        return (
          <CustomSlider
            className={classes.slider}
            value={getPropsValue()}
            getAriaValueText={() => {
              return getPropsValue();
            }}
            aria-labelledby={`slider-${level1}-${level2}`}
            step={range.step}
            min={range.min}
            max={range.max}
            valueLabelDisplay="off"
            onChange={(event, value) => updateStore(value)}
          />
        );
      }
      case 'switch': {
        return <Switch checked={getPropsValue()} onChange={(evt, value) => updateStore(value)} name="checkedB" color="primary" />;
      }
      case 'select': {
        return (
          <SelectCustom
            variant="outlined"
            className={classes.select}
            value={getPropsValue()}
            onChange={(event, value) => updateStore(event)}
          >
            {provideOptions().map((element: { value: string; label: string }) => {
              if (level2 === 'fontFamily' || level3 === 'fontFamily') {
                return (
                  <MenuItem key={element.value} value={element.value}>
                    <Box fontFamily={element.value}>{element.label}</Box>
                  </MenuItem>
                );
              } else {
                return <MenuItem key={element.value} value={element.value}>{element.label}</MenuItem>;
              }
            })}
          </SelectCustom>
        );
      }
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container item className={classes.control} alignItems="center" justify="space-between">
      <Grid item>
        <Typography color="textPrimary" variant="h6" component="h6">
          {label}
        </Typography>
      </Grid>
      <Grid item className={classes.component}>
        {chooseComponent()}
      </Grid>
    </Grid>
  );
};
