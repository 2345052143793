import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Link, { LinkProps, LinkClassKey } from '@material-ui/core/Link';

interface Styles extends Partial<Record<LinkClassKey, string>> {
  // focusVisible?: string;  add as many calss you wnat for type strict
}
interface Props extends LinkProps {
  classes: Styles;
}

export const LinkCustom = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      transition: theme.transitions.create(['color']),
      //can put global button style here also
      '& > * + *': {
        marginLeft: theme.spacing(2),
        textDecoration: 'none',
      },
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
      alignSelf: 'center'
    },
  })
)(({ classes, children, ...props }: Props) => {
  return (
    <Link
      classes={{
        root: classes.root,
      }}
      {...props}
    >
      {children}
    </Link>
  );
});
