import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { createStyles, Dialog, Grid, makeStyles, Theme, Typography, Box } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { ButtonCustom, TextFieldCustom } from '../../../style';
import { AlertBox, toAbsoluteUrl } from '../../../util';
import { useSubscriptionPostCreateMutation, useSubscriptionUpgradeMutation } from '../../../api';
import { SubscriptionSlice, AccountSlice, BillingSlice } from '../../../store';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
      marginTop: theme.spacing(16),
    },
    card: {
      width: '100%',
      height: 40,
      '& .StripeElement': {
        width: '100%',
        padding: '10px',
        border: '1px solid #666666',
        borderRadius: 4,
      },
    },
    errorCard: {
      color: '#F64E60',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    cardContainer: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      backgroundColor: lighten(theme.palette.secondary.light, 0.99),
      borderRadius: 8,
    },
    headContainer: {
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      backgroundColor: lighten(theme.palette.secondary.light, 1),
      borderRadius: 8,
    },
    coupon: {
      marginBottom: theme.spacing(4),
    },
    paymentField: {
      fontFamily: 'sans-serif',
      fontSize: '16px',
      fontWeight: 400,
      '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
        fontFamily: 'sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        color: '#666666',
      },
    },
    marginBottom: {
      marginBottom: theme.spacing(6),
    },
    primary: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    stripe: {},
  })
);

const iframeStyles = {
  base: {
    color: '#666666',
    fontSize: '16px',
    iconColor: '#666666',
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    marginTop: '15px',
  },
  invalid: {
    iconColor: '#F64E60',
    color: '#F64E60',
  },
  complete: {
    iconColor: '#4A7DFF',
  },
};

const cardElementOpts = {
  iconStyle: 'solid',
  style: iframeStyles,
  hidePostalCode: true,
};

function calcPrice(quant: any) {
  const first50Rate = 1.8;
  const fiftyTo500Rate = 1.2;
  const above500Rate = 0.7;

  let qty = parseInt(quant);
  let total = 0;

  if (qty > 50) {
    total += 50 * first50Rate;
    if (qty > 500) {
      total += 450 * fiftyTo500Rate;
      total += (qty - 500) * above500Rate;
    } else {
      total += (qty - 50) * fiftyTo500Rate;
    }
  } else {
    total = qty * first50Rate;
  }
  return Math.round(total * 100) / 100;
}

export const Upgrade = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements: any = useElements();
  const { account } = useSelector(
    (state: any) => ({
      account: state.account.account,
    }),
    shallowEqual
  );
  const { loadAccount } = AccountSlice.actions;
  const { loadSubscription } = SubscriptionSlice.actions;
  const { loadBilling } = BillingSlice.actions;
  const [subscriptionPostCreate] = useSubscriptionPostCreateMutation();
  const [subscriptionUpgrade] = useSubscriptionUpgradeMutation();
  const [quantity, setQuantity] = useState(5);
  const [coupon, setCoupon] = useState('');
  const [billing, setBilling] = useState({ name: account.name, email: account.email });
  const [price, setPrice] = useState(9); //ratio per user
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePaymentThatRequiresAction = async (subscription: any) => {
    if (subscription && subscription.status === 'active') {
      return { status: 'success', data: { subscription: subscription } };
    } else if (subscription.invoiceStatus === 'requires_action') {
      if (stripe) {
        const response = await stripe.confirmCardPayment(subscription.latest_invoice, {
          payment_method: subscription.paymentMethodId,
        });
        if (response.error) {
          subscription.invoiceStatus = response!.error!.payment_intent!.status;
          return { status: 'error', data: { subscription: subscription }, reason: response!.error!.code };
        } else {
          if (response!.paymentIntent!.status === 'succeeded') {
            subscription.status = 'active';
            subscription.invoiceStatus = 'succeeded';
            const responseSubscription = await subscriptionPostCreate({
              variables: {
                options: {
                  customerId: subscription.customerId,
                  coupons: subscription.coupons,
                  renewDate: subscription.renewDate.toString(),
                  status: 'active',
                  invoiceStatus: subscription.invoiceStatus,
                  latest_invoice: subscription.latest_invoice,
                  invoiceId: subscription.invoiceId,
                  quantity: subscription.quantity.toString(),
                  subscriptionPlan: subscription.subscriptionPlan,
                  paymentMethodId: subscription.paymentMethodId,
                  subscriptionId: subscription.subscriptionId,
                  productId: subscription.productId,
                  price: subscription.price,
                },
              } as any,
            });
            if (!responseSubscription.errors && !responseSubscription.data!.subscriptionPostCreate.errors) {
              return { status: 'updated', response: responseSubscription.data!.subscriptionPostCreate };
            } else {
              //trigger cancel subscriptions ,need to build
              //needs cancelSubscrtion mutatuion with subscription id
              return { status: 'error', reason: 'system Issue' };
            }
          }

          return {
            status: 'error',
            data: { subscription: subscription },
            reason: response!.paymentIntent!.status,
          };
        }
      }

      return { status: 'error', data: { subscription: subscription }, reason: 'No Stripe Connection' };
    } else if (subscription.invoiceStatus === 'requires_payment_method') {
      return { status: 'error', data: { subscription: subscription }, reason: 'Card Declined' };
    } else {
      // No customer action needed.
      return { status: 'success', data: { subscription: subscription } };
    }
  };

  const handleSubscription = async () => {
    if (!billing.name) {
      return toastr.error('Error', "Enter cardholder's name");
    }
    enableLoading();
    const cardElement = elements.getElement('card');
    const paymentMethodReq: any = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billing,
    });
    if (paymentMethodReq.error) {
      toastr.error('Error', paymentMethodReq.error.message);
      disableLoading();
      return;
    } else {
      if (quantity < 5) {
        setQuantity(5);
      }
      const response = await subscriptionUpgrade({
        variables: {
          options: {
            type: 'premium',
            quantity: quantity.toString(),
            coupon: coupon.trim(),
            paymentMethodId: paymentMethodReq.paymentMethod.id,
          },
        } as any,
      });
      if (!response.errors && !response.data!.subscriptionUpgrade.errors) {
        let responseCheck: any = await handlePaymentThatRequiresAction(response.data!.subscriptionUpgrade.subscription);
        if (responseCheck.status === 'error') {
          toastr.error('Error', responseCheck.reason ? responseCheck.reason : 'Card was declined, please try again');
          disableLoading();
        } else if (responseCheck.status === 'updated') {
          const response = responseCheck.response;
          toastr.success('Success', 'Thank you for purchasing');
          disableLoading();
          dispatch(loadAccount({ account: response.account }));
          dispatch(loadSubscription({ subscription: response.subscription }));
          dispatch(loadBilling({ billing: response.billing }));
          handleClose();
        } else {
          toastr.success('Success', 'Thank you for purchasing');
          disableLoading();
          dispatch(loadAccount({ account: response.data!.subscriptionUpgrade.account }));
          dispatch(loadSubscription({ subscription: response.data!.subscriptionUpgrade.subscription }));
          dispatch(loadBilling({ billing: response.data!.subscriptionUpgrade.billing }));
          handleClose();
        }
      } else {
        response.data!.subscriptionUpgrade.errors!.forEach((error: any) => {
          toastr.error('Error', error.message);
        });
        disableLoading();
      }
    }
  };

  const handleChange = (event: any) => {
    try {
      let quantity: number;
      if (!event.target.value) {
        quantity = event.target.value;
      } else {
        quantity = parseInt(event.target.value);
      }
      let quantToCalc: number;
      if (quantity >= 5) {
        setQuantity(quantity);
        quantToCalc = quantity;
      } else {
        setQuantity(quantity);
        quantToCalc = 5;
      }
      setPrice(calcPrice(quantToCalc));
    } catch (err) {
      console.error('Quantity not a number');
    }
  };

  const handleCardDetailsChange = (ev: any) => {
    if (ev.error) {
      toastr.error('Error', ev.error.message);
    }
  };
  // const handleOn = async () => {
  //   const cardElement = elements.getElement('card');
  //   const paymentMethodReq: any = await stripe!.createPaymentMethod({
  //     type: 'card',
  //     card: cardElement,
  //     billing_details: billing,
  //   });
  // };
  const handleBillingChange = async (evt: any) => {
    const name = evt.target.value;
    setBilling({ name: name, email: account.email });
  };
  const handleCouponChange = async (evt: any) => {
    const cp = evt.target.value;
    setCoupon(cp);
  };

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonCustom variant={props.type ? props.type : 'contained'} color="primary" onClick={handleClickOpen}>
        {props.name ? props.name : 'Buy More Users'}
      </ButtonCustom>
      <Dialog
        maxWidth="sm"
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid item container lg={12} justify="center" alignItems="center">
          <Grid container item lg={12} className={classes.headContainer} justify="center" alignItems="center">
            <Grid className={classes.marginBottom} item container lg={12} justify="flex-start" alignItems="center">
              <Typography variant="h3" align="left" component="h3">
                Upgrade
              </Typography>
            </Grid>
            <Grid className={classes.marginBottom} item container lg={12} justify="flex-start" alignItems="center">
              <Typography variant="h6" align="left" component="h6">
                Minimum purchase <span className={classes.primary}>5 user</span> license{' '}
                <span className={classes.primary}>USD$9</span> per month.
                <br />
                <span className={classes.primary}>6-50</span> Licenses are <span className={classes.primary}>USD$1.80</span> per
                unit per month.
                <br />
                <span className={classes.primary}>51-500</span> Licenses are <span className={classes.primary}>USD$1.20</span> per
                unit per month.
                <br />
                <span className={classes.primary}>501+</span> Licenses are <span className={classes.primary}>USD$0.70</span> per
                unit per month.
              </Typography>
            </Grid>
            <Grid className={classes.marginBottom} item lg={12}>
              <Typography variant="h3" align="center" component="h3">
                ${price} / month
              </Typography>
              <Typography variant="h6" align="center" component="h6">
                Per {quantity} user pack
              </Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} className={classes.cardContainer} justify="center" alignItems="center">
            <Grid item lg={12}>
              <AlertBox container="onboard-subscription" />
            </Grid>
            <Grid item lg={12} md={6} sm={12} container justify="center" alignItems="center">
              <Grid container item lg={12}>
                <TextFieldCustom
                  fullWidth
                  variant="outlined"
                  label="User Licenses"
                  id="users"
                  name="users"
                  value={quantity}
                  onChange={(evt) => handleChange(evt)}
                  onBlur={(evt) => handleChange(evt)}
                />
              </Grid>

              <Grid container item lg={12}>
                <TextFieldCustom
                  fullWidth
                  id="name"
                  name="name"
                  variant="outlined"
                  color="primary"
                  label="Cardholder name"
                  value={billing.name}
                  className={classes.paymentField}
                  onChange={(evt) => handleBillingChange(evt)}
                />
              </Grid>
              <Grid container item lg={12}>
                <Box className={classes.card}>
                  <CardElement options={cardElementOpts as any} onChange={handleCardDetailsChange} />
                </Box>
              </Grid>
              <Grid container item lg={12} className={classes.coupon}>
                <TextFieldCustom
                  fullWidth
                  id="coupon"
                  name="coupon"
                  variant="outlined"
                  color="primary"
                  label="Coupon"
                  value={coupon}
                  className={classes.paymentField}
                  onChange={(evt) => handleCouponChange(evt)}
                />
              </Grid>
              {/* begin: Alert */}

              {/* end: Alert */}
              <Grid container item xs={12} direction="row" justify="space-between" alignItems="center">
                <Grid className={classes.stripe} container item xs={3} direction="row" justify="center" alignItems="center">
                  <img width="95%" src={toAbsoluteUrl('/media/logo/stripe.svg')} alt="" />
                </Grid>
                <Grid item container xs={9} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                  <Grid item>
                    <ButtonCustom disabled={loading} size="large" onClick={handleClose} color="primary">
                      Cancel
                    </ButtonCustom>
                  </Grid>
                  <Grid item>
                    <ButtonCustom
                      disabled={loading || quantity < 5}
                      size="large"
                      onClick={handleSubscription}
                      spinner={loading ? loading : undefined}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Pay USD${price} Per Month
                    </ButtonCustom>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
