import React, { useState } from 'react';
import { ButtonCustom } from '../../../../../style';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Box } from '@material-ui/core';

export const SignaturePublishConfirmation = (props: any) => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
    props.handlePublish(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <ButtonCustom
        disabled={props.loadingPublish}
        spinner={props.loadingPublish}
        variant="outlined"
        color="primary"
        onClick={handleOpen}
      >
        Publish
      </ButtonCustom>
      <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" aria-labelledby="confirmation-dialog-publish" open={open}>
        <DialogTitle id="confirmation-dialog-title">Publish this signature?</DialogTitle>
        <DialogContent dividers>
          {!!props.numUsers && (
            <Typography>
              This will update the signatures for {props.numUsers} user{!!props.numUsers ? 's' : ''}, are you sure you want to
              proceed?
            </Typography>
          )}
          {!props.numUsers && <Typography>This will publish the signature, are you sure you want to proceed?</Typography>}
        </DialogContent>
        <DialogActions>
          <ButtonCustom autoFocus onClick={handleCancel} color="primary">
            Cancel
          </ButtonCustom>
          <ButtonCustom onClick={handleOk} color="primary">
            Publish
          </ButtonCustom>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
