import axios from 'axios';
import {validateAccesstoken} from "./setupToken";
const discardValidationUrl=["account/refresh_token"]; //discard link list
// const ashoksLocalhost = 'http://localhost:4000';
// const liveServer = 'https://api.signaturebildr.com';

// const thisServer = process.env.NODE_ENV === 'production'? liveServer: ashoksLocalhost;
const thisServer = process.env.REACT_APP_API_URL;

export const axiosClient = axios.create({
    baseURL: thisServer,
    responseType: "json",
    withCredentials:true
});

axiosClient.interceptors.request.use(async config => {
    // log a message before any HTTP request is sent
    config.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    if(!discardValidationUrl.includes(config.url!)){ //discard the not authorised link
        config.headers=await validateAccesstoken(config.headers);
    }

    return config;
},error => Promise.reject(error));

axiosClient.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axiosClient(originalRequest);
    }
    return Promise.reject(error);
});




export const setupAxios=(axios:any, store:any)=>{
    //this function get the token form store for puting it in every axious request . thats all
  axios.interceptors.request.use(
      (config:any) => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
      (err:any) => Promise.reject(err)
  );
}
