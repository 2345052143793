import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createStyles, Grid, makeStyles, Theme, Typography, Paper, Box } from '@material-ui/core';
import { EditOutlined as EditOutlinedIcon, AddCircle as AddCircleIcon, Backspace as BackspaceIcon } from '@material-ui/icons';
import * as Yup from 'yup';
import { SignatureDisplay } from '../SignatureDisplay';
import { SignatureAdd } from '../SignatureAdd';
import { ButtonCustom } from '../../../../style';
import { useSignatureRemoveMutation } from '../../../../api';
import { SignatureSlice, UsersSlice } from '../../../../store';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(4),
    },
    container: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
    },
    signaturePreview: {
      border: '1px solid #E1E1FB',
      padding: theme.spacing(4),
    },
    signatureDetailsPreview: {
      paddingLeft: theme.spacing(6),
    },
    editSignature: {
      width: '100%',
    },
    header: {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(6),
    },
    paper: {
      minHeight: theme.spacing(32),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '30px',
    },
    signatureCard: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      gap: theme.spacing(7),
    },
    btnWithCustomPadding: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2) - 2,
      paddingBottom: theme.spacing(2) - 2,
    },
  })
);

const RegistrationSchema = Yup.object({
  campaign: Yup.string().min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters'),
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    //  .matches(/^[[A-Za-z ]+$/, "Invalid character")
    .required('Signature Name is Required'),
  template: Yup.string().min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters').required('Choose template is Required'),
}).defined();
type registrationType = Yup.InferType<typeof RegistrationSchema>; //Type

export const SignatureList = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { account, template, signatures } = useSelector(
    (state: any) => ({
      account: state.account.account,
      template: state.template.templateList,
      signatures: state.signature.signatureItems,
    }),
    shallowEqual
  );
  const { setActive } = SignatureSlice.actions;
  const { removeSignature } = SignatureSlice.actions;
  const { removeSignatureUser } = UsersSlice.actions;
  const [signatureRemove] = useSignatureRemoveMutation();
  const [loadingRemove, setLoadingRemove] = useState([]);

  const provideTemplateHtml = (id: string) => {
    return template.filter((templ: any) => templ.id === id)[0].html;
  };
  const handleEdit = (signature: any) => {
    dispatch(setActive({ signature: signature }));
    history.push('/signature/editor/' + signature.id);
  };
  const handleAssign = (signature: any) => {
    history.push('/users/' + signature.id);
  };

  const handleDelete = async (signature: any) => {
    const removes: any = [...loadingRemove, signature.id];
    setLoadingRemove(removes);
    const signatureId = signature.id;

    const response = await signatureRemove({
      variables: {
        options: { signature: signatureId },
      } as any,
    });
    if (!response.errors && !response.data!.signatureRemove.errors) {
      dispatch(removeSignature({ signature: signature }));
      dispatch(removeSignatureUser({ signature: signature }));
      dispatch(setActive({ signature: null }));
      toastr.success('Success', 'Signature successfully removed');
    } else {
      response.data!.signatureRemove.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
    }
    const newRemoves: any = loadingRemove
      .map((loading: string) => {
        if (loading !== signature.id) {
          return loading;
        }
        return null;
      })
      .filter((val) => !!val);
    setLoadingRemove(newRemoves);
  };

  return (
    <Grid container justify="center">
      <Grid container className={classes.root} item justify="space-between" alignItems="center" lg={12}>
        <Grid container className={classes.header} item justify="space-between" alignItems="center" lg={12}>
          <Grid item>
            <Typography component="h4" variant="h4">
              Signatures
            </Typography>
          </Grid>
        </Grid>
        {signatures && signatures.length > 0 && (
          <Grid item container>
            {signatures.map((signature: any, index: number) => {
              const { draft, hasDraft } = signature;
              return (
                <Fragment key={`${index}-signature`}>
                  <Grid className={classes.container} direction="column" item container>
                    <Grid item>
                      <Box pb={4}>
                        <Typography color="textPrimary" component="h4" variant="h4">
                          {signature.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item container>
                      <Grid lg={7} item className={classes.signaturePreview}>
                        <SignatureDisplay
                          meetingLinkImg={signature.meetingLinkImg}
                          embedCode={signature.embedCode}
                          embedCodeTwo={signature.embedCodeTwo}
                          customCode={signature.customCode}
                          template={provideTemplateHtml(signature.template.id)}
                          property={signature.properties}
                          isPreviewMeetingLink
                        />
                      </Grid>
                      <Grid
                        lg={5}
                        item
                        container
                        direction="column"
                        className={classes.signatureDetailsPreview}
                        alignItems="flex-start"
                        justify="space-between"
                        spacing={2}
                      >
                        <Grid container item direction="column" alignItems="flex-start" justify="flex-start">
                          <Grid container item justify="space-between" alignItems="center" spacing={3}>
                            <Grid item xs={4}>
                              <ButtonCustom
                                onClick={() => handleEdit(signature)}
                                className={classes.btnWithCustomPadding}
                                color="primary"
                                variant="outlined"
                                fullWidth
                              >
                                <EditOutlinedIcon /> &nbsp; Edit Signature
                              </ButtonCustom>
                            </Grid>
                            <Grid item xs={4}>
                              <ButtonCustom
                                onClick={() => handleAssign(signature)}
                                className={classes.btnWithCustomPadding}
                                color="primary"
                                variant="outlined"
                                fullWidth
                              >
                                <AddCircleIcon /> &nbsp; Assign users
                              </ButtonCustom>
                            </Grid>
                            <Grid item xs={4}>
                              <ButtonCustom
                                spinner={loadingRemove.filter((id: string) => id === signature.id).length > 0}
                                disabled={loadingRemove.filter((id: string) => id === signature.id).length > 0}
                                onClick={() => handleDelete(signature)}
                                className={classes.btnWithCustomPadding}
                                color="primary"
                                variant="outlined"
                                fullWidth
                              >
                                <BackspaceIcon /> &nbsp; Delete
                              </ButtonCustom>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!!draft && hasDraft && (
                    <Grid className={classes.container} key={`${index}-signature`} direction="column" item container>
                      <Grid item>
                        <Box pb={4}>
                          <Typography color="textPrimary" component="h4" variant="h4">
                            {draft.name} [Draft]
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item container>
                        <Grid lg={7} item className={classes.signaturePreview}>
                          <SignatureDisplay
                            meetingLinkImg={draft.meetingLinkImg}
                            embedCode={draft.embedCode}
                            embedCodeTwo={draft.embedCodeTwo}
                            customCode={draft.customCode}
                            template={provideTemplateHtml(draft.template.id)}
                            property={draft.properties}
                            isPreviewMeetingLink
                          />
                        </Grid>
                        <Grid
                          lg={5}
                          item
                          container
                          direction="column"
                          className={classes.signatureDetailsPreview}
                          alignItems="flex-start"
                          justify="space-between"
                          spacing={2}
                        >
                          <Typography variant="body1" component="p">
                            This is a draft of a signature {signature.name}. Edit and Publish the live version to implement the
                            changes.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid>
        )}
        {(!signatures || !signatures.length) && (
          <Grid item container justify="center">
            <Grid item lg={6}>
              <Paper className={classes.paper}>
                <Grid container direction="column" justify="center" className={classes.signatureCard}>
                  <Typography align="left" variant="h4" color="textPrimary" component="h4">
                    Create your first company standard Signature
                  </Typography>
                  <Typography align="left">
                    A signature allows you to set up your company brand, which will then be rolled out to all of the users you
                    choose to apply this signature to.
                    <br />
                    <br />
                    You can have an unlimited number of signatures for your organization.
                    <br />
                    <br />
                    You can choose which signatures to apply to different teams or individuals.
                  </Typography>
                  {!(account.type === 'trial' && signatures && signatures.length > 1) && <SignatureAdd button />}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
