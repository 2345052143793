import { createMuiTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core';
import { themeBase, themeOptions } from './_theme';

const theme = createMuiTheme({
  ...themeOptions, //provide base
  palette: {
    type: 'dark',
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: '#ffffff',
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    primary: {
      main: '#5541D7',
    },
    secondary: {
      main: '#170041',
    },
    error: {
      main: '#e57373',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    ...themeBase.overrides,
    MuiIconButton: {
      sizeSmall: {
        // Adjust spacing to reach minimal touch target hitbox
        marginLeft: 4,
        marginRight: 4,
        padding: 6,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: themeBase.spacing(4),
        marginBottom: themeBase.spacing(2),
      },
    },
    MuiButton: {
      root: {},
      sizeLarge: {
        marginTop: themeBase.spacing(4),
        marginBottom: themeBase.spacing(2),
        paddingTop: themeBase.spacing(4),
        paddingBottom: themeBase.spacing(4),
        [themeBase.breakpoints.up('lg')]: {
          paddingLeft: themeBase.spacing(8),
          paddingRight: themeBase.spacing(8),
        },
        [themeBase.breakpoints.down('lg')]: {
          paddingLeft: themeBase.spacing(6),
          paddingRight: themeBase.spacing(6),
        },
      },
    },
    MuiTab: {
      fullWidth: {
        maxWidth: 100,
        minHeight: 60,
      },
      wrapper: {
        textTransform: 'capitalize',
      },
    },
  },
} as ThemeOptions);
export const DarkTheme = responsiveFontSizes(theme);
