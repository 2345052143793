/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { CssBaseline } from '@material-ui/core';
import { SplashPage } from './pages';
import { Main } from './Main';
import { AlertProvider } from '../util';
import ScrollProvider from '../util/ScrollTopArrow';
import { ThemeProviderMaterial, SideBoxProvider, useCustomTheme } from '../style';
import ReduxToastr from 'react-redux-toastr';

const App = ({ store, persistor, basename }: any) => {
  const customTheme = useCustomTheme();

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<SplashPage />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<SplashPage />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            {/* Render routes with provided `Layout`. */}
            <ThemeProviderMaterial theme={customTheme.theme.name}>
              <CssBaseline />
              <AlertProvider direction="top-right">
                <SideBoxProvider>
                  <ScrollProvider>
                    <Main />
                  </ScrollProvider>
                </SideBoxProvider>
              </AlertProvider>
              <ReduxToastr timeOut={0} />
            </ThemeProviderMaterial>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
};

export default App;
