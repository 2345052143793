import React, { createContext, useContext, useReducer } from 'react';

const initialSideBox = {
  options: {
    anchor: 'left',
    elevation: 16,
    ModalProps: {},
    open: true,
    PaperProps: {},
    SlideProps: {},
    transitionDuration: { enter: 100, exit: 100 },
    variant: 'temporary',
    width: '240px',
  },
  observe: {
    onClose: null,
  },
};

function reducerSideBox(state: any, action: any) {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        options: { ...state.options, ...action.payload.options },
        observe: { ...state.observe, ...action.payload.observe },
      };
    case 'open':
      return { ...state, options: { ...state.options, open: true } };
    case 'close':
      return { ...state, options: { ...state.options, open: false } };
    default:
      throw new Error();
  }
}

export const SideBoxContext = createContext({});

export const useSideBox = () => {
  const context: any = useContext(SideBoxContext);
  return context;
};

export const SideBoxProvider = ({ children }: any) => {
  const [sideBox, dispatchSideBox] = useReducer(reducerSideBox, initialSideBox);

  const context = {
    options: sideBox.options,
    observe: sideBox.observe,
    initialize(options?: any, observe?: any) {
      dispatchSideBox({ type: 'initialize', payload: { options: options, observe: observe } });
      return true;
    },
    open() {
      dispatchSideBox({ type: 'open' });
      return true;
    },
    close() {
      dispatchSideBox({ type: 'close' });
      return true;
    },
  };
  return <SideBoxContext.Provider value={context}>{children}</SideBoxContext.Provider>;
};
