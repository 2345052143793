import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import {
  createStyles,
  makeStyles,
  Theme,
  IconButton,
  List,
  Tooltip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ChevronRight, ChevronLeft, AccountCircle, Settings, Description, AttachMoney, MeetingRoom, AddCircleOutline } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { useSideBox } from './SideBoxProvider';
import { DrawerCustom } from '../../..';
import { AddCustomUser } from '../../../../app/modules/Users/AddCustomUser';
import { SignatureAdd } from '../../../../app/modules/Signature/SignatureAdd';
import { STRINGS } from '../../../../util/texts';
import { SIGNATURE_LOCATIONS } from '../../../../util/consts';

const drawerWidth = 240;
const useSideStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    list: {
      display: 'flex',
      flexFlow: 'column nowrap',
      rowGap: theme.spacing(2)
    }
  })
);

export const SideBox = () => {
  const classes = useSideStyles();
  const theme = useTheme();
  const location = useLocation();
  const { options, observe, close } = useSideBox(); // trigger sidebox
  const { account, signatures } = useSelector(
    (state: any) => ({
      account: state.account.account,
      signatures: state.signature.signatureItems,
    }),
    shallowEqual
  );
  const [showSignatureAction, setShowSignatureAction] = useState();

  useEffect(() => {
    setShowSignatureAction(SIGNATURE_LOCATIONS.some((val) => location.pathname.includes(val)));
  }, [location]);

  return (
    <div className={classes.root}>
      <DrawerCustom
        className={classes.drawer}
        variant={options.variant}
        anchor={options.anchor}
        open={options.open}
        onClose={observe.onClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={close}>{theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}</IconButton>
        </div>
        <Divider />
        <List className={classes.list}>
          <NavLink to="/users">
            <ListItem button>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </NavLink>
          <NavLink to="/dashboard">
            <ListItem button>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText primary="Signatures" />
            </ListItem>
          </NavLink>
          <NavLink to="/organization">
            <ListItem button>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </NavLink>
          <NavLink to="#">
            <AddCustomUser
              btnComponent={(handleClickOpen, disabled) => (
                <Tooltip title={disabled ? STRINGS.usersLimit : ''} placement="bottom" arrow>
                  <span>
                    <ListItem button aria-label="Add a new user" disabled={disabled} onClick={handleClickOpen}>
                      <ListItemIcon>
                        <AddCircleOutline />
                      </ListItemIcon>
                      <ListItemText primary="Add User" />
                    </ListItem>
                  </span>
                </Tooltip>
              )}
            />
          </NavLink>
          {showSignatureAction && !(account.type === 'trial' && signatures && signatures.length > 1) && (
            <NavLink to="#">
              <SignatureAdd withIcon />
            </NavLink>
          )}
          {/*<Divider />*/}
          {/*<Link to="/profile" className={classes.menuItem}>*/}
          {/*  Profile*/}
          {/*</Link>*/}
          <NavLink to="/billing">
            <ListItem button>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Billing" />
            </ListItem>
          </NavLink>
          <NavLink to="/logout">
            <ListItem button>
              <ListItemIcon>
                <MeetingRoom />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItem>
          </NavLink>
          {/*<Link to="/profile" className={classes.menuItem}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <AccountCircle fontSize="large" />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="My Profile" secondary="Account Settings and More" />*/}
          {/*</Link>*/}
          {/*<Link to='/payments' className={classes.menuItem}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <PaymentIcon fontSize='large' />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="My Payments" secondary='Payments Settings and More' />*/}
          {/*</Link>*/}
        </List>
      </DrawerCustom>
    </div>
  );
};
