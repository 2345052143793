/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { ApolloProvider } from '@apollo/client';
import { storePersistor, store } from './store';
import { setupAxios, apolloClient } from './api';
import App from './app/App';
import './index.scss'; // Standard version
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastProvider } from 'react-toast-notifications';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID as string}>
    <ToastProvider key="uniqueToast">
      <ApolloProvider client={apolloClient}>
        <App store={store} persistor={storePersistor} basename={PUBLIC_URL} />
      </ApolloProvider>
    </ToastProvider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
