import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { TextFieldCustom, ButtonCustom } from '../../../../../style';
import { SignatureSlice } from '../../../../../store';
import { useStyles } from './styles';

export const EmbedMeetingLinkImage = () => {
  const { signature } = useSelector(
    (state: any) => ({
      signature: state.signature.active,
    }),
    shallowEqual
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setActive } = SignatureSlice.actions;
  const [error, setError] = useState(false);
  const [meetingLinkImg, setMeetingLinkImg] = useState(signature.draft.meetingLinkImg);

  useEffect(() => {
    //when user reload the browser
    setMeetingLinkImg(signature.draft.meetingLinkImg);
  }, [signature.draft.meetingLinkImg]);

  const handlePaste = (evt: any) => {
    setMeetingLinkImg(evt.target.value);
    setError(false);
  };
  function clearLink() {
    setMeetingLinkImg('');
    setError(false);
  }

  function applyLink() {
    if (meetingLinkImg.length) {
      const newSignature = { ...signature, draft: { ...signature.draft, meetingLinkImg } };
      dispatch(setActive({ signature: newSignature }));
    } else {
      setError(true);
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item lg={12}>
        <Typography gutterBottom color="textPrimary" component="h5" variant="h5">
          Please paste your Meeting Link image below to embed it in your email signature
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <TextFieldCustom
          fullWidth
          id="meeting-image"
          label="Meeting Link image"
          multiline
          rows={4}
          value={meetingLinkImg}
          placeholder="Paste Your Meeting Link image"
          variant="outlined"
          onChange={handlePaste}
          error={error}
          helperText={error && 'Invalid image'}
        />
      </Grid>

      <Grid container justify="flex-end">
        <ButtonCustom onClick={clearLink} variant="outlined" color="primary">
          Clear
        </ButtonCustom>
        <ButtonCustom onClick={applyLink} className={classes.applyButton} variant="contained" color="primary">
          Apply
        </ButtonCustom>
      </Grid>
    </Grid>
  );
};
