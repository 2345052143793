import { createSlice } from '@reduxjs/toolkit';

interface socialType {
  id: string;
  name: string;
  icon_round: string;
  icon_solid: string;
  icon_square: string;
  icon_color: string;
  priority: boolean;
  linkBuffer?: string;
  labelBuffer?: string;
}

interface initialType {
  lastAction: string | null;
  socialItem: socialType[] | null;
  loaded: boolean;
}

const SocialState: initialType = {
  lastAction: null,
  socialItem: null,
  loaded: false,
};

export const callTypesSocial = {
  list: 'list',
  action: 'action',
};
export const SocialSlice = createSlice({
  name: 'Social Objects',
  initialState: SocialState,
  reducers: {
    loadSocialItem: (state: any, action) => {
      const { socials } = action.payload;
      state.socialItem = socials;
      state.lastAction = action.type;
    },
    updateSocialItem: (state: any, action) => {
      const { id, link, label } = action.payload;
      state.socialItem = state.socialItem.map((el: any) => {
        if (el.id === id) {
          el.linkBuffer = link;
          el.labelBuffer = label;
        }
        return el;
      });
      state.lastAction = action.type;
    },
    updateSocialItemLabel: (state: any, action) => {
      const { id, label } = action.payload;
      state.socialItem = state.socialItem.map((el: any) => {
        if (el.id === id) {
          el.labelBuffer = label;
        }
        return el;
      });
      state.lastAction = action.type;
    },
    clearSocialItem: (state: any, action) => {
      state.socialItem = state.socialItem.map((el: any) => {
        el.linkBuffer = '';
        el.labelBuffer = '';
        return el;
      });
      state.lastAction = action.type;
    },
  },
});
