import React, { ReactElement, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createStyles, Dialog, Grid, makeStyles, Theme, Typography, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ButtonCustom, TextFieldCustom, Upload } from '../../../style';
import { useUserImportMutation } from '../../../api';
import { STRINGS, YUP_MESSAGES } from '../../../util/texts';
import { SubscriptionSlice, TeamSlice, UsersSlice } from '../../../store';
import { toastr } from 'react-redux-toastr';

const AddUserSchema = Yup.object({
  name: Yup.string()
    .min(3, YUP_MESSAGES.minSymbols(3))
    .max(50, YUP_MESSAGES.maxSymbols(50))
    // .matches(/^[[A-Za-z ]+$/, "Invalid character")
    .required(YUP_MESSAGES.required('Name')),
  email: Yup.string()
    .min(3, YUP_MESSAGES.minSymbols(3))
    .max(50, YUP_MESSAGES.maxSymbols(50))
    .email(YUP_MESSAGES.wrongFormat('Email'))
    .required(YUP_MESSAGES.required('Email')),
  image: Yup.string(),
}).defined();
type UserType = Yup.InferType<typeof AddUserSchema>; //Type
const initialValues = {
  name: '',
  email: '',
  image: '',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      alignItems: 'center',
      paddingTop: theme.spacing(14),
      paddingBottom: theme.spacing(20),
    },
    container: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    emailField: {
      marginBottom: theme.spacing(13),
    },
    addUserBtn: {
      marginBottom: theme.spacing(10),
    },
    upload: {
      '& > img': {
        maxWidth: '100px',
      },
    },
    actionsContainer: {
      alignSelf: 'center',
      marginTop: theme.spacing(8),
    },
  })
);

export const AddCustomUser = ({ btnComponent }: AddCustomUserProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loadUsers } = UsersSlice.actions;
  const { loadTeam } = TeamSlice.actions;
  const { loadSubscription } = SubscriptionSlice.actions; //update subscription store for in use
  const { subscription } = useSelector(
    (state: any) => ({
      subscription: state.subscription.subscription,
    }),
    shallowEqual
  );
  const [UserImport] = useUserImportMutation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState({
    file: null,
    path: '/media/image/default.png',
    updated: false,
  } as any);

  const DISABLE_BTN = (subscription?.inUse ? subscription?.inUse : 0) === subscription?.quantity;

  const handleSubmit = async (values: UserType) => {
    enableLoading();
    const response = await UserImport({
      variables: {
        options: {
          users: {
            name: values.name,
            email: values.email,
          },
        },
        image: upload.file,
      } as any,
    });
    if (!response.errors && !response.data!.userImport.errors) {
      disableLoading();
      setOpen(false);
      dispatch(loadUsers({ users: response.data!.userImport.users }));
      dispatch(loadSubscription({ subscription: response.data!.userImport.subscription }));
      dispatch(loadTeam({ team: response.data!.userImport.teams }));
      toastr.success('Success', 'User successfully imported');
    } else {
      setOpen(false);
      const errors = response!.data!.userImport!.errors;
      disableLoading();
      errors!.forEach((error) => {
        toastr.error('Error', error.message);
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <Grid item>
      {btnComponent && btnComponent(handleClickOpen, DISABLE_BTN)}
      {!btnComponent && (
        <Tooltip title={DISABLE_BTN ? STRINGS.usersLimit : ''} placement="bottom" arrow>
          <span>
            <ButtonCustom variant="contained" color="primary" disabled={DISABLE_BTN} onClick={handleClickOpen}>
              <AddIcon /> &nbsp;Add User
            </ButtonCustom>
          </span>
        </Tooltip>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialog }}
      >
        <Formik initialValues={initialValues} validationSchema={AddUserSchema} onSubmit={handleSubmit}>
          {({ values, touched, errors, isSubmitting, isValid, handleChange, handleBlur }) => (
            <Grid item container justify="center" direction="column" className={classes.container} xs={12} lg={10} spacing={5}>
              <Grid item>
                <Typography component="h2" variant="h3" align="center">
                  Add User
                </Typography>
              </Grid>
              <Grid item>
                <TextFieldCustom
                  fullWidth
                  id="name"
                  name="name"
                  type="text"
                  variant="outlined"
                  color="primary"
                  label="Name*"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldCustom
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  variant="outlined"
                  color="primary"
                  label="Email*"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  className={classes.emailField}
                />
              </Grid>
              <Upload
                image={upload.path}
                onChange={setUpload}
                label="Upload Image"
                tooltip="Image should be less than 1mb. Recommended dimensions are 600px by 600px."
                uploadBtnText="Browse"
                previewContainerClassname={classes.upload}
                colWidth={10}
                tooltipHint
              />
              <Grid item container direction="column" className={classes.actionsContainer} xs={7}>
                <Grid item>
                  <ButtonCustom
                    spinner={loading}
                    disabled={loading || isSubmitting || !isValid}
                    variant={loading ? 'outlined' : 'contained'}
                    color="primary"
                    type="submit"
                    onClick={() => handleSubmit(values)}
                    autoFocus
                    fullWidth
                    className={classes.addUserBtn}
                  >
                    Add User
                  </ButtonCustom>
                </Grid>
                <Grid item>
                  <ButtonCustom
                    disabled={loading || isSubmitting}
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </ButtonCustom>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Dialog>
    </Grid>
  );
};

type AddCustomUserProps = {
  btnComponent?: (handleClickOpen: () => void, disabled: boolean) => ReactElement | string;
};
