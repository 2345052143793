import { createSlice } from '@reduxjs/toolkit';
import { Maybe, Scalars } from '../../../../api';

export interface PropertiesState {
  heading: {
    fontStyle?: Maybe<Scalars['String']>;
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
  };
  body: {
    fontStyle?: Maybe<Scalars['String']>;
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
  };
  link: {
    fontStyle?: Maybe<Scalars['String']>;
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
  };
  footnote: {
    fontStyle?: Maybe<Scalars['String']>;
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
  };
  meetingLink: {
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
    backgroundColor?: Maybe<Scalars['String']>;
    vPadding?: Maybe<Scalars['Float']>;
    hPadding?: Maybe<Scalars['Float']>;
    borderWidth?: Maybe<Scalars['Float']>;
    borderStyle?: Maybe<Scalars['String']>;
    borderColor?: Maybe<Scalars['String']>;
  };
  icons: {
    social: {
      backgroundColor: Maybe<Scalars['String']>;
    };
    contact: {
      backgroundColor: Maybe<Scalars['String']>;
    };
    size: Maybe<Scalars['Float']>;
    show: Maybe<Scalars['Boolean']>;
  };
  avatar: {
    size: Maybe<Scalars['Float']>;
    shape: Maybe<Scalars['String']>;
    borderRadius: Maybe<Scalars['String']>; //this is the output value of the shape, to avoid the need for template logic
  };
  socials: {
    show: Maybe<Scalars['Boolean']>;
    size: Maybe<Scalars['Float']>;
    iconStyle: Maybe<Scalars['String']>;
  };
  logo: {
    size: Maybe<Scalars['Float']>;
  };
  dividers: {
    style: Maybe<Scalars['String']>;
    thickness: Maybe<Scalars['Float']>;
    color: Maybe<Scalars['String']>;
    show: Maybe<Scalars['Boolean']>;
  };
  separator: {
    type: Maybe<Scalars['String']>;
    color: Maybe<Scalars['String']>;
  };
}

interface initialType {
  lastAction: string | null;
  property: PropertiesState | null;
}

const Property: PropertiesState = {
  heading: {
    fontStyle: 'normal',
    fontFamily: 'sans-serif',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#5541D7',
  },
  body: {
    fontStyle: 'normal',
    fontFamily: 'sans-serif',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.2,
    color: '#444',
  },
  link: {
    fontStyle: 'normal',
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
    color: '#000',
  },
  footnote: {
    fontStyle: 'normal',
    fontFamily: 'sans-serif',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1.2,
    color: '#666',
  },
  meetingLink: {
    fontFamily: 'sans-serif',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.2,
    color: '#FFF',
    backgroundColor: '#5541D7',
    hPadding: 8,
    vPadding: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#5541D7',
  },
  icons: {
    social: {
      backgroundColor: '#000',
    },
    contact: {
      backgroundColor: '#5541D7',
    },
    size: 16,
    show: true,
  },
  socials: {
    show: true,
    size: 24,
    iconStyle: 'default',
  },
  avatar: {
    size: 128,
    shape: 'square',
    borderRadius: '0px', //this is the output value of the shape, to avoid the need for template logic
  },
  logo: {
    size: 128,
  },
  dividers: {
    style: 'solid',
    thickness: 1,
    color: 'red',
    show: true,
  },
  separator: {
    type: ' ',
    color: '#444',
  },
};
const PropertiesState: initialType = {
  lastAction: null,
  property: Property,
};

export const callTypesProperties = {
  list: 'list',
  action: 'action',
};

export const PropertiesSlice = createSlice({
  name: 'Properties Objects',
  initialState: PropertiesState,
  reducers: {
    setProperty: (state: any, action) => {
      const { property } = action.payload;
      state.property = property;
      state.lastAction = action.type;
    },
    clearProperty: (state: any, action) => {
      state.property = Property;
      state.lastAction = action.type;
    },
  },
});
