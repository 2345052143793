const STRINGS = {
  usersLimit: 'You have hit your limit of users. Visit the billing page.'
};

const ARIA_LABELS = {};

const YUP_MESSAGES = {
  required: (fieldName: string) => `${fieldName} is required`,
  acceptTerms: 'You must accept the terms and conditions',
  invalid: (fieldName: string) => `Invalid ${fieldName}`,
  wrongFormat: (fieldName: string) => `Wrong ${fieldName} format`,
  notMatch: (fieldName: string) => `${fieldName} don't match`,
  mustProvide: (fieldName: string) => `${fieldName} must be provided`,
  haveTo: (duty: string) => `You have to ${duty}`,
  maximumUsersAssigned: 'You have too many users assigned',
  minSymbols: (count: number) => `Minimum ${count} characters`,
  maxSymbols: (count: number) => `Maximum ${count} characters`
};

export { STRINGS, ARIA_LABELS, YUP_MESSAGES };
