import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import RemoveIcon from '@material-ui/icons/Remove';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PhotoIcon from '@material-ui/icons/Photo';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import LinkIcon from '@material-ui/icons/Link';
import PolicyIcon from '@material-ui/icons/Policy';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import CodeIcon from '@material-ui/icons/Code';
import Image from '@material-ui/icons/Image';
import { Control } from './Controls';
import { EmbedCode } from '../embedCode/EmbedCode';
import { EmbedHTMLCode } from '../embedCode/EmbedHTMLCode';
import { EmbedMeetingLinkImage } from '../embedCode/EmbedMeetingLinkImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 48,
      },
    },
    container: {
      padding: theme.spacing(4),
    },
    control: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    select: {
      margin: 0,
      '& .MuiSelect-select': {
        minWidth: 156,
      },
    },
    component: {
      '& .MuiFormControl-root': {
        margin: '0!important',
      },
    },
  })
);

export const EditorsControl = (props: any) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const classes = useStyles();

  const components = [
    {
      label: 'Photo & Logo',
      key: 'photo',
      icon: <PhotoIcon />,
      properties: [
        {
          level1: 'avatar',
          level2: 'size',
          level3: '',
          label: 'Personal Photo Size',
          component: 'slider',
          range: { min: 50, max: 150, step: 1 },
        },
        {
          level1: 'logo',
          level2: 'size',
          level3: '',
          label: 'Logo Size',
          component: 'slider',
          range: { min: 50, max: 150, step: 1 },
        },
      ],
    },
    {
      label: 'Name',
      key: 'heading',
      icon: <ViewHeadlineIcon />,
      properties: [
        { level1: 'heading', level2: 'fontStyle', level3: '', label: 'Font Style', component: 'select' },
        { level1: 'heading', level2: 'fontFamily', level3: '', label: 'Font Family', component: 'select' },
        {
          level1: 'heading',
          level2: 'fontSize',
          level3: '',
          label: 'Font Size',
          component: 'slider',
          range: { min: 12, max: 24, step: 1 },
        },
        {
          level1: 'heading',
          level2: 'fontWeight',
          level3: '',
          label: 'Font Weight',
          component: 'slider',
          range: { min: 400, max: 800, step: 100 },
        },
        {
          level1: 'heading',
          level2: 'lineHeight',
          level3: '',
          label: 'Line Height',
          component: 'slider',
          range: { min: 1, max: 2, step: 0.1 },
        },
        { level1: 'heading', level2: 'color', level3: '', label: 'Color', component: 'color' },
      ],
    },
    {
      label: 'Company Name, Title, Department',
      key: 'body',
      icon: <AccessibilityIcon />,
      properties: [
        { level1: 'body', level2: 'fontStyle', level3: '', label: 'Font Style', component: 'select' },
        { level1: 'body', level2: 'fontFamily', level3: '', label: 'Font Family', component: 'select' },
        {
          level1: 'body',
          level2: 'fontSize',
          level3: '',
          label: 'Font Size',
          component: 'slider',
          range: { min: 10, max: 18, step: 1 },
        },
        {
          level1: 'body',
          level2: 'fontWeight',
          level3: '',
          label: 'Font Weight',
          component: 'slider',
          range: { min: 300, max: 600, step: 100 },
        },
        {
          level1: 'body',
          level2: 'lineHeight',
          level3: '',
          label: 'Line Height',
          component: 'slider',
          range: { min: 1, max: 2, step: 0.1 },
        },
        { level1: 'body', level2: 'color', level3: '', label: 'Color', component: 'color' },
      ],
    },
    {
      label: 'Address, Email, Web',
      key: 'link',
      icon: <LinkIcon />,
      properties: [
        { level1: 'link', level2: 'fontStyle', level3: '', label: 'Font Style', component: 'select' },
        { level1: 'link', level2: 'fontFamily', level3: '', label: 'Font Family', component: 'select' },
        {
          level1: 'link',
          level2: 'fontSize',
          level3: '',
          label: 'Font Size',
          component: 'slider',
          range: { min: 10, max: 18, step: 1 },
        },
        {
          level1: 'link',
          level2: 'fontWeight',
          level3: '',
          label: 'Font Weight',
          component: 'slider',
          range: { min: 300, max: 600, step: 100 },
        },
        {
          level1: 'link',
          level2: 'lineHeight',
          level3: '',
          label: 'Line Height',
          component: 'slider',
          range: { min: 1, max: 2, step: 0.1 },
        },
        { level1: 'link', level2: 'color', level3: '', label: 'Color', component: 'color' },
      ],
    },

    {
      label: 'Show Icons',
      key: 'contact',
      icon: <PermContactCalendarIcon />,
      properties: [
        { level1: 'icons', level2: 'show', level3: '', label: 'Show Icons', component: 'switch' },
        { level1: 'icons', level2: 'contact', level3: 'backgroundColor', label: 'Icons color', component: 'color' },
        {
          level1: 'icons',
          level2: 'size',
          level3: '',
          label: 'Icon size',
          component: 'slider',
          range: { min: 10, max: 50, step: 1 },
        },
      ],
    },

    {
      label: 'Social Links',
      key: 'socials',
      icon: <AccountTreeIcon />,
      properties: [
        { level1: 'socials', level2: 'show', level3: '', label: 'Show Icons', component: 'switch' },
        {
          level1: 'socials',
          level2: 'size',
          level3: '',
          label: 'Icon size',
          component: 'slider',
          range: { min: 10, max: 50, step: 1 },
        },
        { level1: 'socials', level2: 'iconStyle', level3: '', label: 'Icon Style', component: 'select' },
      ],
    },
    {
      label: 'Dividers',
      key: 'dividers',
      icon: <RemoveIcon />,
      properties: [
        {
          level1: 'dividers',
          level2: 'thickness',
          level3: '',
          label: 'Thickness',
          component: 'slider',
          range: { min: 1, max: 5, step: 1 },
        },
        { level1: 'dividers', level2: 'style', level3: '', label: 'Style', component: 'select' },
        { level1: 'dividers', level2: 'color', level3: '', label: 'Color', component: 'color' },
        { level1: 'dividers', level2: 'show', level3: '', label: 'Show Dividers', component: 'switch' },
      ],
    },
    {
      label: 'Separators',
      key: 'separator',
      icon: <FirstPageIcon />,
      properties: [
        { level1: 'separator', level2: 'type', level3: '', label: 'Type', component: 'select' },
        { level1: 'separator', level2: 'color', level3: '', label: 'Color', component: 'color' },
      ],
    },
    {
      label: 'Footnote / Disclaimer',
      key: 'footnote',
      icon: <PolicyIcon />,
      properties: [
        { level1: 'footnote', level2: 'fontStyle', level3: '', label: 'Font Style', component: 'select' },
        { level1: 'footnote', level2: 'fontFamily', level3: '', label: 'Font Family', component: 'select' },
        {
          level1: 'footnote',
          level2: 'fontSize',
          level3: '',
          label: 'Font Size',
          component: 'slider',
          range: { min: 10, max: 18, step: 1 },
        },
        {
          level1: 'footnote',
          level2: 'fontWeight',
          level3: '',
          label: 'Font Weight',
          component: 'slider',
          range: { min: 300, max: 600, step: 100 },
        },
        {
          level1: 'footnote',
          level2: 'lineHeight',
          level3: '',
          label: 'Line Height',
          component: 'slider',
          range: { min: 1, max: 2, step: 0.1 },
        },
        { level1: 'footnote', level2: 'color', level3: '', label: 'Color', component: 'color' },
      ],
    },

    {
      label: 'Meeting Link',
      key: 'meetingLink',
      icon: <MeetingRoomIcon />,
      properties: [
        { level1: 'meetingLink', level2: 'fontFamily', level3: '', label: 'Font Family', component: 'select' },
        {
          level1: 'meetingLink',
          level2: 'fontSize',
          level3: '',
          label: 'Font Size',
          component: 'slider',
          range: { min: 10, max: 18, step: 1 },
        },
        {
          level1: 'meetingLink',
          level2: 'fontWeight',
          level3: '',
          label: 'Font Weight',
          component: 'slider',
          range: { min: 300, max: 800, step: 100 },
        },
        {
          level1: 'meetingLink',
          level2: 'lineHeight',
          level3: '',
          label: 'Line Height',
          component: 'slider',
          range: { min: 1, max: 2, step: 0.1 },
        },
        { level1: 'meetingLink', level2: 'color', level3: '', label: 'Color', component: 'color' },
        { level1: 'meetingLink', level2: 'backgroundColor', level3: '', label: 'Button Color', component: 'color' },
        {
          level1: 'meetingLink',
          level2: 'vPadding',
          level3: '',
          label: 'Vertical Padding',
          component: 'slider',
          range: { min: 0, max: 30, step: 1 },
        },
        {
          level1: 'meetingLink',
          level2: 'hPadding',
          level3: '',
          label: 'Horizontal Padding',
          component: 'slider',
          range: { min: 0, max: 30, step: 1 },
        },
        {
          level1: 'meetingLink',
          level2: 'borderWidth',
          level3: '',
          label: 'Border Thickenss',
          component: 'slider',
          range: { min: 0, max: 10, step: 1 },
        },
        { level1: 'meetingLink', level2: 'borderColor', level3: '', label: 'Border Color', component: 'color' },
        { level1: 'meetingLink', level2: 'borderStyle', level3: '', label: 'Border Style', component: 'select' },
      ],
    },
  ];

  const handleExpand = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      {components.map((component: any) => {
        return (
          <Accordion
            key={component.key}
            expanded={expanded === `panel-${component.key}`}
            onChange={handleExpand(`panel-${component.key}`)}
          >
            <AccordionSummary
              className={classes.header}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`settings-${component.key}-controls`}
              id="settings-socials-controls"
            >
              {component.icon}&nbsp;&nbsp;
              <Typography color="textPrimary" variant="h5" component="h5">
                {component.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container className={classes.container}>
                {component.properties.map((element: any) => {
                  if (element.component === 'slider') {
                    return (
                      <Control
                        key={element.label}
                        label={element.label}
                        range={element.range}
                        component={element.component}
                        level3={element.level3}
                        level1={element.level1}
                        level2={element.level2}
                      />
                    );
                  } else {
                    return (
                      <Control
                        key={element.label}
                        label={element.label}
                        component={element.component}
                        level3={element.level3}
                        level1={element.level1}
                        level2={element.level2}
                      />
                    );
                  }
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {/*
               <Accordion expanded={expanded === `panel-meeting`} onChange={handleExpand(`panel-meeting`)}>
                   <AccordionSummary className={classes.header} expandIcon={<ExpandMoreIcon/>}
                                     aria-controls={`settings-meeting-controls`} id="settings-meeting-controls">
                       <MeetingRoomIcon/>&nbsp;&nbsp;<Typography color={"textPrimary"} variant={"h5"} component={"h5"}>Book
                       a Meeting Link</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Grid container className={classes.container}>
                           <Grid container item className={classes.control} alignItems={"center"}
                                 justify={"space-between"}>
                               <Grid item>
                                   <Typography color={"textPrimary"} variant={"h6"} component={"h6"}>Meeting Link
                                       Label</Typography>
                               </Grid>
                               <Grid item className={classes.component}>
                                   <TextFieldCustom variant={"outlined"}/>
                               </Grid>
                           </Grid>
                       </Grid>
                   </AccordionDetails>
               </Accordion>
           */}
      <Accordion expanded={expanded === `panel-meeting-link`} onChange={handleExpand(`panel-meeting-link`)}>
        <AccordionSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="settings-meeting-link-controls"
          id="settings-meeting-link-controls"
        >
          <Image />
          &nbsp;&nbsp;
          <Typography color="textPrimary" variant="h5" component="h5">
            Embed Meeting Link image
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.container}>
            <Grid container item className={classes.control} alignItems="center" justify="space-between">
              <EmbedMeetingLinkImage />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === `panel-embed`} onChange={handleExpand(`panel-embed`)}>
        <AccordionSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="settings-embed-controls"
          id="settings-embed-controls"
        >
          <CallToActionIcon />
          &nbsp;&nbsp;
          <Typography color="textPrimary" variant="h5" component="h5">
            Embed HubSpot CTA
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.container}>
            <Grid container item className={classes.control} alignItems="center" justify="space-between">
              <EmbedCode />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === `panel-custom-html`} onChange={handleExpand(`panel-custom-html`)}>
        <AccordionSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="settings-custom-html-controls"
          id="settings-custom-html-controls"
        >
          <CodeIcon />
          &nbsp;&nbsp;
          <Typography color="textPrimary" variant="h5" component="h5">
            Embed Custom HTML
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.container}>
            <Grid container item className={classes.control} alignItems="center" justify="space-between">
              <EmbedHTMLCode expanded={expanded === `panel-custom-html`} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
