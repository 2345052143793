import React, { ReactElement, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme, Popover, Avatar } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover/Popover';

export const useDropdownStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      padding: theme.spacing(3),
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    popper: {
      display: 'block',
    },
    popover: {
      top: '104px',
    },
    typography: {
      padding: theme.spacing(2),
    },
    square: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      background: 'none',
      border: 'none',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    name: {
      textTransform: 'capitalize',
      marginLeft: 8,
    },
  })
);

export const Dropdown = ({
  icon,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  children,
}: DropdownProps) => {
  const classes = useDropdownStyles();
  const dropdownBtnRef: any = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = () => {
    setAnchorEl(dropdownBtnRef.current as any);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div ref={dropdownBtnRef} className={classes.popper} aria-describedby={id} onClick={handleClick}>
        <Avatar variant="rounded" className={classes.square}>
          {icon}
        </Avatar>
      </div>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children}
      </Popover>
    </div>
  );
};

type DropdownProps = {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  icon: ReactElement;
  children: ReactElement;
};
