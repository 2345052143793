import { createSlice } from '@reduxjs/toolkit';
import Maybe from 'graphql/tsutils/Maybe';
import { Scalars } from '../../../../api';

interface SocialDetailsEntities {
  id: string;
  name: string;
  icon_round: string;
  icon_solid: string;
  icon_square: string;
  priority: string;
}

interface SocialsEntities {
  id: string;
  link: string;
  ownerType: string;
  details: SocialDetailsEntities | null;
}

interface AccountProfileEntities {
  id: string;
  image: string;
  role: string;
  phone: string;
  mobile: string;
  department: string;
  custom: string;
  socials: SocialsEntities[] | null;
}

interface AccountType {
  name: string;
  email: string;
  id: string;
  status: string;
  terms_accepted: boolean;
  profile: AccountProfileEntities | null;
}

interface TeamInput {
  name: string;
  id: string;
}

interface TeamInput {
  name: string;
  id: string;
}

interface TemplateType {
  name: string;
  customCode: string;
  id: string;
  html: string;
}

interface SocialsUserEntities {
  id: string;
  link: string;
  details: string;
}

interface UsersEntities {
  id: string;
  email: string;
  name: string;
  hub_id: string;
  type: string;
  image: string;
  role: string;
  phone: string;
  mobile: string;
  passcode: string;
  assigned: boolean;
  teams: TeamInput[];
  socials: SocialsUserEntities[];
  isLinked: boolean;
}

interface PropertiesState {
  heading: {
    // @ts-ignore
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
  };
  body: {
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
  };
  link: {
    fontFamily?: Maybe<Scalars['String']>;
    fontSize?: Maybe<Scalars['Float']>;
    fontWeight?: Maybe<Scalars['Float']>;
    lineHeight?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
  };
  icons: {
    social: {
      backgroundColor: Maybe<Scalars['String']>;
    };
    contact: {
      backgroundColor: Maybe<Scalars['String']>;
    };
    size: Maybe<Scalars['Float']>;
    show: Maybe<Scalars['Boolean']>;
  };
  avatar: {
    size: Maybe<Scalars['Float']>;
    shape: Maybe<Scalars['String']>;
    borderRadius: Maybe<Scalars['String']>; //this is the output value of the shape, to avoid the need for template logic
  };
  socials: {
    show: Maybe<Scalars['Boolean']>;
    size: Maybe<Scalars['Float']>;
  };
  logo: {
    size: Maybe<Scalars['Float']>;
  };
  dividers: {
    style: Maybe<Scalars['String']>;
    thickness: Maybe<Scalars['Boolean']>;
    color: Maybe<Scalars['String']>;
    show: Maybe<Scalars['Boolean']>;
  };
}

interface SignatureDraftEntities {
  id: string;
  name: string;
  meetingLinkImg: string;
  embedCode: string;
  embedCodeTwo: string;
  customCode: string;
  template: TemplateType;
  properties: PropertiesState;
}

interface SignatureEntities {
  id: string;
  name: string;
}

interface SocialsUserEntities {
  id: string;
  link: string;
  details: string;
}

interface UserType {
  id: string;
  email: string;
  name: string;
  hub_id: string;
  image: string;
  role: string;
  phone: string;
  mobile: string;
  passcode: string;
  assigned: boolean;
  teams: TeamInput[];
  signature: SignatureEntities;
  socials: SocialsUserEntities[];
  isLinked: boolean;
}

interface SignatureEntities {
  id: string;
  name: string;
  meetingLinkImg: string;
  embedCode: string;
  customCode: string;
  users: UsersEntities[];
  template: TemplateType;
  properties: PropertiesState;
  hasDraft: boolean; //
  draft: SignatureDraftEntities;
}

interface AddressEntities {
  id: string;
  street: string;
  city: string;
  post_code: string;
  state: string;
  country: string;
  isDefault: boolean;
}

interface CompanyEntities {
  id: string;
  image: string;
  name: string;
  url: string;
  phone: string;
  footnote: string;
  hubId: string;
  address: AddressEntities[] | null;
  socials: SocialsEntities[] | null;
}

interface initialType {
  lastAction: string | null;
  user: UserType | null;
  account: AccountType | null;
  signature: SignatureEntities | null;
  company: CompanyEntities | null;
  template: TemplateType | null;
  googleLink: string | null;
}

const PublicState: initialType = {
  lastAction: null,
  user: null,
  account: null,
  signature: null,
  template: null,
  company: null,
  googleLink: null,
};

export const callTypesPublic = {
  list: 'list',
  action: 'action',
};

export const PublicSlice = createSlice({
  name: 'Address Objects',
  initialState: PublicState,
  reducers: {
    loadPublic: (state: any, action) => {
      const { user, account, signature, company, template, googleLink } = action.payload; //Put accessToken user
      state.user = user;
      state.account = account;
      state.signature = signature;
      state.company = company;
      state.template = template;
      state.googleLink = googleLink;
      state.lastAction = action.type;
    },
    updateUserSlice: (state: any, action) => {
      const { user } = action.payload; //Put accessToken user
      state.user = user;
      state.lastAction = action.type;
    },
  },
});
