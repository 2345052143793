import * as Yup from 'yup';

const testSocials = (parent: any, fieldDependency: string, fieldToCheck: string) => {
  const dependencyValue = parent[fieldDependency];
  const fieldValue = parent[fieldToCheck];

  return !(!fieldValue && !!dependencyValue);
};

const socials = Yup.array().of(
  Yup.object().shape({
    priority: Yup.string(),
    icon_solid: Yup.string(),
    name: Yup.string(),
    linkBuffer: Yup.string()
      .max(120, 'Maximum 120 characters')
      .test('touched', 'This field is required', function () {
        return testSocials(this.parent, 'labelBuffer', 'linkBuffer');
      }),
    labelBuffer: Yup.string()
      .max(50, 'Maximum 50 characters')
      .test('touched', 'This field is required', function () {
        return testSocials(this.parent, 'linkBuffer', 'labelBuffer');
      }),
    id: Yup.string(),
  })
);

export { socials };
