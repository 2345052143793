import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { List, ListItem, Tooltip } from '@material-ui/core';
import { useStyles } from './styles';
import { SignatureAdd } from '../../../../app/modules/Signature/SignatureAdd';
import { AddCustomUser } from '../../../../app/modules/Users/AddCustomUser';
import { STRINGS } from '../../../../util/texts';
import { SIGNATURE_LOCATIONS } from '../../../../util/consts';

export const ActionsDropdownMenu = () => {
  const classes = useStyles();
  const location = useLocation();
  const { account, signatures } = useSelector(
    (state: any) => ({
      account: state.account.account,
      signatures: state.signature.signatureItems,
    }),
    shallowEqual
  );
  const [showSignatureAction, setShowSignatureAction] = useState();

  useEffect(() => {
    setShowSignatureAction(SIGNATURE_LOCATIONS.some((val) => location.pathname.includes(val)));
  }, [location]);

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="actions dropdown menu">
        <AddCustomUser
          btnComponent={(handleClickOpen, disabled) => (
            <Tooltip title={disabled ? STRINGS.usersLimit : ''} placement="bottom" arrow>
              <span>
                <ListItem
                  button
                  className={classes.menuItem}
                  aria-label="add a new user"
                  disabled={disabled}
                  onClick={handleClickOpen}
                >
                  Add User
                </ListItem>
              </span>
            </Tooltip>
          )}
        />
        {showSignatureAction && !(account.type === 'trial' && signatures && signatures.length > 1) && (
          <SignatureAdd btnClassName={classes.menuItem} />
        )}
      </List>
    </div>
  );
};
