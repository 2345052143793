import {createSlice} from "@reduxjs/toolkit";

interface initialType {
    lastAction:string|null,
    authorizationUrl:string

}
const GoogleState:initialType = {
    lastAction:null,
    authorizationUrl: "",
};

export const callTypesGoogle = {
    list: "list",
    action: "action"
};

export const GoogleSlice = createSlice({
    name: "Google Objects",
    initialState: GoogleState,
    reducers: {
        updateGoogle: (state:any, action) => {
            const { url } = action.payload; //Put accessToken user
            state.authorizationUrl=url;
            state.lastAction=action.type;
        },

        clearGoogle: (state:any, action) => {
            state.authorizationUrl="";
            state.lastAction=action.type;
        },
    }
}
)

