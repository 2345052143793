import jwtDecode from "jwt-decode";
import {AccountSlice,store} from "../../store"

import {axiosClient} from "./setupAxios";

export const getAccessToken = ()=>{
    const {account: {accessToken} } = store.getState();
    return accessToken;
}

export const refreshAccessToken = async () =>{
    const {updateToken,logoutReducer}=AccountSlice.actions
    let accessToken =getAccessToken();

    if (accessToken){
        const { exp } = jwtDecode(accessToken);
        if (Date.now() >= exp * 1000) {
            const resolve =await axiosClient.post('account/refresh_token');
            if(resolve.data.ok===true) {
                store.dispatch(updateToken({accessToken:resolve!.data.accessToken})) //hydrate the access token
                return resolve.data.accessToken;
            }else{
                store.dispatch(logoutReducer({})) //user is invalid and will make store access token null, so it will redirect mate
            }
        }
    }
    return accessToken;
}

export const validateAccesstoken =async (headers:any)=>{
    let accessToken =await refreshAccessToken();
    const newHeader=headers?headers:{};
    return {
        headers: {
            ...newHeader,
            authorization: accessToken ? `Bearer ${accessToken}` : "",
        }
    }
}
