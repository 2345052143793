import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      [theme.breakpoints.up('lg')]: {
        height: '100%',
      },
    },
  })
);

export const LayoutAuth = ({ children }: any) => {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.root}>
        <Grid container>{children}</Grid>
      </Paper>
    </>
  );
};
