import { createSlice } from '@reduxjs/toolkit';

interface SocialDetailsEntities {
  id: string;
  name: string;
  icon_round: string;
  icon_solid: string;
  icon_square: string;
  priority: string;
}

interface AddressEntities {
  id: string;
  street: string;
  post_code: string;
  state: string;
  country: string;
  isDefault: boolean;
}

interface SocialsEntities {
  id: string;
  link: string;
  ownerType: string;
  details: SocialDetailsEntities | null;
}

interface CompanyEntities {
  id: string;
  image: string;
  name: string;
  url: string;
  phone: string;
  fax: string;
  footnote: string;
  hubId: string;
  address: AddressEntities[] | null;
  socials: SocialsEntities[] | null;
}

interface initialType {
  lastAction: string | null;
  company: CompanyEntities | null;
}

const CompanyState: initialType = {
  lastAction: null,
  company: null,
};

export const callTypesCompany = {
  list: 'list',
  action: 'action',
};

export const CompanySlice = createSlice({
  name: 'Company Objects',
  initialState: CompanyState,
  reducers: {
    loadCompany: (state: any, action) => {
      const { company } = action.payload;
      state.company = company;
      state.lastAction = action.type;
    },
  },
});
