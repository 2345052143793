import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGoogleVerifyMutation } from '../../../../api';
import { Splash } from '../../../../style';
import { toastr } from 'react-redux-toastr';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const GoogleConfirm = (props: any) => {
  const query: any = useQuery();
  const state: string = query.get('state');
  const code: string = query.get('code');
  const history = useHistory();
  const [googleVerifyMutation] = useGoogleVerifyMutation();
  const hasRun = useRef<boolean>(false);

  useEffect(() => {
    //when user reloads the browser
    if (code && !hasRun.current) {
      hasRun.current = true;
      googleVerifyMutation({ variables: { code, state } }).then(({ data }) => {
        if (!data!.GoogleVerify.errors) {
          toastr.success('Success', 'You have successfully connected to Google');
          history.push(data!.GoogleVerify.redirectUri as string);
        } else {
          //todo response.data!.onBoardHubspot.errors[0].message
          toastr.error('Error', data!.GoogleVerify.errors[0].message);
          history.push('/public/google-error');
        }
      });
    }
  }, [code, googleVerifyMutation, hasRun, history, state]);

  return <Splash />;
};
