const removeFromObject = (prop: any, objects: any) => {
  if (Array.isArray(prop)) {
    prop.forEach((item) => {
      delete objects[item];
    });
  } else {
    delete objects[prop];
  }

  return objects;
};

const toAbsoluteUrl = (pathname: any) => process.env.PUBLIC_URL + pathname;

export { removeFromObject, toAbsoluteUrl };
