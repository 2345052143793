import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Billing } from '../modules/Billing';
import { BillingExpire } from '../modules/Billing/Expire';
import { Layout } from '../../style';

const BillingPage = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/billing/expire/:type" component={BillingExpire} />
        <Route path="/billing" component={Billing} />
      </Switch>
    </Layout>
  );
};

export default BillingPage;
