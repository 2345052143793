import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Routes } from './Routes';
import ConfirmHubspotPage from './pages/ConfirmHubspot';
import { Splash } from '../style';
import { useMeQuery } from '../api';

export const Main = () => {
  const location = useLocation();
  const { data, error, loading } = useMeQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (!loading) {
      console.log('Data Loaded!');
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      console.error(error, 'Error Occurred');
    }
  }, [error]);

  if (location.pathname === '/hubspot-authorize') {
    return (
      <Switch>
        <Route path="/hubspot-authorize" component={ConfirmHubspotPage} />
      </Switch>
    );
  }

  return (
    <>
      {loading && <Splash />}
      {!loading && <Routes data={data!.me} />}
    </>
  );
};
