import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { createStyles, Dialog, DialogActions, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ButtonCustom, TextFieldCustom, CheckboxCustom, LinkCustom } from '../../../style';
import { AlertBox } from '../../../util';
import { useSubscriptionUpdateMutation } from '../../../api';
import { SubscriptionSlice } from '../../../store';
import { toastr } from 'react-redux-toastr';

const { loadSubscription } = SubscriptionSlice.actions; //update subscription store for in use

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(7),
    },
    modalBody: {
      padding: theme.spacing(7),
    },
    wrapper: {
      display: 'inline-flex',
      marginRight: theme.spacing(3),
    },
    highlight: {
      color: theme.palette.primary.main,
    },
    checkboxCustom: {
      '& .MuiFormControlLabel-root': {
        marginRight: theme.spacing(2),
      },
    },
    actionsContainer: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  })
);

function calcPrice(quant: any) {
  const first50Rate = 1.8;
  const fiftyTo500Rate = 1.2;
  const above500Rate = 0.7;

  let qty = parseInt(quant);
  let total = 0;

  if (qty > 50) {
    total += 50 * first50Rate;
    if (qty > 500) {
      total += 450 * fiftyTo500Rate;
      total += (qty - 500) * above500Rate;
    } else {
      total += (qty - 50) * fiftyTo500Rate;
    }
  } else {
    total = qty * first50Rate;
  }
  return Math.floor(total);
}

export const BuyMore = ({ name, type, disabled }: BuyMoreProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { subscription } = useSelector(
    (state: any) => ({
      account: state.account.account,
      company: state.company.company,
      subscription: state.subscription.subscription,
    }),
    shallowEqual
  );
  const [updateSubscription] = useSubscriptionUpdateMutation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const VerifyChoices = Yup.object({
    quantity: Yup.number()
      .required('Quantity is required')
      .min(5, 'You have to assign at least 5 users')
      .test('in use', 'You have too many users assigned', (value: any) => parseInt(value) > subscription.inUse),
    coupon: Yup.string(),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
  }).defined();
  type VerifyChoicesType = Yup.InferType<typeof VerifyChoices>; //Type

  const initialValues = {
    quantity: subscription.quantity,
    coupon: '',
    acceptTerms: false,
  };

  const payLessMore = (value: number) => {
    if (value < 0) {
      return 'less';
    } else {
      return 'more';
    }
  };
  const matchPrice = (value: any) => {
    return calcPrice(value) - calcPrice(subscription.quantity);
  };
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: VerifyChoicesType) => {
    enableLoading();
    const response = await updateSubscription({
      variables: {
        options: {
          type: 'quantity',
          quantity: values.quantity.toString(),
          coupon: values.coupon.trim(),
        },
      } as any,
    });
    if (!response.errors && !response.data!.subscriptionUpdate.errors) {
      dispatch(loadSubscription({ subscription: response.data!.subscriptionUpdate.subscription }));
      handleClose();
    } else {
      response.data!.subscriptionUpdate!.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
    }
    disableLoading();
  };

  return (
    <>
      <ButtonCustom variant={type ? type : 'contained'} color="primary" onClick={handleClickOpen} disabled={disabled}>
        {name ? name : 'Buy More Users'}
      </ButtonCustom>
      <Dialog
        maxWidth="sm"
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik initialValues={initialValues} validationSchema={VerifyChoices} validateOnChange onSubmit={onSubmit}>
          {(formik) => (
            <Form>
              <Grid container className={classes.modalBody} justify="center" alignItems="center">
                <Grid item lg={12}>
                  <Typography variant="h5" gutterBottom>
                    Buy more licenses to increase the amount of users you can assign signatures to.
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography variant="h5" gutterBottom>
                    You currently have{' '}
                    <span className={classes.highlight}>{subscription ? subscription.quantity : ''} Licenses</span> and have
                    assigned <span className={classes.highlight}>{subscription ? subscription.inUse : ''} users.</span>
                  </Typography>
                </Grid>
                {subscription.coupon && (
                  <Grid item lg={12}>
                    <Typography variant="h5" gutterBottom>
                      You are currently using <span className={classes.highlight}>{subscription.coupon}</span> Coupon
                    </Typography>
                  </Grid>
                )}
                <Grid item container lg={12} justify="center" alignItems="center" spacing={1}>
                  <Grid item xs={12}>
                    <AlertBox container="quantity-billing" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      fullWidth
                      id="quantity"
                      name="quantity"
                      type="number"
                      variant="outlined"
                      color="primary"
                      label="Number of Assignable Users"
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                      helperText={formik.touched.quantity && formik.errors.quantity}
                    />
                    {Boolean(formik.errors.quantity) && (
                      <Typography color="error" variant="body1">
                        {formik.errors.quantity}
                      </Typography>
                    )}
                  </Grid>
                  {formik.values.quantity > 5 &&
                    subscription.inUse < formik.values.quantity &&
                    matchPrice(formik.values.quantity) !== 0 &&
                    !Number.isNaN(matchPrice(formik.values.quantity)) && (
                      <Grid item lg={12}>
                        <Typography variant="h5" gutterBottom>
                          You will pay{' '}
                          <span className={classes.highlight}>
                            USD $ {Math.abs(calcPrice(formik.values.quantity) - calcPrice(subscription.quantity))}{' '}
                            {payLessMore(calcPrice(formik.values.quantity) - calcPrice(subscription.quantity))}{' '}
                          </span>{' '}
                          than your current <span className={classes.highlight}>USD ${subscription.price} amount</span>
                        </Typography>
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    <TextFieldCustom
                      fullWidth
                      id="coupon"
                      name="coupon"
                      variant="outlined"
                      color="primary"
                      label="Coupon (Optional)"
                      value={formik.values.coupon}
                      onChange={formik.handleChange}
                      error={formik.touched.coupon && Boolean(formik.errors.coupon)}
                      helperText={formik.touched.coupon && formik.errors.coupon}
                    />
                  </Grid>
                  <Grid item xs={12} container alignItems="center" className={classes.checkboxCustom}>
                    <CheckboxCustom
                      label="I agree to the "
                      color="primary"
                      checked={formik.values.acceptTerms}
                      onChange={formik.handleChange}
                      name="acceptTerms"
                      error={formik.touched.acceptTerms ? formik.errors.acceptTerms : ''}
                    >
                      <LinkCustom href="https://www.signaturebuilder.com/terms">Terms & Conditions</LinkCustom>
                    </CheckboxCustom>
                  </Grid>
                </Grid>
                <Grid item container lg={12} justify="flex-end">
                  <DialogActions className={classes.actionsContainer}>
                    <ButtonCustom disabled={loading} onClick={handleClose} color="primary">
                      Cancel
                    </ButtonCustom>
                    <ButtonCustom
                      spinner={loading ? loading : undefined}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                    >
                      Agree & Update
                    </ButtonCustom>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

type BuyMoreProps = {
  name?: string;
  type?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
};
