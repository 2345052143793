import { createSlice } from '@reduxjs/toolkit';

interface templateType {
  name: string;
  html: string;
  isDefault: string;
  id: string;
}

interface initialType {
  lastAction: string | null;
  templateList: templateType[] | null;
}

const TemplateState: initialType = {
  lastAction: null,
  templateList: null,
};

export const callTypesTemplate = {
  list: 'list',
  action: 'action',
};
export const TemplateSlice = createSlice({
  name: 'Template Objects',
  initialState: TemplateState,
  reducers: {
    loadTemplate: (state: any, action) => {
      const { template } = action.payload; //Put accessToken user
      state.templateList = template;
      state.lastAction = action.type;
    },
  },
});
