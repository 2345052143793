import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import Select, { SelectProps, SelectClassKey } from '@material-ui/core/Select';

interface Styles extends Partial<Record<SelectClassKey, string>> {
  // focusVisible?: string;  add as many calls you want for type strict
  formControl?: string;
  selectEmpty?: string;
}
interface Props extends SelectProps {
  classes: Styles;
  label?: string;
  helperText?: string;
  helperColor?: string;
}

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const SelectCustom = withStyles(() =>
  createStyles({
    root: {},
    formControl: {
      minWidth: 120,
      width: '100%',
      margin: 0,
      legend: {
        maxWidth: '0.01px !important',
      },
    },
  })
)(({ classes, children, ...props }: Props) => {
  const id = uuidv4();

  return (
    <FormControl
      required={props.disabled}
      disabled={props.disabled}
      error={props.error}
      variant={props.variant}
      className={classes.formControl}
    >
      {props.label && <InputLabel id={`select_${id}`}>{props.label}</InputLabel>}
      <Select
        labelId={`select_${id}`}
        classes={{
          root: classes.root,
        }}
        {...props}
      >
        {children}
      </Select>
      {props.helperText && (
        <FormHelperText color={props.helperColor ? props.helperColor : 'default'}>{props.helperText}</FormHelperText>
      )}
    </FormControl>
  );
});
