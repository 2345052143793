import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Grid, List, ListItem, ListItemText, ListSubheader, Popper, Typography } from '@material-ui/core';
import { TextFieldCustom, ButtonCustom } from '../../../../../style';
import { SignatureSlice } from '../../../../../store';
import { useStyles } from './styles';
import { escapedAttributes, escapedTags } from './escaped-tags-attributes.list';

export const EmbedHTMLCode = ({ expanded }: { expanded: boolean }) => {
  const { signature } = useSelector(
    (state: any) => ({
      signature: state.signature.active,
    }),
    shallowEqual
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const { setActive } = SignatureSlice.actions;
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [customCode, setCustomCode] = useState(signature.draft.customCode ?? '');
  const [openPopper, setOpenPopper] = useState(false);
  const id = openPopper ? 'textarea-tooltip' : undefined;

  useEffect(() => {
    // when the user reloads the browser
    setCustomCode(signature.draft.customCode ?? '');
  }, [signature.draft.customCode]);

  useEffect(() => {
    if (!expanded) hidePopper();
  }, [expanded]);

  const handlePaste = (evt: any) => {
    setCustomCode(evt.target.value);
    if (error) setError(false);
  };

  const clearCode = () => {
    setCustomCode('');
    if (error) setError(false);
    const newSignature = { ...signature, draft: { ...signature.draft, customCode: '' } };
    dispatch(setActive({ signature: newSignature }));
  };

  const showPopper = () => {
    setOpenPopper(true);
  };

  const hidePopper = () => {
    setOpenPopper(false);
  };

  function applyCode() {
    if (!customCode || !customCode.length) {
      setError(true);
      return setErrorText('Invalid HTML Code');
    }
    const newSignature = { ...signature, draft: { ...signature.draft, customCode } };
    dispatch(setActive({ signature: newSignature }));
  }

  return (
    <Grid container item className={classes.container}>
      <Grid item lg={12}>
        <Typography gutterBottom color="textPrimary" component="h5" variant="h5">
          Please paste your HTML Code below to embed in your email signature
        </Typography>
      </Grid>
      <Grid item lg={12} aria-owns={id} aria-haspopup="true" ref={textareaRef}>
        <TextFieldCustom
          fullWidth
          id="custom-html"
          label="Custom HTML Code"
          multiline
          rows={20}
          value={customCode}
          placeholder="Paste Your HTML Code"
          variant="outlined"
          onChange={handlePaste}
          onFocus={showPopper}
          error={error}
          helperText={error && errorText}
        />
        <Popper
          id={id}
          open={expanded && openPopper}
          anchorEl={textareaRef?.current}
          placement="left"
          className={classes.tooltip}
          modifiers={{
            offset: {
              enabled: true,
              offset: '0, 42',
            },
          }}
        >
          <Box>
            <Typography component="p" variant="body1" className={classes.listHeader}>
              Escaped tags and attributes
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  Custom tags, custom attributes, data-* attributes, aria-* attributes and unknown protocols are not allowed and
                  will be escaped after save.
                </ListItemText>
              </ListItem>
            </List>
            <List
              subheader={
                <ListSubheader classes={{ sticky: classes.listSubheader }} className={classes.listSubheader}>
                  List of escaped tags
                </ListSubheader>
              }
            >
              <ListItem className={classes.list}>
                {escapedTags.map((tag) => (
                  <ListItemText key={tag}>{tag}</ListItemText>
                ))}
              </ListItem>
            </List>
            <List
              subheader={
                <ListSubheader classes={{ sticky: classes.listSubheader }} className={classes.listSubheader}>
                  List of escaped attributes
                </ListSubheader>
              }
            >
              <ListItem className={classes.list}>
                {escapedAttributes.map((attr) => (
                  <ListItemText key={attr}>{attr}</ListItemText>
                ))}
              </ListItem>
            </List>
          </Box>
        </Popper>
      </Grid>
      <Grid container justify="flex-end">
        <ButtonCustom onClick={clearCode} variant="outlined" color="primary">
          Clear
        </ButtonCustom>
        <ButtonCustom onClick={applyCode} className={classes.applyButton} variant="contained" color="primary">
          Apply
        </ButtonCustom>
      </Grid>
    </Grid>
  );
};
