import React from 'react';
import { DrawerClassKey, DrawerProps } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { removeFromObject } from '../../../util';

interface Styles extends Partial<Record<DrawerClassKey, string>> {
  // focusVisible?: string;  add as many calls you want for type strict
  drawerClass?: string;
}

interface Props extends DrawerProps {
  classes: Styles;
  swipeable?: boolean;
  width?: number;
}

export const DrawerCustom = withStyles(() =>
  createStyles({
    root: {
      //can put global button style here also
    },
    paper: {
      // color: theme.palette.grey[100],
      // marginLeft:theme.spacing(3)
    },
  })
)(({ classes, children, ...props }: Props) => {
  const newProp = removeFromObject(['Swipeable', 'width'], { ...props });
  const isSwipeable = props.swipeable ? props.swipeable : false;

  if (isSwipeable) {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    // The following properties are used in this documentation website for optimal usability of the component:
    //
    //     iOS is hosted on high-end devices. The backdrop transition can be enabled without dropping frames. The performance will be good enough.
    //     iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
    return (
      <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS} classes={classes} {...newProp}>
        {children}
      </SwipeableDrawer>
    );
  } else {
    return (
      <div>
        <Drawer classes={classes} {...newProp}>
          {children}
        </Drawer>
      </div>
    );
  }
});
