import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as Yup from 'yup';
import { Box, Grid, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { Social } from '../../Socials';
import { SignatureDisplay } from '../../Signature';
import { AlertBox, toAbsoluteUrl } from '../../../../util';
import { ButtonCustom, TextFieldCustom, Upload } from '../../../../style';
import { useScroll } from '../../../../util/ScrollTopArrow';
import { useUserUpdateServiceImageMutation, useUserUpdateServiceMutation } from '../../../../api';
import { SocialSlice } from '../../../../store';
import { PublicSlice } from '../../../../store/redux/Reducer/public';
import { socials } from '../../../../util/schemas/socials';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(6),
    },
    paper: {
      padding: theme.spacing(8),
    },
    back: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    button: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      display: 'none',
    },
    upload: {
      marginLeft: theme.spacing(4),
    },
    action: {
      marginTop: theme.spacing(6),
    },
    link: {
      display: 'flex',
      alignItems: 'center',
    },
    innerView: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #E1E1FB',
      padding: theme.spacing(4),
      position: 'relative',
    },
  })
);

const UserUpdateSchema = Yup.object({
  name: Yup.string().min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters').required('Name is Required'),
  email: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .email('Wrong email format')
    .required('email is Required'),
  phone: Yup.string().max(100, 'Maximum 100 characters'),
  mobile: Yup.string().max(100, 'Maximum 100 characters'),
  role: Yup.string().max(50, 'Maximum 50 characters'),
  custom: Yup.string(),
  department: Yup.string(),
  socials,
}).defined();
type UserUpdateType = Yup.InferType<typeof UserUpdateSchema>; //Type

export const UserUpdate = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scroll = useScroll();
  const { updateUserSlice } = PublicSlice.actions;
  const { updateSocialItem } = SocialSlice.actions;
  const { socials, publicAccount, publicUser, publicSignature, publicCompany, publicTemplate } = useSelector(
    (state: any) => ({
      socials: state.socials.socialItem,
      publicAccount: state.public.account,
      publicUser: state.public.user,
      publicSignature: state.public.signature,
      publicCompany: state.public.company,
      publicTemplate: state.public.template,
    }),
    shallowEqual
  );
  const [userUpdateService] = useUserUpdateServiceMutation();
  const [userUpdateServiceImage] = useUserUpdateServiceImageMutation();
  const [loadings, setLoadings] = useState(false);
  const [upload, setUpload] = useState({
    file: null,
    path: publicUser.image ? publicUser.image : '/media/image/default.png',
    updated: false,
  } as any);

  const initialValues: any = {
    email: publicUser ? publicUser.email : '',
    name: publicUser ? publicUser.name : '',
    phone: publicUser ? publicUser.phone : '',
    mobile: publicUser ? publicUser.mobile : '',
    role: publicUser ? publicUser.role : '',
    department: publicUser ? publicUser.department : '',
    custom: publicUser ? publicUser.custom : '',
    socials: publicUser
      ? socials.map((social: any) => {
          const publicUserSocial = publicUser.socials.find((soc: any) => soc.details === social.id);
          if (publicUserSocial) {
            return { ...social, linkBuffer: publicUserSocial.link, labelBuffer: publicUserSocial.label };
          }
          return social;
        })
      : socials,
  };

  const enableLoading = () => {
    setLoadings(true);
  };
  const disableLoading = () => {
    setLoadings(false);
  };
  const getImagePath = () => {
    return toAbsoluteUrl(upload.path);
  };

  const onSubmit = async (values: UserUpdateType, actions: FormikHelpers<UserUpdateType>) => {
    actions.setSubmitting(true);
    enableLoading();
    const newSocials: any = socials
      .map((social: any) => {
        if (social.linkBuffer) {
          return { id: social.id, link: social.linkBuffer };
        }
        return null;
      })
      .filter((val: any) => !!val);
    let response: any;
    if (upload.file) {
      response = await userUpdateServiceImage({
        variables: {
          options: {
            passcode: props.authorizationCode ? props.authorizationCode : publicUser.passcode,
            accountId: publicAccount.id,
            userId: publicUser.id,
            name: values.name,
            mobile: values.mobile,
            phone: values.phone,
            role: values.role,
            custom: values.custom ? values.custom : '',
            department: values.department ? values.department : '',
            socials: newSocials,
          },
          image: upload.file,
        } as any,
      });
      if (!response.errors && !response.data!.UserUpdateServiceImage.errors) {
        toastr.success('Success', 'Successfully updated');
        disableLoading();
        dispatch(updateUserSlice({ user: response.data!.UserUpdateServiceImage.user }));
        response.data!.UserUpdateServiceImage.user!.socials!.forEach((social: any) => {
          dispatch(updateSocialItem({ id: social.details, link: social.link }));
        });
        actions.setSubmitting(false);
      } else {
        const message: string = response.data!.UserUpdateServiceImage.errors![0].message;
        response.data!.UserUpdateService.errors!.forEach((error: any) => {
          toastr.error('Error', error.message);
        });
        actions.setSubmitting(false);
        actions.setStatus(message);
        disableLoading();
      }
    } else {
      response = await userUpdateService({
        variables: {
          options: {
            passcode: props.authorizationCode ? props.authorizationCode : publicUser.passcode,
            accountId: publicAccount.id,
            userId: publicUser.id,
            name: values.name,
            mobile: values.mobile || '',
            phone: values.phone || '',
            role: values.role || '',
            custom: values.custom ? values.custom : '',
            department: values.department ? values.department : '',
            socials: newSocials,
          },
        } as any,
      });
      if (!response.errors && !response.data!.UserUpdateService.errors) {
        toastr.success('Success', 'Successfully updated');
        disableLoading();
        dispatch(updateUserSlice({ user: response.data!.UserUpdateService.user }));
        response.data!.UserUpdateService.user!.socials!.forEach((social: any) => {
          dispatch(updateSocialItem({ id: social.details, link: social.link }));
        });
        actions.setSubmitting(false);
      } else {
        const message: string = response.data!.UserUpdateService.errors![0].message;
        response.data!.UserUpdateService.errors!.forEach((error: any) => {
          toastr.error('Error', error.message);
        });
        actions.setSubmitting(false);
        actions.setStatus(message);
        disableLoading();
      }
    }
    scroll.scrollTop();
  };

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item container justify="center" lg={12}>
        <Grid item container justify="center" lg={12} spacing={4}>
          <Grid item container lg={6} justify="center">
            <Grid item lg={12}>
              {publicSignature && (
                <Box className={classes.innerView}>
                  <SignatureDisplay
                    company={publicCompany}
                    account={publicAccount}
                    user={publicUser}
                    embedCode={publicSignature.embedCode}
                    meetingLinkImg={publicSignature.meetingLinkImg}
                    embedCodeTwo={publicSignature.embedCodeTwo}
                    customCode={publicSignature.customCode}
                    template={publicTemplate!.html}
                    property={publicSignature.properties}
                    setOutputCopy
                  />
                </Box>
              )}
              {!publicSignature && (
                <Box className={classes.innerView}>
                  <Paper className={classes.paper}>
                    <Typography align="center" variant="h4">
                      No Signature Assigned
                    </Typography>
                  </Paper>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item container lg={6} alignItems="center" justify="center">
            <Grid item container lg={12} justify="center" alignItems="center">
              <Formik initialValues={initialValues} validationSchema={UserUpdateSchema} onSubmit={onSubmit}>
                {(formik) => (
                  <Form>
                    <Paper className={classes.paper}>
                      <Grid item container lg={12} justify="center" alignItems="center" spacing={2}>
                        <Grid item container lg={12} justify="flex-start" alignItems="center">
                          <Typography variant="h3" align="left" component="h3">
                            Update your Signature Details
                          </Typography>
                        </Grid>
                        <Grid item container lg={12} justify="flex-start" alignItems="center">
                          <Typography variant="h6" align="left" component="h6">
                            Update you profile and images
                          </Typography>
                        </Grid>
                        <Upload
                          image={getImagePath()}
                          maxSize={1}
                          onChange={setUpload}
                          label="Update Your Photo *"
                          tooltip="Image should be less than 1mb. Recommended dimensions are 600px by 600px."
                        />
                        {/* begin: Alert */}
                        <AlertBox container="onboard-profile" />
                        {/* end: Alert */}
                        {/* begin: Email */}
                        <Grid item xs={12}>
                          <TextFieldCustom
                            fullWidth
                            id="name"
                            name="name"
                            variant="outlined"
                            color="primary"
                            label="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            fullWidth
                            disabled
                            id="email"
                            name="email"
                            type="email"
                            variant="outlined"
                            color="primary"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            fullWidth
                            id="role"
                            name="role"
                            variant="outlined"
                            color="primary"
                            label="Role"
                            value={formik.values.role}
                            onChange={formik.handleChange}
                            error={formik.touched.role && Boolean(formik.errors.role)}
                            helperText={formik.touched.role && formik.errors.role}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            fullWidth
                            id="phone"
                            name="phone"
                            variant="outlined"
                            color="primary"
                            label="Phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            fullWidth
                            id="mobile"
                            name="mobile"
                            variant="outlined"
                            color="primary"
                            label="Mobile"
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                            helperText={formik.touched.mobile && formik.errors.mobile}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            fullWidth
                            id="department"
                            name="department"
                            variant="outlined"
                            color="primary"
                            label="Department"
                            value={formik.values.department}
                            onChange={formik.handleChange}
                            error={formik.touched.department && Boolean(formik.errors.department)}
                            helperText={formik.touched.department && formik.errors.department}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldCustom
                            fullWidth
                            id="custom"
                            name="custom"
                            variant="outlined"
                            color="primary"
                            label="Custom"
                            value={formik.values.custom}
                            onChange={formik.handleChange}
                            error={formik.touched.custom && Boolean(formik.errors.custom)}
                            helperText={formik.touched.custom && formik.errors.custom}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Social socials={formik.values.socials as any[]} formik={formik} />
                        </Grid>
                        <Grid
                          className={classes.action}
                          container
                          item
                          xs={12}
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <ButtonCustom
                            size="large"
                            spinner={loadings ? loadings : undefined}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={formik.isSubmitting || upload.path === '/media/image/default.png'}
                          >
                            Update
                          </ButtonCustom>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
