import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Breadcrumbs, BreadcrumbsProps, BreadcrumbsClassKey } from '@material-ui/core';

interface Styles extends Partial<Record<BreadcrumbsClassKey, string>> {
  // focusVisible?: string;  add as many calss you wnat for type strict
}
interface Props extends BreadcrumbsProps {
  classes: Styles;
}

export const BreadCrumbsCustom = withStyles(() =>
  createStyles({
    root: {},
  })
)(({ classes, children, ...props }: Props) => {
  return (
    <Breadcrumbs
      classes={{
        root: classes.root,
      }}
      {...props}
      aria-label="breadcrumb"
    >
      {children}
    </Breadcrumbs>
  );
});
