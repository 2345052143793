import { createSlice } from '@reduxjs/toolkit';

interface TeamInput {
  name: string;
  id: string;
}

interface hubType {
  hub_id: string;
  name: string;
  email: string;
  teams: TeamInput[];
  linked_google: boolean;
  has_owner: boolean;
  owner: string;
}

interface initialType {
  lastAction: string | null;
  selectedHubspotUser: hubType[] | null;
}

const HubState: initialType = {
  lastAction: null,
  selectedHubspotUser: null,
};

export const callTypesHubspot = {
  list: 'list',
  action: 'action',
};

export const HubspotSlice = createSlice({
  name: 'Hubspot Objects',
  initialState: HubState,
  reducers: {
    addHubspotUser: (state: any, action) => {
      const { users } = action.payload; //Put accessToken user
      state.selectedHubspotUser = users;
      state.lastAction = action.type;
    },

    clearHubspotUser: (state: any, action) => {
      state.selectedHubspotUser = [];
      state.lastAction = action.type;
    },
  },
});
