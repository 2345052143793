import React from 'react';
import { Link } from 'react-router-dom';
import { List } from '@material-ui/core';
import { ListItemLink } from './ListitemLink';
import { useStyles } from './styles';

// const Subheader = (props: any) => {
//   const classes = useStyles();
//   const customTheme = useCustomTheme();
//
//   const handleToggle = () => {
//     const theme = customTheme.theme.name;
//     customTheme.changeTheme(theme == 'light' ? 'dark' : 'light');
//   };
//
//   return (
//     <Grid className={classes.mode} container justify="space-between" alignItems="center">
//       <Grid item>
//         <Typography id="switch-theme" variant="h5" component="h5">
//           Mode
//         </Typography>
//       </Grid>
//       <Grid item>
//         <SwitchCustom
//           label1={customTheme.theme.name}
//           color="primary"
//           icon={<NightsStayIcon />}
//           checkedIcon={<Brightness4Icon />}
//           edge="end"
//           onChange={handleToggle}
//           checked={customTheme.theme.name == 'light'}
//           inputProps={{ 'aria-labelledby': 'switch-theme' }}
//         />
//       </Grid>
//     </Grid>
//   );
// };

export const UserDropdownMenu = () => {
  const classes = useStyles();
  // const [selectedIndex, setSelectedIndex] = useState(1);
  //
  // const handleListItemClick = (event: MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
  //   setSelectedIndex(index);
  // };

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="user dropdown menu">
        {/*<Divider />*/}
        {/*<Link to="/profile" component={ListItemLink} className={classes.menuItem}>*/}
        {/*  Profile*/}
        {/*</Link>*/}
        <Link to="/billing" component={ListItemLink} className={classes.menuItem}>
          Billing
        </Link>
        <Link to="/logout" component={ListItemLink} className={classes.menuItem}>
          Log Out
        </Link>
        {/*<Link to="/profile" component={ListItemLink} className={classes.menuItem}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <AccountCircle fontSize="large" />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="My Profile" secondary="Account Settings and More" />*/}
        {/*</Link>*/}
        {/*<Link to='/payments' component={ListItemLink} className={classes.menuItem}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <PaymentIcon fontSize='large' />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="My Payments" secondary='Payments Settings and More' />*/}
        {/*</Link>*/}
      </List>
    </div>
  );
};
