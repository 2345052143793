import React, { useState } from 'react';
import { Button, createStyles, makeStyles, Theme, Typography, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { lighten } from '@material-ui/core/styles';
import { muiStyleAlpha } from '@material-ui/data-grid';
import { toAbsoluteUrl } from '../../../util';
import hint from '../svg/Hint.svg';

const PLACEHOLDER = toAbsoluteUrl('/media/image/default.png');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none',
    },
    upload: {
      position: 'relative',
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
    container: {
      alignSelf: 'center',
    },
    previewContainer: {
      display: 'flex',
      aspectRatio: '1 / 1',
      padding: theme.spacing(1),
      border: `1px solid ${muiStyleAlpha(lighten(theme.palette.primary.dark, 0.15), 0.5)}`,
      borderRadius: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(6),
    },
    uploadInherit: {
      color: theme.palette.error.main,
    },
    hint: {
      all: 'unset',
      position: 'absolute',
      top: theme.spacing(3),
      right: 0,
      padding: theme.spacing(1),
      borderRadius: '50%',
      '& .MuiButton-label': {
        display: 'flex',
      },
    },
  })
);

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//   },
// }))(Tooltip);

export const Upload = ({
  image,
  label = 'upload image',
  tooltipHint = false,
  maxSize = 1,
  tooltip = `Image should be less than equal to ${maxSize}mb size'`,
  uploadBtnText = 'Upload',
  previewContainerClassname,
  filenameMaxLength,
  colWidth = 12,
  onChange,
}: UploadProps) => {
  const classes = useStyles();
  const [imageError, setImageError] = useState(null as any);
  const [upload, setUpload] = useState({
    file: null,
    path: image ? image : PLACEHOLDER,
  } as any);

  const onImageChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity.valid) {
      setImageError(null);
      if (FileReader) {
        const fr = new FileReader();
        fr.onload = function () {
          if (fr.result) {
            if (filenameMaxLength && file.name.length >= filenameMaxLength) {
              return setImageError('Image  should be less then  ' + maxSize + ' MB');
            }
            const size = file.size / (1024 * 1024);
            if (size <= maxSize) {
              setUpload({ file, path: fr.result });
              onChange({ file, path: fr.result });
            } else {
              setImageError('Image  should be less then  ' + maxSize + ' MB');
            }
          }
        };
        fr.readAsDataURL(file);
      }
    }
  };

  return (
    <Grid item container lg={colWidth as any} justify="flex-start" alignItems="stretch" className={classes.container}>
      <Grid lg={4} item container alignItems="center" justify="center">
        <div className={previewContainerClassname ? previewContainerClassname : classes.previewContainer}>
          <img width="100%" src={upload.path} alt="" />
        </div>
      </Grid>
      <Grid lg={8} item container className={classes.upload} justify={tooltipHint ? 'space-between' : 'center'}>
        <Typography color={Boolean(imageError) ? 'error' : 'textPrimary'} variant="h5" align="left" component="h6" gutterBottom>
          {label}
        </Typography>
        {!tooltipHint && (
          <Typography color={Boolean(imageError) ? 'error' : 'textPrimary'} variant="h6" align="left" component="h6" gutterBottom>
            {tooltip}
          </Typography>
        )}
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={onImageChange}
        />
        <label htmlFor="contained-button-file" className={classes.uploadInherit}>
          <Button variant="outlined" color={Boolean(imageError) ? 'inherit' : 'primary'} component="span">
            {uploadBtnText}
          </Button>
        </label>
        {tooltipHint && (
          <Tooltip title={tooltip} placement="bottom" arrow>
            <Button className={classes.hint}>
              <img src={hint} alt="" />
            </Button>
          </Tooltip>
        )}
        {imageError && (
          <Typography color="error" variant="h5" align="left" component="h5" gutterBottom>
            {imageError}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

type UploadProps = {
  image?: string;
  label?: string;
  maxSize?: number;
  colWidth?: number;
  tooltipHint?: boolean;
  tooltip?: string;
  uploadBtnText?: string;
  previewContainerClassname?: string;
  filenameMaxLength?: number;
  onChange: (val: any) => void;
};
