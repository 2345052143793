import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import {AccountSlice } from "./accountSlice";

export const persistConfig = {
    key: "signature-builder",
    storage,
    whitelist: ["account","accessToken"] //persist this
}

export const persistedAccountReducer = persistReducer(persistConfig, AccountSlice.reducer); // local storage
