import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },
  })
);

export const GoogleError = (props: any) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container justify="center" alignItems="center">
      <Grid item lg={6}>
        <Typography color="textPrimary" align="center" variant="h3" component="h3">
          {props.message ? props.message : 'Something went wrong, please try again'}
        </Typography>
      </Grid>
    </Grid>
  );
};
