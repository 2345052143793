import React, {useCallback, useState} from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { FieldArray } from 'formik';
import { Remove, Add as AddIcon } from '@material-ui/icons';
import { ButtonCustom, TextFieldCustom } from '../../../style';
import { SocialSlice } from '../../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      marginTop: theme.spacing(4),
    },
    image: {
      width: '40px',
      height: '40px',
    },
    label: {
      marginLeft: theme.spacing(3),
    },
  })
);

export const Social = ({ socials, title, subtitle, formik }: SocialProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updateSocialItem } = SocialSlice.actions;
  const { socials: socialsErrors } = formik.errors;
  const [more, setMore] = useState(false);

  const onChangeCallback = useCallback(
    (event: any, error: string, social: any, type: 'link' | 'label', index: number) => {
      formik.handleChange(event);
      if (type === 'link') {
        if (!error) {
          dispatch(
            updateSocialItem({ link: event.target.value, label: formik.values.socials[index].labelBuffer, id: social.id })
          );
        }
      } else if (type === 'label') {
        if (!error) {
          dispatch(updateSocialItem({ link: formik.values.socials[index].linkBuffer, label: event.target.value, id: social.id }));
        }
      }
    },
    [formik, dispatch, updateSocialItem]
  );

  const showMore = () => {
    return socials.filter((social: any) => social.priority === false).length > 0;
  };

  return (
    <Grid container spacing={2}>
      <Grid item container lg={12} justify="flex-start" alignItems="center">
        <Typography color="textPrimary" variant="h4" component="h4">
          {title || 'Socials'}
        </Typography>
      </Grid>
      {!!subtitle && (
        <Grid item container lg={12} justify="flex-start" alignItems="center">
          <Typography color="textPrimary" variant="body1" component="p">
            {subtitle}
          </Typography>
        </Grid>
      )}
      <FieldArray
        name="socials"
        render={() => {
          return socials.map((social: any, index: number) => {
            const hasErrors = socialsErrors && socialsErrors.length && socialsErrors[index];
            const linkError = hasErrors && socialsErrors[index].linkBuffer;
            const labelError = hasErrors && socialsErrors[index].labelBuffer;
            return (
              (social.priority || more) && (
                <Grid key={index} item container lg={12} justify="flex-start" alignItems="stretch" spacing={3}>
                  <Grid item lg="auto">
                    <Grid item lg="auto" className={classes.imageContainer}>
                      <img className={classes.image} src={social.icon_solid} alt="" />
                    </Grid>
                  </Grid>
                  <Grid item lg="auto">
                    <TextFieldCustom
                      fullWidth
                      id={social.name}
                      value={social.linkBuffer || ''}
                      name={`socials[${index}].linkBuffer`}
                      variant="outlined"
                      color="primary"
                      label={`${social.name} Link`}
                      onChange={(event: any) => onChangeCallback(event, linkError, social, 'link', index)}
                      error={!!linkError}
                      helperText={linkError}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <TextFieldCustom
                      fullWidth
                      id={`${social.name} label`}
                      value={social.labelBuffer || ''}
                      name={`socials[${index}].labelBuffer`}
                      variant="outlined"
                      color="primary"
                      label="Display Label"
                      onChange={(event: any) => onChangeCallback(event, labelError, social, 'label', index)}
                      error={!!labelError}
                      helperText={labelError}
                    />
                  </Grid>
                </Grid>
              )
            );
          });
        }}
      />
      {showMore() && (
        <Grid item container lg={12} justify="flex-end" alignItems="center">
          <ButtonCustom onClick={() => setMore(!more)}>
            Show {!more ? 'more' : 'less'} accounts! &nbsp;{!more ? <AddIcon /> : <Remove />}
          </ButtonCustom>
        </Grid>
      )}
    </Grid>
  );
};

type SocialProps = {
  title?: string;
  subtitle?: string;
  socials: any[];
  formik: any;
};
