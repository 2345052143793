import { createSlice } from '@reduxjs/toolkit';

interface AddressInput {
  street: string | null
  city: string | null
  post_code: string | null
  state: string | null
  country: string | null
  isDefault: boolean;
}

interface initialType {
  lastAction: string | null;
  address: AddressInput[] | null;
}

const AddressState: initialType = {
  lastAction: null,
  address: [
    {
      state: '',
      city: '',
      post_code: '',
      street: '',
      country: '',
      isDefault: true,
    },
  ],
};

// export const callTypesAddress = {
//   list: 'list',
//   action: 'action',
// };

// const ValidationSchema = (fieldName: string, fieldValue: string) => {
//   if (fieldName !== 'isDefault') {
//     if (fieldValue.trim() === '') {
//       return `${fieldName.charAt(0).toUpperCase()}${fieldName.substring(1)} is required`;
//     }
//     if (/[^a-zA-Z0-9 -/:]/.test(fieldValue)) {
//       return 'Invalid characters';
//     }
//     if (fieldValue.trim().length < 1) {
//       return `${fieldName} needs to be at least two characters`;
//     }
//   }
//   return null;
// };

export const AddressSlice = createSlice({
  name: 'Address Objects',
  initialState: AddressState,
  reducers: {
    addAddressItem: (state: any, action) => {
      let checks = 0; //checking multiple checks
      const { index, item, value } = action.payload;
      if (item === 'isDefault') {
        state.address.forEach((address: any) => {
          if (address.isDefault === true) {
            checks++;
          }
        });
        if (checks > 1) {
          state.address[index][item] = value;
        } else {
          if (!!value) {
            if (item === 'isDefault') {
              state.address.map((address: any) => {
                address.isDefault = false;
                return address;
              });
            }
            state.address[index][item] = value;
            state.lastAction = action.type;
          }
        }
      } else {
        state.address[index][item] = value;
        state.lastAction = action.type;
      }
    },
    addAddress: (state: any, action) => {
      state.address.push({
        state: '',
        city: '',
        post_code: '',
        street: '',
        country: '',
        isDefault: false,
      });
      state.lastAction = action.type;
    },

    updateAddress: (state: any, action: any) => {
      const { addresses } = action.payload;
      state.address = [];
      addresses.forEach((address: any) => {
        state.address.push({
          state: '',
          city: '',
          post_code: '',
          street: '',
          country: '',
          isDefault: false,
        });
      });
    },
    removeAddress: (state: any, action) => {
      const { item } = action.payload; //Put accessToken user
      if (item > 0) {
        state.address = state.address.filter((el: any, index: number) => index !== item);
      }

      state.lastAction = action.type;
    },
    removeAllAddress: (state: any, action) => {
      state.address = [
        {
          state: '',
          city: '',
          post_code: '',
          street: '',
          country: '',
          isDefault: false,
        },
      ];
      state.lastAction = action.type;
    },
  },
});
