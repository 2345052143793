import { createSlice } from '@reduxjs/toolkit';

interface teamType {
  name: string;
  id: string;
}

interface initialType {
  lastAction: string | null;
  teamList: teamType[] | null;
}

const TeamState: initialType = {
  lastAction: null,
  teamList: null,
};

export const callTypesTeam = {
  list: 'list',
  action: 'action',
};
export const TeamSlice = createSlice({
  name: 'Team Objects',
  initialState: TeamState,
  reducers: {
    loadTeam: (state: any, action) => {
      const { team } = action.payload; //Put accessToken user
      state.teamList = team;
      state.lastAction = action.type;
    },
  },
});
