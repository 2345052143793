import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme, Grid, Box, Typography, RadioGroup, Radio } from '@material-ui/core';
import clsx from 'clsx';
import { SignatureSlice } from '../../../../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      padding: theme.spacing(2),
      width: '100%',
      cursor: 'pointer',
    },
    imageWrapper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #e8e8e8;',
      position: 'relative',
      '&:hover': {
        border: '1px solid' + theme.palette.primary.main,
      },
      '&.active': {
        border: '1px solid' + theme.palette.primary.main,
      },
    },
    container: {
      paddingTop: theme.spacing(8),
    },
    radio: {
      position: 'absolute',
      left: -theme.spacing(2),
      top: -theme.spacing(1),
    },
  })
);

export const Template = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { template, signature } = useSelector(
    (state: any) => ({
      template: state.template.templateList,
      signature: state.signature.active,
    }),
    shallowEqual
  );
  const { setActive } = SignatureSlice.actions;

  const handleChange = (id: any) => {
    const newTemplate = template.filter((temp: any) => temp.id === id)[0];
    const newSignature = { ...signature, draft: { ...signature.draft, template: newTemplate } };
    dispatch(setActive({ signature: newSignature }));
  };

  return (
    <RadioGroup aria-label="template" name="template" value={signature.draft.template.id}>
      <Grid className={classes.container} container direction="row" justify="flex-start" spacing={4}>
        {template.map((temp: any, index: number) => {
          return (
            <Grid key={`${index}-template`} item lg={6}>
              <Box
                onClick={() => handleChange(temp.id)}
                className={clsx(classes.imageWrapper, signature.draft.template.id === temp.id ? 'active' : '')}
              >
                <Radio
                  onChange={() => handleChange(temp.id)}
                  color="primary"
                  className={classes.radio}
                  checked={signature.draft.template.id === temp.id}
                  value={temp.id}
                  name={`${index}-templateradio`}
                  inputProps={{ 'aria-label': `${index}-templateradio` }}
                />
                <img className={classes.image} src={temp.previewImage as string} alt="" />
                <Typography align="center" variant="h6" component="h6">
                  {temp.name}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
};
