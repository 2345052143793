import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { ButtonCustom } from '../../../style';
import { ChangeCard } from './ChangeCard';
import { BuyMore, Upgrade } from '.';
import { AccountSlice, BillingSlice, HubspotSlice, SignatureSlice, SubscriptionSlice, UsersSlice } from '../../../store';
import { useRetrieveInvoiceMutation, useSubscriptionCancelMutation } from '../../../api';
import { toAbsoluteUrl } from '../../../util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    billing: {
      paddingLeft: theme.spacing(4),
    },
    billingMobile: {
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    Body: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    heading: {
      marginBottom: theme.spacing(4),
      paddingLeft: theme.spacing(3),
    },
    card: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(2),
      width: '100%',
      padding: theme.spacing(8),
      position: 'relative',
    },
    cardBrand: {
      marginBottom: theme.spacing(12),
      fontSize: theme.spacing(18),
    },
    actions: {
      marginTop: theme.spacing(9),
    },
    white: {
      color: theme.palette.background.paper,
    },
    legal: {
      marginTop: theme.spacing(9),
    },
    stripe: {
      marginTop: theme.spacing(2),
    },
  })
);

export const Billing = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loadSubscription } = SubscriptionSlice.actions; //update subscription store for in use
  const { loadAccount } = AccountSlice.actions;
  const { loadBilling } = BillingSlice.actions;
  const { loadSignatures } = SignatureSlice.actions;
  const { loadUsers } = UsersSlice.actions;
  const { addHubspotUser } = HubspotSlice.actions;
  const { account, subscription, billing } = useSelector(
    (state: any) => ({
      account: state.account.account,
      subscription: state.subscription.subscription,
      billing: state.billing.billing,
    }),
    shallowEqual
  );
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [retrieveInvoice] = useRetrieveInvoiceMutation();
  const [cancelSubscription] = useSubscriptionCancelMutation();
  const [cancel, setCancel] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [loading, setLoading] = useState(false);
  const cards: any = {
    visa: toAbsoluteUrl('/card/visa.png'),
    mastercard: toAbsoluteUrl('/card/master.svg'),
    amex: toAbsoluteUrl('/card/amex.jpeg'),
  };

  const provideDate = () => {
    if (account.type === 'trial') {
      return new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
        subscription.renewDate
      );
    } else {
      return new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
        subscription.renewDate * 1000
      );
    }
  };

  const handleInvoice = async () => {
    setLoading(true);
    const response = await retrieveInvoice();
    if (!response.errors && !response.data!.retrieveInvoice.errors) {
      window.open(response.data!.retrieveInvoice!.invoice as string);
    } else {
      response.data!.retrieveInvoice!.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
    }
    setLoading(false);
  };

  const handleCancelSubscription = async () => {
    setCancelling(true);
    const response = await cancelSubscription();
    if (!response.errors && !response.data!.subscriptionCancel.errors) {
      dispatch(loadAccount({ account: response.data!.subscriptionCancel.account })); // update user every time mate user reload
      history.push('/onboard/subscription');
      dispatch(loadSubscription({ subscription: null }));
      dispatch(loadBilling({ billing: null }));
      dispatch(loadSignatures({ signatures: null }));
      dispatch(loadUsers({ users: null }));
      dispatch(addHubspotUser({ users: null }));
    } else {
      response.data!.subscriptionCancel!.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
    }
    setCancelling(false);
  };

  const handleClose = () => {
    setCancel(false);
  };
  const handleCancel = () => {
    setCancel(true);
  };

  return (
    <Grid container justify="center" alignItems="flex-start" className={classes.root}>
      {subscription && (
        <Grid item lg={7} container justify="center" alignItems="center">
          <Grid container item lg={12} className={classes.heading}>
            <Typography variant="h4" align="center">
              Subscriptions
            </Typography>
          </Grid>
          <Grid container className={classes.Body} item lg={12} justify="flex-start" alignItems="center" spacing={2}>
            <Grid item container lg={12} justify={desktop ? 'flex-start' : 'space-between'} alignItems="center">
              <Grid item lg={4}>
                <Typography align="left" variant="h5">
                  Status
                </Typography>
              </Grid>
              <Grid item lg={8}>
                <Typography align="left" variant="h5">
                  {subscription.status}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={12} justify={desktop ? 'flex-start' : 'space-between'} alignItems="center">
              <Grid item lg={4}>
                <Typography align="left" variant="h5">
                  Quantity
                </Typography>
              </Grid>
              <Grid item lg={8}>
                <Typography align="left" variant="h5">
                  {subscription.quantity}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={12} justify={desktop ? 'flex-start' : 'space-between'} alignItems="center">
              <Grid item lg={4}>
                <Typography align="left" variant="h5">
                  Used
                </Typography>
              </Grid>
              <Grid item lg={8}>
                <Typography align="left" variant="h5">
                  {subscription.inUse}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={12} justify={desktop ? 'flex-start' : 'space-between'} alignItems="center">
              <Grid item lg={4}>
                <Typography align="left" variant="h5">
                  {account.type === 'trial' ? 'Trial Ends' : 'Renew Date'}
                </Typography>
              </Grid>
              <Grid item lg={8}>
                <Typography align="left" variant="h5">
                  {provideDate()}
                </Typography>
              </Grid>
            </Grid>
            {account.type === 'premium' && (
              <Grid item container lg={12} justify={desktop ? 'flex-start' : 'space-between'} alignItems="center">
                <Grid item lg={4}>
                  <Typography align="left" variant="h5">
                    Amount
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography align="left" variant="h5">
                    USD ${subscription.price}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid className={classes.actions} item container lg={12} justify="flex-start" alignItems="center" spacing={3}>
              {account.type === 'premium' && <BuyMore type="outlined" name="Update Subscription" disabled={loading} />}
              {account.type === 'premium' && (
                <Grid item>
                  <ButtonCustom spinner={loading} disabled={loading} color="primary" onClick={handleInvoice} variant="outlined">
                    Download Invoice
                  </ButtonCustom>
                </Grid>
              )}
              {account.type === 'trial' && <Upgrade type="outlined" name="Upgrade Subscription" />}
              <Grid item>
                <ButtonCustom color="primary" onClick={handleCancel}>
                  Cancel Subscription
                </ButtonCustom>
              </Grid>
              <Dialog
                open={cancel}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown
                disableBackdropClick
              >
                <DialogContent>
                  <Grid container justify="flex-start" alignItems="center" spacing={4}>
                    <Grid item lg={12}>
                      <Typography variant="h4" component="h4">
                        Are you sure?
                      </Typography>
                    </Grid>
                    <Grid item lg={12}>
                      <Typography variant="h5" component="h5">
                        You are about to cancel the subscription. After cancellation all your users and signature will be removed.
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonCustom disabled={cancelling} variant="contained" onClick={handleClose} color="primary">
                    No
                  </ButtonCustom>
                  <ButtonCustom
                    spinner={cancelling}
                    disabled={cancelling}
                    onClick={handleCancelSubscription}
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </ButtonCustom>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item container lg={12} className={classes.legal} justify="space-between" alignItems="center" spacing={3}>
              {/*<Grid item lg={7}>*/}
              <Typography variant="h6">
                Signature Builder is created by The Kingdom. Copyright Kingdom Media Pty Ltd, 2022
              </Typography>
              {/*</Grid>*/}
              {/*<Grid item lg={5} justify="flex-end" container alignItems="center">*/}
              {/*  <Grid item lg={6}>*/}
              {/*    <LinkCustom target="_blank" href={'https://www.thekingdom.com.au'}>*/}
              {/*      <img width={theme.spacing(32)} src={toAbsoluteUrl('/media/logo/KNG-Logo-Purple.svg')} alt="" />*/}
              {/*    </LinkCustom>*/}
              {/*  </Grid>*/}
              {/*  <Grid item lg={6}>*/}
              {/*    <img width="100%" src={toAbsoluteUrl('/media/logo/stripe.svg')} alt="" />*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
        </Grid>
      )}
      {billing && (
        <Grid
          item
          lg={5}
          container
          justify="center"
          alignItems="center"
          className={`${classes.billing} ${!desktop ? classes.billingMobile : ''}`}
        >
          <Grid container item lg={12} className={classes.heading}>
            <Typography variant="h4" align="center">
              Billing
            </Typography>
          </Grid>
          <Grid container item lg={12} justify="flex-start" alignItems="center">
            <Paper className={classes.card}>
              <ChangeCard />
              <Grid container item lg={12} justify="flex-start" alignItems="center" spacing={4}>
                <Grid item container lg={12} justify="flex-start" alignItems="center">
                  {cards[billing.card.brand] && (
                    <img className={classes.cardBrand} width={theme.spacing(24)} src={cards[billing.card.brand]} alt="" />
                  )}
                  {!cards[billing.card.brand] && <CreditCardIcon className={classes.cardBrand} color="secondary" />}
                </Grid>
                <Grid item container lg={12} justify="flex-start" alignItems="center">
                  <Grid item lg={6}>
                    <Typography className={classes.white} align="left" variant="h5">
                      Card Number
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container lg={12} justify="flex-start" alignItems="center">
                  <Grid item lg={6}>
                    <Typography className={classes.white} align="left" variant="h5">
                      **** **** **** {billing.card.last4}
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.white} align="left" variant="h5">
                      {billing.card.exp_month}/{billing.card.exp_year}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container lg={12} justify="flex-start" alignItems="center">
                  <Grid item lg={12}>
                    <Typography className={classes.white} align="left" variant="h5">
                      {billing.billing_details.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
