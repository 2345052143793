import React, { useState } from 'react';
import { Box, createStyles, IconButton, lighten, makeStyles, Theme, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    upload: {
      marginLeft: theme.spacing(4),
    },
    header: {
      marginBottom: theme.spacing(6),
    },
    uploadInherit: {
      color: theme.palette.error.main,
    },
    input: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(2),
      minWidth: theme.spacing(32),
      flex: 1,
      backgroundColor: theme.palette.background.paper,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
    },
    dividerName: {
      height: 28,
      marginLeft: theme.spacing(2),
    },
    name: {
      padding: theme.spacing(2),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.light, 0.98),
      },
    },
  })
);

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//   },
// }))(Tooltip);

export const TextFieldInline = (props: any) => {
  const classes = useStyles();
  const initialValue = props.content ? props.content : '';
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(initialValue);

  const onBoxClick = () => {
    setShow(true);
  };
  const onChange = (event: any) => {
    setValue(event.target.value);
  };
  const cancelIt = () => {
    setValue(initialValue);
    setShow(false);
  };
  const onSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(value);
    }
    cancelIt();
  };

  return (
    <Grid item container lg={12} justify="flex-start" alignItems="flex-start">
      {!show && (
        <Grid lg={12} container item justify="flex-start" alignItems="center">
          <Box
            onClick={onBoxClick}
            display="flex"
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.name}
          >
            <Typography variant={props.variant ? props.variant : 'h4'} align="left" component="h6">
              {props.content}
            </Typography>
            <Box display="flex" className="edit" alignItems="center">
              <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      )}
      {show && (
        <Grid lg={12} item>
          <Box onSubmit={onSubmit} component="form" className={classes.root}>
            <InputBase className={classes.input} value={value} onChange={onChange} inputProps={{ 'aria-label': 'set change' }} />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <DoneSharpIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton onClick={cancelIt} color="primary" className={classes.iconButton} aria-label="directions">
              <ClearSharpIcon />
            </IconButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
