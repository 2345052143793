import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { validateAccesstoken } from './setupToken';
import { graphQlError } from '../error';

export const cache: InMemoryCache = new InMemoryCache({
  addTypename: false,
});

// const ashoksLocalhost = 'http://localhost:4000/graphql';
// const liveServer = 'https://api.signaturebildr.com/graphql';

// const thisServer = process.env.NODE_ENV === 'production' ? liveServer : ashoksLocalhost;
const thisServer = `${process.env.REACT_APP_API_URL}/graphql`;

const httpLink = createUploadLink({
  uri: thisServer,
  credentials: 'include',
});

const authLink = setContext(async (_, { headers }) => {
  return await validateAccesstoken(headers); //check and rfresh access token if its invalid
});

const Links = ApolloLink.from([graphQlError, authLink.concat(httpLink)]);
export const apolloClient = new ApolloClient({ link: Links, cache });
