import React from 'react';
import {
  makeStyles,
  Box,
  TextField,
  TextFieldProps,
  OutlinedInputProps,
  InputAdornment,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useStyles as useBaseStyles } from '../base';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      // backgroundColor: '#fcfcfb',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        // backgroundColor: '#fff',
      },
      '&$focused': {
        // backgroundColor: '#fff',
        // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        // borderColor: theme.palette.primary.main,
      },
      '& .MuiInputBase-input': {
        paddingRight: theme.spacing(6),
      },
    },
  })
);

export const TextFieldCustom = ({ ...props }: TextFieldProps) => {
  const classes = useStyles();
  const baseClasses = useBaseStyles();
  const theme = useTheme();

  return (
    <Box component="div" className={baseClasses.w100}>
      <TextField
        autoComplete="off"
        {...props}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: -50,
              horizontal: 'center',
            },
          },
        }}
        InputProps={
          {
            classes,
            notched: false,
            endAdornment: props.error && (
              <InputAdornment position="end">
                <ErrorIcon
                  color="error"
                  style={{
                    fontSize: theme.spacing(6),
                  }}
                />
              </InputAdornment>
            ),
            ...props?.InputProps,
          } as Partial<OutlinedInputProps>
        }
      />
    </Box>
  );
};
