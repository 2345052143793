//import {all} from "redux-saga/effects";
import { combineReducers } from 'redux';
import { reducer as ToastrReducer } from 'react-redux-toastr';
import {
  SignatureReducer,
  PropertiesReducer,
  persistedAccountReducer,
  SocialReducer,
  AddressReducer,
  CompanyReducer,
  SubscriptionReducer,
  BillingReducer,
  HubspotReducer,
  UsersReducer,
  TemplateReducer,
  TeamReducer,
  GoogleReducer,
  CursorReducer,
} from './Reducer';
import { PublicReducer } from './Reducer/public';

export const rootReducer = combineReducers({
  account: persistedAccountReducer,
  socials: SocialReducer,
  address: AddressReducer,
  company: CompanyReducer,
  subscription: SubscriptionReducer,
  billing: BillingReducer,
  hubspot: HubspotReducer,
  users: UsersReducer,
  template: TemplateReducer,
  properties: PropertiesReducer,
  signature: SignatureReducer,
  team: TeamReducer,
  public: PublicReducer,
  google: GoogleReducer,
  cursor: CursorReducer,
  toastr: ToastrReducer,
});
// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
