import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ArrowForward, AddCircleOutline } from '@material-ui/icons';
import { SignatureDisplay } from '../SignatureDisplay';
import { ButtonCustom, TextFieldCustom } from '../../../../style';
import { AlertBox } from '../../../../util';
import { useSignatureAddMutation } from '../../../../api';
import { SignatureSlice } from '../../../../store';
import { FormikHelpers } from 'formik/dist/types';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      padding: theme.spacing(4),
    },
    cancelButton: {
      marginRight: theme.spacing(4),
    },
  })
);

const SignatureSchema = Yup.object({
  meetingLinkImg: Yup.string().min(3, 'Minimum 3 characters').max(1000, 'Maximum 1000 characters'),
  customCode: Yup.string().min(3, 'Minimum 3 characters').max(1000, 'Maximum 1000 characters'),
  embedCode: Yup.string().min(3, 'Minimum 3 characters').max(1000, 'Maximum 1000 characters'),
  embedCodeTwo: Yup.string().min(3, 'Minimum 3 characters').max(1000, 'maximum 1000 characters'),
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    //  .matches(/^[[A-Za-z 0-9]+$/, "Invalid character")
    .required('Signature Name is Required'),
  template: Yup.string().min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters').required('Choose template is Required'),
}).defined();
type SignatureType = Yup.InferType<typeof SignatureSchema>;

export const SignatureAdd = ({ btnClassName, button = false, withIcon = false }: SignatureAddProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [signatureAdd] = useSignatureAddMutation();
  const { addSignature } = SignatureSlice.actions;
  const { template, property } = useSelector(
    (state: any) => ({
      account: state.account.account,
      template: state.template.templateList,
      property: state.properties.property,
    }),
    shallowEqual
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultTemplate = template.filter((tem: any) => tem.isDefault === true)[0];
  const initialValues = {
    name: '',
    meetingLinkImg: '',
    customCode: '',
    embedCode: '',
    embedCodeTwo: '',
    template: defaultTemplate.id,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  const provideTemplateHtml = (id: string) => {
    return template.filter((templ: any) => templ.id === id)[0].html;
  };

  const onSubmit = async (values: SignatureType, actions: FormikHelpers<SignatureType>) => {
    enableLoading();
    const response = await signatureAdd({
      variables: {
        options: {
          name: values.name,
          meetingLinkImg: values.meetingLinkImg ? values.meetingLinkImg : '',
          customCode: values.customCode ? values.customCode : '',
          embedCode: values.embedCode ? values.embedCode : '',
          embedCodeTwo: values.embedCodeTwo ? values.embedCodeTwo : '',
          properties: property,
          template: values.template,
        },
      } as any,
    });
    if (!response.errors && !response.data!.signatureAdd.errors) {
      actions.setSubmitting(false);

      disableLoading();

      dispatch(addSignature({ signature: response.data!.signatureAdd.signature }));
      toastr.success('Success', 'Successfully added signature');
      setOpen(false);
      history.push(`/signature/editor/${response.data!.signatureAdd!.signature!.id}`);
    } else {
      actions.setSubmitting(false);
      response.data!.signatureAdd.errors!.forEach((error: any) => {
        toastr.error('Error', error.message);
      });
      disableLoading();
    }
  };

  return (
    <Grid item>
      {button && (
        <ButtonCustom variant="contained" color="primary" className={btnClassName} onClick={handleClickOpen}>
          Create New Signature
        </ButtonCustom>
      )}
      {!button && (
        <ListItem button className={btnClassName} onClick={handleClickOpen}>
          {withIcon && (
            <ListItemIcon>
              <AddCircleOutline />
            </ListItemIcon>
          )}
          <ListItemText primary="Create New Signature" />
        </ListItem>
      )}
      <Dialog disableEscapeKeyDown disableBackdropClick open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Formik initialValues={initialValues} validationSchema={SignatureSchema} onSubmit={onSubmit}>
          {(formik) => (
            <Form>
              <DialogTitle id="customized-dialog-title" disableTypography>
                <Typography component="h4" variant="h4">
                  Create Signature
                </Typography>
              </DialogTitle>
              <AlertBox container="signature-add" />
              <DialogContent dividers>
                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      fullWidth
                      id="name"
                      name="name"
                      variant="outlined"
                      color="primary"
                      label="Signature Name*"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  {/*<Grid item xs={12}>
                                <TextFieldCustom
                                    fullWidth
                                    id="embedCode"
                                    name="embedCode"
                                    multiline
                                    rowsMax={4}
                                    rows={4}
                                    variant="outlined"
                                    color={"primary"}
                                    label="HubSpot HTML Embed Code"
                                    value={formik.values.embedCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched.embedCode && Boolean(formik.errors.embedCode)}
                                    helperText={formik.touched.embedCode && formik.errors.embedCode}
                                />
                            </Grid>*/}
                  <Grid item xs={12}>
                    <TextFieldCustom
                      fullWidth
                      select
                      label="Choose template"
                      variant="outlined"
                      id="template"
                      name="template"
                      value={formik.values.template}
                      onChange={formik.handleChange}
                      error={formik.touched.template && Boolean(formik.errors.template)}
                    >
                      {template.map((tem: any) => (
                        <MenuItem key={tem.id} value={tem.id}>
                          {tem.name}
                        </MenuItem>
                      ))}
                    </TextFieldCustom>
                  </Grid>
                  <Grid item xs={12}>
                    <SignatureDisplay
                      embedCode={formik.values.embedCode}
                      embedCodeTwo={formik.values.embedCodeTwo}
                      customCode={formik.values.customCode}
                      template={provideTemplateHtml(formik.values.template)}
                      editing
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.action}>
                <ButtonCustom className={classes.cancelButton} onClick={handleClose} color="primary">
                  Cancel
                </ButtonCustom>
                <ButtonCustom
                  spinner={loading ? loading : undefined}
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Create Signature&nbsp;&nbsp;
                  <ArrowForward />
                </ButtonCustom>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Grid>
  );
};

type SignatureAddProps = {
  btnClassName?: string;
  button?: boolean;
  withIcon?: boolean;
};
