import { createSlice } from '@reduxjs/toolkit';

interface SocialDetailsEntities {
  id: string;
  name: string;
  icon_round: string;
  icon_solid: string;
  icon_square: string;
  priority: string;
}

interface SocialsEntities {
  id: string;
  link: string;
  ownerType: string;
  details: SocialDetailsEntities | null;
}

interface AccountProfileEntities {
  id: string;
  image: string;
  role: string;
  phone: string;
  mobile: string;
  socials: SocialsEntities[] | null;
}

interface accountType {
  fistName: string;
  lastName: string;
  type: string;
  displayName: string;
  userName: string;
  hubDomain: string;
  email: string;
  id: string;
  status: string;
  terms_accepted: boolean;
  profile: AccountProfileEntities | null;
  hubspot_portal_id: string | null;
}

interface initialType {
  lastAction: string | null;
  account: accountType | null;
  accessToken: string | null;
}

const AccountState: initialType = {
  lastAction: null,
  account: null,
  accessToken: null,
};

export const callTypesAccount = {
  list: 'list',
  action: 'action',
};
export const AccountSlice = createSlice({
  name: 'Account Objects',
  initialState: AccountState,
  reducers: {
    loginReducer: (state: any, action) => {
      const { accessToken, account } = action.payload; //Put accessToken user
      state.account = account;
      state.accessToken = accessToken;
      state.lastAction = action.type;
    },

    hubspotReducer: (state: any, action) => {
      const { email } = action.payload; //Put accessToken user
      state.account = { email: email };
      state.accessToken = null;
    },
    onBoardFormReducer: (state: any, action) => {
      const { accessToken, account } = action.payload; // Put AccessToken user
      state.account = account;
      state.accessToken = accessToken;
      state.lastAction = action.type;
    },
    updateToken: (state: any, action) => {
      const { accessToken } = action.payload; // Put AccessToken
      state.accessToken = accessToken;
      state.lastAction = action.type;
    },
    addProfileSocial: (state: any, action) => {
      const { socials } = action.payload; // Put AccessToken
      state.onBoard = { ...state.onBoard, profile: { ...state.onBoard.profile, socials: socials } };
      state.lastAction = action.type;
    },
    logoutReducer: (state, action) => {
      state.account = null;
      state.accessToken = null;
      state.lastAction = action.type;
    },
    loadAccount: (state: any, action) => {
      const { account } = action.payload;
      state.account = account;
      state.lastAction = action.type;
    },
  },
});
