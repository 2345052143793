import { createSlice } from '@reduxjs/toolkit';

interface SubscriptionEntities {
  customerId: string | null;
  renewDate: string | null;
  status: string | null;
  latest_invoice: string | null;
  invoiceStatus: string | null;
  invoiceId: string | null;
  quantity: string | null;
  subscriptionPlan: string | null;
  paymentMethodId: string | null;
  subscriptionId: string | null;
  productId: string | null;
  price: string | null;
}

interface initialType {
  lastAction: string | null;
  subscription: SubscriptionEntities | null;
}

const SubscriptionState: initialType = {
  lastAction: null,
  subscription: null,
};

export const callTypesSubscription = {
  list: 'list',
  action: 'action',
};

export const SubscriptionSlice = createSlice({
  name: 'Subscription Objects',
  initialState: SubscriptionState,
  reducers: {
    loadSubscription: (state: any, action) => {
      const { subscription } = action.payload;
      state.subscription = subscription;
      state.lastAction = action.type;
    },
  },
});
