import React from 'react';
import { CircularProgress, createStyles, makeStyles, Theme, Box } from '@material-ui/core';
import { toAbsoluteUrl } from '../../../util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: theme.spacing(52),
      marginBottom: theme.spacing(5),
    },
    container: {
      width: '100vw',
      height: '100vh',
    },
  })
);

export function Splash() {
  const classes = useStyles();

  return (
    <Box justifyContent="center" alignItems="center" display="flex" flexDirection="column" className={classes.container}>
      <img className={classes.image} src={toAbsoluteUrl('/media/logo/Signature-Builder-Stacked.svg')} alt="Signature Bildr" />
      <CircularProgress />
    </Box>
  );
}
