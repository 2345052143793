import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useSignatureCopyHtmlQuery } from '../../../../api';
import { SocialSlice } from '../../../../store';
import { PublicSlice } from '../../../../store/redux/Reducer/public';
import { UserUpdate } from './UserUpdate';
import { Splash } from '../../../../style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },
    paper: {
      height: 'auto',
    },
    header: {
      marginBottom: theme.spacing(6),
    },
    innerView: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid ' + theme.palette.primary.main,
      padding: theme.spacing(4),
      position: 'relative',
    },
    logo: {
      width: '300px',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export const User = () => {
  let { userId, authorizationCode } = useParams();
  const classes = useStyles();
  const [requesting, setRequesting] = useState(true);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { loadPublic } = PublicSlice.actions;
  const { loadSocialItem, updateSocialItem } = SocialSlice.actions;

  const { refetch } = useSignatureCopyHtmlQuery({
    fetchPolicy: 'no-cache',
    variables: {
      user: userId,
    },
  });

  useEffect(() => {
    (async () => {
      //when user reload the browser
      if (userId && authorizationCode) {
        const response = await refetch();
        if (!response.loading) {
          const data = response.data;
          if (data!.signatureCopyHtml.errors) {
            setError(data!.signatureCopyHtml.errors[0].message);
          } else {
            dispatch(
              loadPublic({
                googleLink: data!.signatureCopyHtml!.googleLink,
                template: data!.signatureCopyHtml.template,
                user: data!.signatureCopyHtml.user,
                account: data!.signatureCopyHtml.account,
                company: data!.signatureCopyHtml.company,
                signature: data!.signatureCopyHtml.signature,
              })
            );
            dispatch(loadSocialItem({ socials: data!.signatureCopyHtml.socials }));
            data!.signatureCopyHtml.user!.socials!.forEach((social) => {
              dispatch(updateSocialItem({ id: social.details, link: social.link }));
            });
            setRequesting(false);
          }
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <Grid>{error}</Grid>;
  }

  if (requesting) {
    return <Splash />;
  }

  return (
    !requesting && (
      <Grid container justify="flex-start" direction="column" className={classes.root}>
        <UserUpdate authorizationCode={authorizationCode} />
      </Grid>
    )
  );
};
