import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  // focusVisible?: string;  add as many calss you wnat for type strict
}
interface Props extends SwitchProps {
  classes: Styles;
  label1?: string;
  label2?: string;
  labelPosition?: 'start' | 'end' | 'top' | 'bottom';
}

export const SwitchCustom = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 46,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      width: 24,
      height: 24,
      transform: 'translateX(-4px)',
      top: 1,
      backgroundColor: '#fafafa',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      color: theme.palette.grey[700],
      '&:hover': {
        backgroundColor: '#fafafa!important',
        opacity: 0.9,
        color: theme.palette.grey[800],
      },
      '&$checked': {
        transform: 'translateX(18px)',
        backgroundColor: '#fafafa!important',
        border: `1px solid ${theme.palette.common.white}`,
        '& + $track': {
          opacity: 0.9,
          border: 'none',
        },
        '&:hover': {
          backgroundColor: '#fafafa!important',
          opacity: 0.9,
          color: theme.palette.primary.dark,
        },
      },
      '&$focusVisible $thumb': {
        border: '6px solid #fff',
      },
    },
    thumb: {
      backgroundColor: '#fafafa',
    },
    track: {
      borderRadius: 24 / 2,
      border: `1px solid rgba(0,0,0,0.38)`,
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border', 'color']),
    },
  })
)(({ classes, ...props }: Props) => {
  const getDirection = (position: string | undefined) => {
    switch (position) {
      case 'top': {
        return 'column';
      }
      case 'bottom': {
        return 'column-reverse';
      }
      case 'start': {
        return 'row';
      }
      case 'end': {
        return 'row-reverse';
      }
      default: {
        return 'row';
      }
    }
  };

  return (
    <Box component="div">
      <Grid component="label" direction={getDirection(props.labelPosition)} container alignItems="center" spacing={1}>
        {props.label1 && <Grid item>{props.label1}</Grid>}
        <Grid item>
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
        </Grid>
        {props.label2 && <Grid item>{props.label2}</Grid>}
      </Grid>
    </Box>
  );
});
